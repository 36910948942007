import React from "react";
import "../../static/css/homepage/homepage.scss";

import FeaturedArticle from "./featuredarticle";
import RecentArticles from "./recentarticles";
import FeaturedProducts from "./featuredproducts";
import BannerAdvert from "./banneradvert";
import InteractiveMap from "./interactivemap";
// import InteractiveRadar from "./interactiveradar";
// import FeaturedChaser from "./featuredchaser";
// import FeaturedVideo from "./featuredvideo";

function HomePageContent(props) {
  return (
    <div className="col py-3 homepage-wrapper">
      <div className="grid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-7">
            <FeaturedArticle {...props} />
            <RecentArticles {...props} />
            <div className="row">
              <div className="col-sm-6 col-md-12 col-xs-12">
                {/* <FeaturedChaser /> */}
              </div>
              <div className="col-sm-6 col-md-12 col-xs-12">
                {/* <FeaturedVideo /> */}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-5">
            <FeaturedProducts />
            <BannerAdvert />
            {/* <InteractiveRadar /> */}
            {/* <InteractiveMap /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageContent;
