import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";

import "../../../static/css/sidebar/sidebarMap.scss";

const ForecastComponent = (props) => {
  // function to get the shifted month array based on available images
  function getShiftedMonthArray(selectedRun) {
    const date = new Date(selectedRun);
    const monthIndex = date.getMonth();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const shiftedMonths = monthNames
      .slice(monthIndex)
      .concat(monthNames.slice(0, monthIndex));

    return { month: shiftedMonths[0], months: shiftedMonths };
  }

  const selectedRun = props.selectedRun;
  const { month, months } = getShiftedMonthArray(selectedRun);

  const months_array = months.slice(0, props.step_cnt);

  const [runChoice, setRunChoice] = useState();

  const sortedInitTime = props?.initTime
    ?.slice()
    .sort((b, a) => a.localeCompare(b));

  const step_value =
    props.selectedProduct == "model" && props.selectedModelCategory == "climate"
      ? months_array.map(function (time) {
          return time;
        })
      : props.selectedProduct == "satellite"
      ? props.initTime.map(function (time) {
          return time;
        })
      : null;

  const handleTimeStepClick = (index, month) => {
    if (props.selectedProduct === "model") {
      props.onTimeStepChange(index);
    } else {
      props.onTimeStepChange(index);
    }
  };

  const handleRunChange = (newRun) => {
    props.onRunChange(newRun);
    setRunChoice(newRun);
  };

  useEffect(() => {
    setRunChoice(sortedInitTime[0]);
  }, [sortedInitTime]);

  if (!props.collapsed) {
    return (
      <div className="forecast-component-wrapper">
        {props.selectedProduct === "model" && (
          <div className="forecast-run-container row pt-2">
            <div className="col-sm ps-4">
              <span className="sidebar-map-product-name">run:</span>
            </div>
            <div className="col-9 mx-0 ps-0">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  size="sm"
                  className="forecast-component-dropdown-btn"
                >
                  {sortedInitTime[0]
                    ? runChoice !== undefined
                      ? runChoice
                      : sortedInitTime[0]
                    : "select run"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="forecast-component-menu">
                  {sortedInitTime?.map((newRun, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(e) => handleRunChange(newRun)}
                      className="dropdown-item-text"
                    >
                      {newRun}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}

        <div className="forecast-time-container ps-2 pe-4">
          <div className="row pt-2">
            {step_value.map((month, index) => (
              <div className="col-2">
                <Button
                  size="sm"
                  className="time_step_btn"
                  onClick={(e) => handleTimeStepClick(index, month)}
                >
                  {month.substring(month.length - 4)}
                </Button>
                {/* <Button
                  size="sm"
                  className="time_step_btn"
                  onClick={(e) => props.onRunChange("20240627_0920")}
                >
                  Test
                </Button> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="forecast-time-container ps-2 pe-4">
        <div className="row pt-2">
          {step_value.map((month, index) => (
            <div className="col-2 px-2 mx-1">
              <Button
                size="sm"
                className="time_step_btn ps-1"
                style={{ width: "25px" }}
                onClick={(e) => handleTimeStepClick(index)}
              >
                {month}
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default ForecastComponent;
