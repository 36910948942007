import React, { useEffect } from "react";
import { SubMenu as ProSubMenu, MenuItem } from "react-pro-sidebar";
import StormIcon from "@mui/icons-material/Storm";

const StormSubMenu = (props) => {
  return (
    <ProSubMenu
      icon={<StormIcon />}
      label="Current Tropical Systems"
      className="sidebar-submenu pt-2"
      defaultOpen={true}
    >
      {/* <div className="separator pt-2 px-2">
        <span className="px-2 sidebar-heading-txt">Atlantic Basin</span>
      </div> */}
      <MenuItem
      // onClick={() => {
      //   // props.fns.calls.updaterecords_user({
      //   //   $set: { username: "administrator", city: "test city" },
      //   // });
      //   // window.location.href = "/";
      // }}
      >
        Tropical Storm Alberto
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   // props.fns.calls.getrecords_user({
      //   //   search: { username: "administrator2" },
      //   //   index: "123",
      //   // });
      //   // console.log(props);
      // }}
      >
        Hurricane Beryl
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.verifyRegisterUser("test");
      // }}
      >
        Invest 94L
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.route("/profile");
      // }}
      >
        Tropical Wave #16
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.calls.getrecords_user({ _self: true, _recursive: true });
      // }}
      >
        Tropical Wave #17
      </MenuItem>
    </ProSubMenu>
  );
};

export default StormSubMenu;
