import React, { useEffect, useState } from "react";

import Sidebar from "../components/sidebar/sidebar.jsx";
import Navbar from "../components/navbar/navbar.jsx";
import HomePageContent from "../components/home/homepagecontainer.jsx";
import { Page } from "@highmountainlabs/arclight-ui";
import init from "../hooks/init";

import { useDataFetching } from "../hooks/dataFetching";
import { useWindowResize } from "../hooks/windowResize";

import "../static/css/main.scss";
import "../static/css/fonts.scss";

const HomePage = (props) => {
  init(props);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [theme, setTheme] = useState({});
  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  useDataFetching(props, props?.D.verifyself?.msg?.User?.username);
  useWindowResize(setSidebarCollapsed, setIsMobileView);

  // useEffect to dynamically change theme for user
  useEffect(() => {
    if (props?.fns?.calls?.verifyself) props.fns.calls.verifyself();
  }, [props.D.updateuser]);

  // useEffect(() => {
  //   if (props?.fns?.calls?.getproducts) {
  //     props.fns.calls.getproducts({
  //       search: {
  //         product: "satellite",
  //         child_category: "GOES",
  //         child_category_level2: "East",
  //         region: "tropical_atlantic",
  //         parameter: "trueRGB",
  //       },
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (props?.fns?.calls?.getproducts && !props?.D?.getproducts) {
  //     props.fns.calls.getproducts({
  //       search: {
  //         product: "satellite",
  //         child_category: "GOES",
  //         child_category_level2: "East",
  //         region: "tropical_atlantic",
  //         parameter: "trueRGB",
  //       },
  //     });
  //   }
  // }, [props?.fns?.calls?.getproducts]);

  return props?.D?.getrecords_settings?.init?.records ? (
    <Page fns={props.fns}>
      <div className="main-container">
        <div className="navbar-container">
          <Navbar {...props} theme={theme} />
        </div>
        <div className="container-fluid row full-height p-0 m-0">
          <div className={`col-2 sidebar-container p-0`}>
            <Sidebar
              {...props}
              sidebarCollapsed={sidebarCollapsed}
              onSidebarCollapse={handleSidebarCollapse}
            />
          </div>
          <div
            className={`col-8 app-container ${
              sidebarCollapsed ? "app-container-collapsed" : ""
            }`}
          >
            <div>
              <HomePageContent {...props} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : null;
};

export default HomePage;
