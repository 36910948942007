import React from "react";

import EditProfileIcon from "@mui/icons-material/Edit";
import EditAvatarIcon from "@mui/icons-material/Upload";

import "../../static/css/profile/profileAvatar.scss";

const AvatarWidget = ({ username, userAvatar, D }) => {
  // console.log(D)
  return (
    <div className="mr-2">
      <div className="profile-header-container row px-0">
        <div className="profile-text-container col-3">
          <h5 className="profile-header-text pt-1">Profile</h5>
        </div>
        <div className="profile-username-container col-7 pt-1">
          <h5 className="profile-username-text">{D?.verifyself?.msg?.User?.username}</h5>
        </div>
        <div className="profile-edit-container col-2 pt-1 text-center">
          <EditProfileIcon
            className="profile-icon profile-edit-icon"
            fontSize="medium"
          />
        </div>
      </div>
      <div className="profile-avatar-container row pt-2">
        <div className="profile-avatar-gradient p-0">
          <div className="profile-avatar-wrapper">
            <div className="profile-avatar-triangle">
              <EditAvatarIcon
                className="profile-icon profile-avatar-icon"
                fontSize="large"
              />
            </div>
            <img
              src={D?.verifyself?.msg?.User?.profile_decorations?.avatar}
              alt="Avatar"
              className="profile-avatar-image"
            />
          </div>
        </div>
      </div>
      <div className="profile-info-container row"></div>
    </div>
  );
};

export default AvatarWidget;
