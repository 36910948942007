import React, { Component } from "react";

class BannerAdvert extends Component {
  render() {
    return (
      <div className="list-group banner-ad-wrapper mt-3">
        <div className="list-group-item home-component-container shadow-sm">
          <div className="grid">
            <div className="banner-ad-container row">
              <div className="hm-lab-svg col-sm-3 col-md-3 col-lg-3 col-xl-4 col-xxl-4">
                {/* <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="75pt"
                  height="75pt"
                  viewBox="0 0 306.000000 303.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <linearGradient id="header-shape-gradient" x2="0" y2="1">
                    <stop offset="90%" stop-color="var(--color-stop)" />
                    <stop offset="100%" stop-color="var(--color-bot)" />
                  </linearGradient>
                  <g
                    transform="translate(0.000000,303.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="url(#MyGradient)"
                  >
                    <path
                      d="M1370 3005 c-382 -47 -719 -226 -972 -515 -333 -380 -466 -909 -340
                    -1355 72 -259 245 -535 445 -714 297 -266 713 -426 1062 -408 209 11 416 63
                    599 152 86 41 120 61 245 144 80 53 299 274 363 366 375 539 366 1148 -26
                    1705 -242 344 -677 589 -1116 629 -133 13 -123 13 -260 -4z m347 -46 c614 -84
                    1112 -547 1260 -1169 15 -60 18 -118 18 -295 -1 -193 -3 -231 -23 -310 -34
                    -136 -74 -255 -87 -255 -9 0 -249 244 -455 463 l-64 68 -121 -121 -121 -120
                    -81 87 c-389 420 -509 547 -517 548 -6 0 -98 -95 -205 -211 -108 -116 -244
                    -262 -304 -325 l-107 -114 -118 123 c-64 67 -122 122 -128 122 -6 0 -26 -17
                    -45 -37 -18 -21 -105 -114 -193 -208 -88 -93 -182 -194 -209 -223 -27 -30 -53
                    -50 -57 -45 -5 4 -24 60 -44 123 -89 289 -86 612 9 897 173 520 653 923 1194
                    1002 83 13 309 13 398 0z m-188 -1341 l2 -86 -71 -79 c-38 -43 -70 -82 -70
                    -88 0 -6 43 -44 95 -85 52 -41 94 -80 92 -86 -3 -16 -316 -268 -323 -262 -3 3
                    25 62 61 132 37 69 65 131 63 136 -2 6 -47 -21 -101 -60 -149 -106 -147 -106
                    -88 6 29 53 50 101 49 105 -2 4 -48 -18 -103 -49 -55 -32 -127 -73 -159 -91
                    l-59 -33 29 35 c57 67 181 206 373 415 154 168 197 209 202 195 3 -10 7 -57 8
                    -105z m283 -426 c54 -47 96 -88 94 -90 -2 -2 -49 10 -104 28 l-101 30 -29 53
                    c-16 28 -47 84 -68 122 l-38 70 74 -64 c41 -35 119 -102 172 -149z m548 75 l0
                    -83 -45 -45 c-24 -24 -45 -48 -45 -54 0 -5 14 -23 30 -40 l30 -31 -120 -83
                    c-66 -45 -120 -80 -120 -77 0 3 19 38 43 78 49 85 82 156 75 163 -3 3 -47 -25
                    -99 -62 -119 -86 -179 -125 -179 -117 0 7 421 434 427 434 2 0 3 -37 3 -83z
                    m-1648 -146 c23 -20 48 -41 56 -47 12 -9 9 -10 -14 -7 -23 3 -44 -7 -101 -49
                    -96 -71 -254 -182 -266 -187 -5 -2 7 25 27 60 20 35 55 100 78 143 46 90 53
                    93 -104 -28 -57 -44 -96 -71 -88 -60 36 47 82 99 214 242 l141 153 8 -92 8
                    -93 41 -35z m1873 -212 l30 -32 -50 28 c-27 16 -63 36 -78 44 -17 10 -41 40
                    -59 76 l-30 59 78 -71 c44 -39 93 -86 109 -104z m-977 123 c4 -28 -24 -41 -44
                    -20 -20 20 -7 50 20 46 13 -2 22 -12 24 -26z m-80 -25 c4 -20 -25 -34 -40 -19
                    -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m-538 -2 c15 -18 5 -35 -21 -35 -12
                    0 -19 7 -19 18 0 33 20 42 40 17z m-92 -63 c4 -28 -24 -40 -45 -19 -14 13 -14
                    19 -3 32 18 22 44 15 48 -13z m669 -18 c3 -8 -2 -23 -11 -32 -15 -15 -17 -15
                    -32 0 -21 21 -11 48 16 48 11 0 23 -7 27 -16z m-580 -10 c3 -8 1 -20 -6 -27
                    -14 -14 -47 15 -37 32 10 16 36 13 43 -5z m61 -112 c2 -13 -3 -25 -12 -28 -12
                    -4 -16 -20 -16 -57 0 -43 10 -69 65 -174 68 -128 76 -164 48 -195 -14 -16 -34
                    -18 -196 -18 -172 0 -182 1 -204 22 -13 12 -23 30 -23 41 0 10 28 69 62 130
                    54 99 62 120 66 180 4 57 2 67 -12 67 -16 0 -23 40 -9 54 4 3 57 5 117 4 107
                    -3 111 -4 114 -26z m592 -2 c0 -20 -5 -30 -15 -30 -32 0 -13 -102 22 -121 59
                    -31 96 -135 73 -203 -27 -83 -90 -126 -183 -126 -171 0 -241 220 -103 325 32
                    25 36 32 36 75 0 33 -5 50 -15 54 -15 5 -21 37 -8 49 3 4 48 7 100 7 l93 0 0
                    -30z m600 -44 c0 -46 3 -56 20 -61 28 -9 32 -34 28 -193 -3 -154 -8 -166 -63
                    -178 -22 -4 -38 0 -58 16 l-27 21 0 160 c0 148 1 160 20 171 16 10 20 24 20
                    65 0 52 0 53 30 53 30 0 30 -1 30 -54z m-213 -196 c2 -113 -1 -211 -6 -219 -5
                    -8 -16 -11 -25 -8 -14 6 -16 34 -16 222 l0 216 23 -3 c22 -3 22 -4 24 -208z"
                    />
                    <path
                      d="M1700 2702 c-44 -22 -80 -75 -80 -119 0 -36 34 -113 50 -113 19 0 10
                    -31 -35 -120 l-45 -90 -38 5 c-73 11 -140 -46 -149 -127 -5 -40 -9 -47 -49
                    -68 -24 -13 -45 -22 -47 -20 -3 3 -18 36 -35 74 l-30 69 19 41 c24 53 24 70
                    -3 118 -53 93 -174 92 -223 -2 -19 -38 -19 -74 1 -116 18 -38 52 -60 110 -72
                    33 -7 39 -13 67 -76 l31 -69 -23 -16 c-13 -9 -114 -66 -224 -128 -170 -95
                    -204 -110 -224 -102 -75 28 -151 -3 -178 -75 -22 -58 -11 -107 35 -151 26 -24
                    39 -29 84 -29 90 -1 140 51 137 144 l-1 48 193 107 c105 60 227 128 270 152
                    42 23 85 48 96 54 14 10 24 8 48 -10 47 -35 99 -37 158 -7 l49 25 56 -30 c30
                    -17 154 -90 276 -162 l221 -132 -5 -46 c-6 -62 17 -104 72 -131 57 -27 107
                    -19 152 26 38 38 49 72 40 121 -15 80 -89 123 -176 103 l-47 -11 -196 117
                    c-109 64 -197 122 -197 129 0 7 17 44 38 82 37 69 37 70 85 75 163 19 145 250
                    -20 250 -33 0 -50 -7 -77 -31 -45 -39 -55 -86 -32 -151 l17 -48 -35 -75 c-19
                    -41 -37 -75 -40 -75 -3 0 -28 12 -56 28 -43 23 -50 31 -50 57 0 17 -9 48 -20
                    69 l-19 39 48 101 c45 96 50 102 87 114 62 18 96 61 102 125 4 47 1 57 -23 85
                    -52 61 -103 75 -165 44z"
                    />
                    <path
                      d="M898 760 c-4 -8 -8 -44 -11 -80 -3 -57 -11 -78 -61 -168 -32 -57 -55
                    -110 -52 -118 8 -22 316 -21 316 1 0 9 -16 47 -35 85 l-35 70 -70 0 c-39 0
                    -70 2 -70 5 0 4 7 19 15 36 11 21 15 52 13 107 -2 42 -6 70 -10 62z m57 -265
                    c0 -30 -39 -27 -43 3 -3 20 0 23 20 20 15 -2 23 -10 23 -23z m-77 -28 c4 -20
                    -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z"
                    />
                    <path
                      d="M1457 589 c-37 -28 -52 -88 -34 -131 25 -59 61 -83 124 -83 50 0 57
                    3 89 37 40 45 46 80 22 141 -15 38 -56 57 -121 57 -40 0 -61 -6 -80 -21z"
                    />
                    <path d="M2182 513 l3 -128 25 0 25 0 3 128 3 127 -31 0 -31 0 3 -127z" />
                  </g>
                </svg> */}
              </div>
              <div className="banner-ad-title-container col-sm-3 col-md-6 col-lg-6 col-xxl-6 col-xl-5">
                <h5 className="banner-ad-title-primary my-0">Highmountain</h5>
                <h5 className="banner-ad-title-secondary">Labs</h5>
              </div>
            </div>
            <div className="banner-ad-text-container">Advertisement</div>
          </div>
        </div>
      </div>
    );
  }
}
export default BannerAdvert;
