import { useEffect } from "react";
import { fetchUserTheme } from "../controllers/themeController";

export const useDataFetching = (props, username) => {
  useEffect(() => {
    const fetchData = async () => {
      if (
        username !== undefined &&
        props.fns.readToken()._token !== undefined
      ) {
        try {
          await fetchUserTheme(props, username);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [props, username, props.fns]);
};
