import React from "react";
import { Button } from "react-bootstrap";

function RegisterModal({
  handleUsernameChange,
  handlePasswordChange,
  handleEmailChange,
  handleSignUp,
}) {
  return (
    <form>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="signup-username"
          placeholder="Username..."
          onChange={handleUsernameChange}
        />
      </div>
      <div className="form-group pt-3">
        <input
          type="password"
          className="form-control"
          id="signup-password"
          placeholder="Password..."
          onChange={handlePasswordChange}
        />
      </div>
      <div className="form-group pt-3">
        <input
          type="email"
          className="form-control"
          id="signup-email"
          placeholder="Email..."
          onChange={handleEmailChange}
        />
      </div>
      <div className="button-container">
        <Button variant="primary" onClick={handleSignUp}>
          Sign Up
        </Button>
      </div>
    </form>
  );
}

export default RegisterModal;
