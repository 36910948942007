import React from "react";

function NavbarHeader(props) {
  return (
    <div className="d-flex align-items-center">
      {/* <img src={logo} className="navbar-main-logo" alt="Logo" /> */}
      <a href="/">
        <h4 className="navbar-text-logo px-1">
          {props.D.getrecords_settings.init.records[0].applicationtitle}
        </h4>
      </a>
    </div>
  );
}

export default NavbarHeader;
