import React from "react";
import { Button } from "react-bootstrap";

import AccountCreated from "./feeds/accountCreated";
import CommentCreated from "./feeds/commentCreated";
import PostCreated from "./feeds/postCreated";
import SubscriptionActivated from "./feeds/subscriptionActivated";
import VerifiedChaser from "./feeds/verifiedChaser";
import NewFollower from "./feeds/newFollower";
import PostLiked from "./feeds/postLiked";
import DateDivider from "./feeds/dateDivider";

import "../../static/css/profile/profileFeed.scss";

const ActivityFeed = () => {
  return (
    <div>
      <div className="profile-activity-container mr-2">
        <div className="profile-text-container row px-0">
          <h5 className="profile-header-text pt-1">Recent Activity</h5>
        </div>
        <div className="profile-feed-container overflow-auto row pt-2">
          <div className="col">
            <NewFollower
              activityText={
                <span>
                  <a href="#">Test</a> started following you at 4:15 PM today.
                </span>
              }
            />
            <PostLiked
              activityText={
                <span>
                  <a href="#">Test</a> liked your <a href="#">post</a> in{" "}
                  <a href="#">Active Weather Systems </a> at 4:02 PM today.
                </span>
              }
            />
            <CommentCreated
              activityText={
                <span>
                  <a href="#">Test</a> left a comment on your{" "}
                  <a href="#">post</a> in{" "}
                  <a href="#">Active Weather Systems </a> at 3:15 PM today.
                </span>
              }
            />
            <DateDivider dateText="Yesterday" />
            <PostCreated
              activityText={
                <span>
                  Your post was created yesterday at 2:33 PM in{" "}
                  <a href="#">Active Weather Systems </a>
                  with 176 views.
                </span>
              }
            />
            <SubscriptionActivated activityText="Your subscription was activated yesterday at 4:15 PM." />
            <VerifiedChaser activityText="You were verified as a chaser yesterday at 2:33 PM." />
            <DateDivider dateText="Last Week" />
            <AccountCreated activityText="Your account was created on 06/22/2023 at 4:20 PM." />
          </div>
        </div>
        <div className="profile-feed-btn row">
          <Button className="profile-feed-btn rounded-0">View More</Button>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;
