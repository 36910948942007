import React, { useEffect, useState } from "react";
import { Page, ArticlePage } from "@highmountainlabs/arclight-ui";
import Navbar from "../components/navbar/navbar.jsx";
import Sidebar from "../components/sidebar/sidebar.jsx";

import init from "../hooks/init.js";

import { useDataFetching } from "../hooks/dataFetching";
import { useWindowResize } from "../hooks/windowResize";
import { Tweet } from "react-tweet";

import "../static/css/main.scss";
import "../static/css/fonts.scss";

const _ArticlePage = (props) => {
  init(props);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [theme, setTheme] = useState({});

  useDataFetching(props, props?.D.verifyself?.msg?.User?.username);
  useWindowResize(setSidebarCollapsed, setIsMobileView);

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  // useEffect to dynamically change theme for user
  useEffect(() => {
    if (props?.fns?.calls?.verifyself) props.fns.calls.verifyself();
  }, [props.D.updateuser]);

  useEffect(() => {
    if (
      !props.fns.readState().query.id ||
      !props.fns.calls.getarticles ||
      props?.D?.getarticles?.viewarticle
    )
      return;
    if (!props?.D?.getarticles?.viewarticle) {
      props.fns.calls.getarticles({
        index: "viewarticle",
        search: { _id: props.fns.readState().query.id },
      });
    }
  });

  return props?.D?.getarticles?.viewarticle ? (
    <Page fns={props.fns}>
      <div className="main-container">
        <div className="navbar-container">
          <Navbar {...props} />
        </div>
        <div className="container-fluid row full-height p-0 m-0">
          <div className={`col-2 sidebar-container p-0`}>
            <Sidebar
              {...props}
              sidebarCollapsed={sidebarCollapsed}
              onSidebarCollapse={handleSidebarCollapse}
            />
          </div>
          <div
            className={`col-8 app-container ${
              sidebarCollapsed ? "app-container-collapsed" : ""
            }`}
          >
            <div className={`p-5`}>
              <ArticlePage
                commenter={{
                  _id: props.D?.verifyself?.msg?.User?._id,
                  avatar:
                    props.D?.verifyself?.msg?.User?.profile_decorations?.avatar,
                }}
                refresh={{
                  index: "viewarticle",
                  fn: props.fns.calls.getarticles,
                }}
                init={props?.D?.getarticles?.viewarticle?.records[0]}
                nopage={props.D.nopage}
                fns={props.fns}
                D={props.D}
                comments={true}
                views={true}
                commentfns={{
                  createcomment: props.fns?.calls?.createrecords_articlecomment,
                  editcomment: props.fns?.calls?.updaterecords_articlecomment,
                  deletecomment: props.fns?.calls?.deleterecords_articlecomment,
                  likecomment: props.fns?.calls?.likecomment,
                  reportcomment: props.fns?.calls?.deleterecords_articlecomment,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : null;
};

export default _ArticlePage;
