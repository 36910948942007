import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import "bootstrap/dist/css/bootstrap.css";
import "../../static/css/navbar/navbar.scss";

import NavbarHeader from "./navbarHeader";

import NavbarButton from "../buttons/navbarButton.jsx";
import PremiumButton from "../buttons/premiumButton.jsx";

function Navbar(props) {
  return (
    <div className="navbar-main-wrapper">
      <nav className="navbar navbar-expand-lg navbar-top">
        <NavbarHeader {...props} />
        {/* <div id="fade-text">
          <span className="text-light">New Tornado Warning:</span>{" "}
          <span className="warning-text text-danger">
            Reports of a tornado down SSE of Witchita, KS
          </span>
        </div> */}
        <PremiumButton {...props} />
        <NavbarButton props={props} />
      </nav>
    </div>
  );
}

export default Navbar;
