import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Page } from "@highmountainlabs/arclight-ui";

import SidebarHeader from "./components/sidebarHeader";
import SidebarFooter from "./components/sidebarFooter";
import ForecastComponent from "./components/modelForecastComponent";
import InfoComponent from "./components/modelInfoComponent";

import CommunityMenu from "./subMenus/communityMenu";
import ModelProductsMenu from "./subMenus/modelProductMenu";
import ModelMenu from "./subMenus/modelMenu";
import SatelliteMenu from "./subMenus/satelliteMenu";
import ParamMenu from "./subMenus/paramMenu";

import SatelliteIcon from "@mui/icons-material/SatelliteAlt";
import ProfileIcon from "@mui/icons-material/AccountBox";
import DataIcon from "@mui/icons-material/BarChart";

import "../../static/css/sidebar/sidebar.scss";
import "../../static/css/variables.scss";

const MainSidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [cookies, setCookie] = useCookies(["sidebarCollapsed"]);
  const sidebarImage =
    "https://skywatchpro.us/arclight/static/media/662010dc3096cedfcd32bd9d.png";

  useEffect(() => {
    const collapsedCookie = cookies.sidebarCollapsed === "true";
    const initialCollapsed =
      collapsedCookie !== undefined ? collapsedCookie : false;
    setCollapsed(initialCollapsed);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      const newCollapsed = window.innerWidth <= 1300; // Change the window width as per your requirement
      setCookie("sidebarCollapsed", newCollapsed.toString(), { path: "/" });
      setCollapsed(newCollapsed);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [setCookie]);

  const handleCollapse = () => {
    const newCollapsed = !collapsed;
    setCookie("sidebarCollapsed", newCollapsed.toString(), { path: "/" });
    setCollapsed(newCollapsed);

    if (props.onSidebarCollapse) {
      props.onSidebarCollapse(newCollapsed);
    }
  };

  return props.page_name == "map" ? (
    <Page fns={props.fns}>
      <div className="sidebar-wrapper">
        <Sidebar
          className="sidebar-container"
          backgroundColor="transparent"
          image={
            props?.D?.verifyself?.msg?.User
              ? props?.D?.verifyself?.msg?.User?.profile_decorations
                  ?.profile_theme?.sidebarimg
              : sidebarImage
          }
          collapsed={collapsed}
          collapsedWidth="80px"
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0 || level === 1) {
                  return {
                    color: disabled ? "#ffffff" : "var(--font-color)",
                    backgroundColor: active ? "#eecef9" : undefined,
                    fontSize: "0.8em",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                    },
                    "&:focus": {
                      fontWeight: "bold",
                    },
                  };
                }
              },
            }}
          >
            <InfoComponent
              {...props}
              onCollapse={handleCollapse}
              collapsed={collapsed}
            />

            <ForecastComponent
              {...props}
              onCollapse={handleCollapse}
              collapsed={collapsed}
            />
            <div className="separator pt-2 px-2">
              <span className="px-2 sidebar-heading-txt">Models</span>
            </div>
            <ModelMenu {...props} />
            <div className="separator pt-2 px-2">
              <span className="px-2 sidebar-heading-txt">Satellite</span>
            </div>
            <SatelliteMenu {...props} />
            <div className="separator pt-2 px-2">
              <span className="px-2 sidebar-heading-txt">Parameters</span>
            </div>
            <ParamMenu {...props} selectedModelName={props.selectedModelName} />
          </Menu>

          <SidebarFooter
            onCollapse={handleCollapse}
            collapsed={collapsed}
            {...props}
          />
        </Sidebar>
      </div>
    </Page>
  ) : (
    <Page fns={props.fns}>
      <div className="sidebar-wrapper">
        <Sidebar
          className="sidebar-container"
          backgroundColor="transparent"
          image={
            props?.D?.verifyself?.msg?.User
              ? props?.D?.verifyself?.msg?.User?.profile_decorations
                  ?.profile_theme?.sidebarimg
              : sidebarImage
          }
          collapsed={collapsed}
          collapsedWidth="80px"
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0 || level === 1) {
                  return {
                    color: disabled ? "#ffffff" : "var(--font-color)",
                    backgroundColor: active ? "#eecef9" : undefined,
                    fontSize: "0.8em",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                    },
                    "&:focus": {
                      fontWeight: "bold",
                    },
                  };
                }
              },
            }}
          >
            <SidebarHeader
              onCollapse={handleCollapse}
              collapsed={collapsed}
              {...props}
              page_name={props.page_name}
            />

            <CommunityMenu {...props} />
            <div className="separator py-2 px-2">
              <span className="px-2 sidebar-heading-txt">Map Products</span>
            </div>
            <ModelProductsMenu {...props} />
            <div className="pt-2"></div>
            <MenuItem icon={<SatelliteIcon />}>Satellite</MenuItem>
            <div className="pt-2"></div>
            <MenuItem icon={<DataIcon />}>Analysis</MenuItem>
            <div className="pt-2"></div>
            <div className="separator py-2 px-2">
              <span className="px-2 sidebar-heading-txt">User Account</span>
            </div>
            <MenuItem icon={<ProfileIcon />}>Profile</MenuItem>
          </Menu>

          <SidebarFooter
            onCollapse={handleCollapse}
            collapsed={collapsed}
            {...props}
          />
        </Sidebar>
      </div>
    </Page>
  );
};

export default MainSidebar;
