import React from "react";
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ProfilePosts = ({ primaryColor, secondaryColor, gridColor }) => {
  const data = [
    {
      name: "January",
      posts: 220,
      comments: 322,
    },
    {
      name: "February",
      posts: 300,
      comments: 422,
    },
    {
      name: "March",
      posts: 525,
      comments: 410,
    },
    {
      name: "April",
      posts: 278,
      comments: 390,
    },
    {
      name: "May",
      posts: 412,
      comments: 380,
    },
    {
      name: "June",
      posts: 792,
      comments: 643,
    },
    {
      name: "July",
      posts: 349,
      comments: 430,
    },
  ];

  return (
    <div>
      <h5 className="profile-header-text pt-1 text-center">
        Monthly Posts and Comments
      </h5>
      <ResponsiveContainer width="95%" height={395}>
        <BarChart
          data={data}
          margin={{
            top: 16,
            right: 20,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={gridColor}
            strokeWidth={0.5}
          />
          <XAxis
            dataKey="name"
            stroke={primaryColor}
            strokeWidth={0.5}
            interval={0}
          />
          <YAxis stroke={primaryColor} />
          <Tooltip cursor={{ fill: "transparent" }} strokeWidth={0.5} />
          <Legend />
          <Bar dataKey="posts" fill={primaryColor} className="bar-chart-bar">
            <LabelList
              dataKey="posts"
              position="top"
              formatter={(value) => value.toString()}
              stroke={primaryColor}
              fontSize={14}
              fontWeight={10}
            />
          </Bar>
          <Bar
            dataKey="comments"
            fill={secondaryColor}
            className="bar-chart-bar"
          >
            <LabelList
              dataKey="comments"
              position="top"
              formatter={(value) => value.toString()}
              stroke={secondaryColor}
              fontSize={14}
              fontWeight={10}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProfilePosts;
