import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "react-bootstrap";
import gifshot from "gifshot";
import "../../static/css/mappage/mappage.scss";
import RegionModal from "../modals/regionModal";

import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import ArrowBackward from "@mui/icons-material/ArrowBackIos";
import ArrowPlay from "@mui/icons-material/PlayArrow";
import PauseBtn from "@mui/icons-material/Pause";
import FastForward from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SlowForward from "@mui/icons-material/KeyboardDoubleArrowRight";
import SaveIcon from "@mui/icons-material/SaveAlt";

const MapPageContent = (props) => {
  const imageCache = useRef({});
  const [timeValue, setTimeValue] = useState(0);
  const [currentStepCnt, setCurrentStepCnt] = useState(props.step_cnt);
  const regionValue = props.selectedRegion || "north_atlantic";
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1000);
  const [showRegionModal, setShowRegionModal] = useState(false);

  const toggleModal = (isOpen) => {
    setShowRegionModal(isOpen);
    props.onRegionCategoryChange(props?.selectedRegionCategory);
  };

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value);
    setTimeValue(newValue);
    props.onSliderChange(newValue);
  };

  const incrementValue = useCallback(() => {
    if (currentStepCnt === 0) {
      setCurrentStepCnt(props.step_cnt);
      setTimeValue((prevValue) => Math.min(prevValue + 1, 11));
    } else if (props.selectedTimeStep >= currentStepCnt) {
    } else {
      setTimeValue((prevValue) => Math.min(prevValue + 1, currentStepCnt - 1));
    }
  }, [props.selectedTimeStep, currentStepCnt]);

  const decrementValue = () => {
    setTimeValue((prevValue) => Math.max(prevValue - 1, 0));
  };

  const togglePlayPause = () => {
    setPlaying(!playing);
  };

  const increaseSpeed = () => {
    setSpeed((prevSpeed) => Math.max(prevSpeed / 2, 100)); // Fastest is 100ms
  };

  const decreaseSpeed = () => {
    setSpeed((prevSpeed) => prevSpeed * 2); // Slowest can be very slow
  };

  const saveAsGif = () => {
    const images = [];
    for (let i = 0; i < props.step_cnt; i++) {
      images.push(props.imageUrl);
    }

    gifshot.createGIF(
      {
        images,
        gifWidth: 1920, // Adjust based on the size of your images
        gifHeight: 1080, // Adjust based on the size of your images
        interval: 0.5, // Interval between frames in seconds
      },
      (obj) => {
        if (!obj.error) {
          const { image } = obj;
          const link = document.createElement("a");
          link.href = image;
          link.download = "animation.gif";
          link.click();
        } else {
          console.error("GIF generation failed:", obj.errorMsg);
        }
      }
    );
  };

  const downloadCurrentImage = async (url) => {
    // fetch(url, {
    //   mode: "cors",
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const downloadUrl = window.URL.createObjectURL(blob);
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = downloadUrl;
    //     downloadLink.download = props.imageUrl; // Set your desired file name here
    //     document.body.appendChild(downloadLink);
    //     downloadLink.click();
    //     document.body.removeChild(downloadLink);
    //     window.URL.revokeObjectURL(downloadUrl);
    //   })
    //   .catch((error) => console.error("Error downloading the image:", error));
    const apikey = `JIpbHHhxsXV358HGiT42imKtj350uUiH0QIXjAJb7jq2HViskE8VYUbsboEXhh4C`;
    const id = `66857b023c8f3bcd582c3733`;
    const res = await fetch(
      `https://skywatchpro.us/api/downloadproductimage?apikey=${apikey}&id=${id}`
    )
      .then((res) => res.json())
      .then((res) => {
        // logic
      })
      .catch((error) => console.error("Error downloading the image:", error));
    console.log(res);
  };

  // Preload images function
  const preloadImages = (url) => {
    if (!imageCache.current[url]) {
      const img = new Image();
      img.src = url;
      imageCache.current[url] = img;
    }
  };

  useEffect(() => {
    let interval;
    if (playing) {
      interval = setInterval(() => {
        setTimeValue((prevValue) =>
          prevValue < props.step_cnt - 1 ? prevValue + 1 : 0
        );
      }, speed);
    }
    return () => clearInterval(interval);
  }, [playing, speed, props.step_cnt]);

  useEffect(() => {
    props.onSliderChange(timeValue);
  }, [timeValue]);

  useEffect(() => {
    setTimeValue(props.selectedTimeStep);
  }, [props.selectedTimeStep]);

  useEffect(() => {
    if (props.step_cnt !== 0 && timeValue >= props.step_cnt) {
      setTimeValue(props.step_cnt - 1);
    }
  }, [props.step_cnt && props.selectedModelName]);

  useEffect(() => {
    setCurrentStepCnt(props.step_cnt);
  }, [props.step_cnt]);

  useEffect(() => {
    if (props.selectedTimeStep >= props.step_cnt - 1) {
    }
  }, [props.selectedTimeStep, props.step_cnt]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        decrementValue();
      } else if (
        e.key === "ArrowRight" &&
        props?.step_cnt > props?.selectedTimeStep
      ) {
        incrementValue();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.step_cnt, props.selectedTimeStep, incrementValue, decrementValue]);

  // Preload current image URL
  useEffect(() => {
    preloadImages(props.imageUrl);
  }, [props.imageUrl]);

  return (
    <div className="col pt-1 workpage-wrapper mx-0">
      <div className="grid">
        <div className="row px-3">
          <div className="col-12 px-0 pb-4 mx-0 map-controls-container">
            <div className="row mw-100 px-0 mx-0">
              <div className="slider-container col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5 px-0">
                <div className="row pe-1 mx-0">
                  <div
                    className="col-1 h-25 d-inline-block text-center rounded slider-step-btn-container"
                    onClick={decrementValue}
                  >
                    <button className="font-size-sm">
                      <ArrowBackward />
                    </button>
                  </div>
                  <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8 col-7 px-1 mx-0 rounded">
                    <input
                      type="range"
                      min="0"
                      max={props.step_cnt - 1}
                      value={timeValue}
                      onChange={handleSliderChange}
                      className="slider rounded"
                      id="myRange"
                    />
                  </div>
                  <div
                    className="col-1 h-25 d-inline-block text-center rounded slider-step-btn-container"
                    onClick={incrementValue}
                  >
                    <button className="font-size-sm">
                      <ArrowForward />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-5 px-0 mx-0 h-25 d-inline-block slider-play-btn-container">
                <div className="row no-gutters justify-content-md-center">
                  <div
                    className="slider-play-btn col-xl-2 col-lg-2 col-md-2 col-1 d-inline-block text-center rounded"
                    onClick={decreaseSpeed}
                  >
                    <button className="font-size-sm">
                      <FastForward />
                    </button>
                  </div>
                  <div
                    className="slider-play-btn col-xl-2 col-lg-2 col-md-2 col-1 mx-1 text-center rounded"
                    onClick={togglePlayPause}
                  >
                    <button className="font-size-sm">
                      {playing ? <PauseBtn /> : <ArrowPlay />}
                    </button>
                  </div>
                  <div
                    className="slider-play-btn col-xl-2 col-lg-2 col-md-2 col-1 text-center rounded"
                    onClick={increaseSpeed}
                  >
                    <button className="font-size-sm">
                      <SlowForward />
                    </button>
                  </div>
                  <div
                    className="slider-play-btn col-xl-2 col-lg-2 col-md-2 col-1 mx-1 text-center rounded"
                    onClick={() => downloadCurrentImage(props.imageUrl)}
                  >
                    <button className="font-size-sm">
                      <SaveIcon />
                    </button>
                  </div>
                  <div
                    className="slider-play-btn col-xl-2 col-lg-2 col-md-2 col-1 text-center rounded"
                    onClick={saveAsGif}
                  >
                    <button className="px-1">
                      <div className="slider-gif-txt">GIF</div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2 px-0 mx-0">
                <div className="w-100">
                  <Button
                    size="sm"
                    onClick={toggleModal}
                    className="select-region-btn w-100"
                  >
                    <div className="select-region-txt d-none d-xl-block">
                      Select a Region
                    </div>
                    <div className="select-region-txt d-xl-none">Options</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="map-component-container col-12 pt-1 px-0">
            <img src={props.imageUrl} alt="product image" />
          </div>
        </div>
      </div>
      <RegionModal
        {...props}
        showModal={showRegionModal}
        handleCloseModal={toggleModal.bind(null, false)}
        selectedRegion={regionValue}
      />
    </div>
  );
};

export default MapPageContent;
