import React, { useState } from "react";
import { Page } from "@highmountainlabs/arclight-ui";
import Sidebar from "../components/sidebar/sidebar.jsx";
import Navbar from "../components/navbar/navbar.jsx";
import { Container, Row, Col } from "react-bootstrap";

import { useWindowResize } from "../hooks/windowResize";

import "../static/css/main.scss";
import "../static/css/fonts.scss";
import "../static/css/profile/profile.scss";

import AvatarWidget from "../components/profile/avatarWidget";
import ActivityFeed from "../components/profile/activityFeed";
import ProfileComponent from "../components/profile/mainComponent";

import init from "../hooks/init";

const ProfilePage = (props) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  
  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  useWindowResize(setSidebarCollapsed, setIsMobileView);
  init(props);

  return props?.D?.getrecords_settings?.init?.records ? (
    <Page fns={props.fns}>
      <div className="main-container">
        <div className="navbar-container">
          <Navbar {...props} />
        </div>
        <div className="container-fluid row full-height p-0 m-0">
          <div className={`col-2 sidebar-container p-0`}>
            <Sidebar
              {...props}
              sidebarCollapsed={sidebarCollapsed}
              onSidebarCollapse={handleSidebarCollapse}
            />
          </div>
          <div
            className={`col-8 app-container ${
              sidebarCollapsed ? "app-container-collapsed" : ""
            }`}
          >
            <div className="profile-container">
              <Container fluid>
                <Row className="pt-1 d-flex justify-content-center mx-0">
                  <Col
                    className="profile-avatar-wrapper mb-2"
                    style={{ height: "484px", minWidth: "400px" }}
                    sm={3}
                  >
                    <AvatarWidget username={props?.D?.verifyself?.msg?.User?.username} userAvatar={props?.D?.verifyself?.msg?.User?.profile_decorations?.avatar} {...props} />
                  </Col>
                  <Col
                    className="profile-activity-wrapper"
                    style={{
                      height: "400px",
                      minWidth: "400px",
                      maxHeight: "484px",
                    }}
                    sm={3}
                  >
                    <ActivityFeed />
                  </Col>
                  {!isMobileView ? (
                    <Col
                      className="profile-component-wrapper"
                      style={{ height: "484px", minWidth: "800px" }}
                      sm={6}
                    >
                      <ProfileComponent />
                    </Col>
                  ) : (
                    <Col
                      className="bg-danger"
                      style={{ height: "484px", minWidth: "400px" }}
                      sm={6}
                    >
                      {/* Content for mobile column */}
                    </Col>
                  )}
                </Row>
              </Container>
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : null;
};

export default ProfilePage;
