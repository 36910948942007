import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CelebrationIcon from "@mui/icons-material/Celebration";

const AccountCreated = ({ activityText }) => {
  return (
    <div className="profile-feed-component row">
      <div className="col-2 pt-1">
        <Avatar className="profile-mui-btn">
          <CelebrationIcon />
        </Avatar>
      </div>
      <div className="profile-activity-content col-10">
        <h6 className="profile-activity-header-text text-left">
          ACCOUNT CREATED
        </h6>
        <span className="profile-activity-text">{activityText}</span>
      </div>
    </div>
  );
};

export default AccountCreated;
