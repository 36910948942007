import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

function LoginModal({
  resetPassword,
  handleUsernameChange,
  handlePasswordChange,
  handlePasswordChangeConfirm,
  handleLogin,
  handleVerification,
  handleIssueClick, // Receive the function as a prop
}) {
  return (
    <form>
      <div className="form-group">
        {!handleVerification ? (
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="Username..."
            onChange={handleUsernameChange}
          />
        ) : null}
      </div>
      <div className="form-group pt-3">
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="Password..."
          onChange={handlePasswordChange}
        />
      </div>
      {resetPassword ? (
        <div className="form-group pt-3">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Confirm Password..."
            onChange={handlePasswordChangeConfirm}
          />
        </div>
      ) : null}
      <div className="button-container">
        <Button
          variant="primary"
          onClick={handleVerification ? handleVerification : handleLogin}
        >
          {resetPassword
            ? "Update Password"
            : handleVerification
            ? "Verify"
            : "Log in"}
        </Button>
      </div>
      {!handleVerification ? (
        <React.Fragment>
          <p className="text-center pt-1">
            <a className="footer-link text-center" onClick={handleIssueClick}>
              Trouble logging in?
            </a>
          </p>
          <hr className="divider" />
          <p className="text-center pt-2 main-theme-text">
            or use a social network
          </p>
          <div className="auth-sm-container text-center row mx-5 px-5 pt-2 pb-4">
            <div className="col-4">
              <FontAwesomeIcon
                icon="fa-brands fa-discord"
                className="fa-brands"
              />
            </div>
            <div className="col-4">
              <FontAwesomeIcon
                icon="fa-brands fa-google"
                className="fa-brands"
              />
            </div>
            <div className="col-4">
              <FontAwesomeIcon
                icon="fa-brands fa-twitter"
                className="fa-brands"
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </form>
  );
}

export default LoginModal;
