import React, { useEffect } from "react";
import { SubMenu as ProSubMenu, MenuItem } from "react-pro-sidebar";
import MapIcon from "@mui/icons-material/Map";

const ModelSubMenu = (props) => {
  return (
    <ProSubMenu
      icon={<MapIcon />}
      label="Model"
      className="sidebar-submenu pt-2"
    >
      <MenuItem
        href="/map"
        // onClick={() => {
        //   // props.fns.calls.updaterecords_user({
        //   //   $set: { username: "administrator", city: "test city" },
        //   // });
        //   // window.location.href = "/";
        // }}
      >
        Climate
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   // props.fns.calls.getrecords_user({
      //   //   search: { username: "administrator2" },
      //   //   index: "123",
      //   // });
      //   // console.log(props);
      // }}
      >
        Global/Dynamic
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.verifyRegisterUser("test");
      // }}
      >
        Ensemble
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.route("/profile");
      // }}
      >
        Mesoscale
      </MenuItem>
      <MenuItem
      // onClick={() => {
      //   props.fns.calls.getrecords_user({ _self: true, _recursive: true });
      // }}
      >
        Superblends
      </MenuItem>
    </ProSubMenu>
  );
};

export default ModelSubMenu;
