import React, { useState } from "react";
import { Modal, Button, Nav } from "react-bootstrap";

import "../../static/css/modal/regionModal.scss";
import "../../static/css/modal/modal.scss";

import SelectLocation from "../product/selectLocation";

function RegionModal(props) {
  const { showModal, handleCloseModal } = props;

  const handleRegionCategoryChange = (newCategory) => {
    props.onRegionCategoryChange(newCategory);
  };

  const handleRegionChange = (newRegion) => {
    props.onRegionChange(newRegion);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      className="region-modal"
      fullscreen="xxl-down"
    >
      <Modal.Header className="custom-modal-header">
        <Modal.Title className="custom-modal-title">
          Region
          <button
            type="button"
            className="btn-close btn-modal-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <div className="region-modal-wrapper">
          <div className="region-modal-info-container">
            <Nav
              variant="tabs"
              defaultActiveKey={props?.selectedRegionCategory}
              activeKey={props?.selectedRegionCategory}
            >
              <Nav.Item>
                <Nav.Link
                  href="#"
                  eventKey="global"
                  onClick={(e) => handleRegionCategoryChange("global")}
                  className="region-modal-btn"
                >
                  Global
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="tropics"
                  onClick={(e) => handleRegionCategoryChange("tropics")}
                  className="region-modal-btn"
                >
                  Tropics
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="north_america"
                  onClick={(e) => handleRegionCategoryChange("north_america")}
                  className="region-modal-btn"
                >
                  North America
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="regional"
                  onClick={(e) => handleRegionCategoryChange("regional")}
                  className="region-modal-btn"
                >
                  Regional
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="local"
                  onClick={(e) => handleRegionCategoryChange("local")}
                  className="region-modal-btn"
                  disabled
                >
                  Local
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="model"
                  onClick={(e) => handleRegionCategoryChange("model")}
                  className="region-modal-btn d-xl-none"
                  disabled
                >
                  Model
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="param"
                  onClick={(e) => handleRegionCategoryChange("local")}
                  className="region-modal-btn d-xl-none"
                  disabled
                >
                  Param
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <SelectLocation {...props} onRegionChange={handleRegionChange} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RegionModal;
