export const fetchUserTheme = async (props) => {
  const D = props?.D?.verifyself?.msg?.User?.profile_decorations?.profile_theme;

  if (D) {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", D.primarycolor);
    root.style.setProperty("--secondary-color", D.secondarycolor);
    root.style.setProperty("--background-color", D.backgroundcolor);
    root.style.setProperty("--background-gradient", D.backgroundgradient);
    root.style.setProperty("--font-color", D.fontcolor);
    root.style.setProperty("--secondary-font-color", D.fontsecondarycolor);
    root.style.setProperty("--header-color", D.headercolor);
    root.style.setProperty("--hover-color", D.hovercolor);
    root.style.setProperty("--link-color", D.linkcolor);
    root.style.setProperty("--link-hover-color", D.linkhovercolor);
    root.style.setProperty("--badge-success", D.badgesuccesscolor);
    root.style.setProperty("--badge-warning", D.badgewarningcolor);
    // root.style.setProperty("--graph-color-one", theme.graphcolor1);
    // root.style.setProperty("--graph-color-two", theme.graphcolor2);
    // root.style.setProperty("--graph-color-three", theme.graphcolor3);
    // root.style.setProperty("--graph-color-four", theme.graphcolor4);
    root.style.setProperty("--background-image", `url('${D.backgroundimg}')`);
  }
};

export const resetTheme = () => {
  const root = document.documentElement;
  root.style.setProperty("--primary-color", "#035565");
  root.style.setProperty("--secondary-color", "#1eadc5");
  root.style.setProperty("--background-color", "#002236");
  root.style.setProperty("--background-gradient", "#034870");
  root.style.setProperty("--font-color", "#35d2eb");
  root.style.setProperty("--secondary-font-color", "#9acfd5");
  root.style.setProperty("--header-color", "#1eadc5");
  root.style.setProperty("--hover-color", "#19d9f9");
  root.style.setProperty("--link-color", "#79dfed");
  root.style.setProperty("--link-hover-color", "#c3dfe3");
  root.style.setProperty("--background-image", "none");
  root.style.setProperty("--badge-success", "#1eadc5");
  root.style.setProperty("--badge-warning", "#035565");
  root.style.setProperty("--graph-color-one", "#35d2eb");
  root.style.setProperty("--graph-color-two", "#9acfd5");
  root.style.setProperty("--graph-color-three", "#35ebc7");
  root.style.setProperty("--graph-color-four", "#6fe6ff");
};
