import React from "react";

import "../../static/css/navbar/premiumButton.css";

function PremiumButton(props) {
  //  console.log(props)
  return (
    <div className="btn-premium-wrapper d-none d-sm-block">
      <button
        className="btn-premium"
        onClick={() => {
          props.fns.calls.updateuser({
            // username: "test_user"
            profiletheme: "",
          });
        }}
        // style={{backgroundColor: props?.D?.verifyself?.msg?.User?.profile_decorations?.profile_theme?.backgroundcolor}}
      >
        <span>GO PREMIUM</span>
      </button>
    </div>
  );
}

export default PremiumButton;
