import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import "../../static/css/modal/authModal.scss";
import "../../static/css/modal/modal.scss";

import LoginModal from "../auth/loginContainer";
import RegisterModal from "../auth/registerContainer";
import VerifiedModal from "./verifiedModal";
import AuthIssueModal from "../auth/issueContainer";

function AuthModal({ showModal, handleCloseModal, props }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentScreen, setCurrentScreen] = useState("login");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSignUpClick = () => {
    setCurrentScreen("register");
  };

  const handleLoginClick = () => {
    setCurrentScreen("login");
  };

  const handleIssueClick = () => {
    setCurrentScreen("issue");
  };

  const handlePasswordReset = (obj) => {
    if (
      obj?.email &&
      !obj?.username &&
      props?.fns?.calls?.lostusernamebyemail
    ) {
      props.fns.calls.lostusernamebyemail({
        email: obj.email,
        username: obj.username,
        redirect:
          process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://skywatchpro.us/verification"
            : "http://localhost:3000/verification",
      });
    } else if (obj?.username && props?.fns?.calls?.resetuserpassword) {
      props.fns.calls.resetuserpassword({
        username: obj.username,
        redirect:
          process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://skywatchpro.us/verification"
            : "http://localhost:3000/verification",
      });
    }
  };

  const handleLogin = () => {
    props.fns.authenticate({
      username: username,
      password: password,
    });
    handleCloseModal();
    setTimeout(function () {
      window.location.href = "/";
    }, 250);
  };

  const handleSignUp = () => {
    props.fns.calls.registeruser({
      username,
      password,
      email,
      redirect:
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "https://skywatchpro.us/verification"
          : "http://localhost:3000/verification",
    });

    handleCloseModal();
    setTimeout(function () {
      window.location.href = "/";
    }, 100);
  };

  if (props.fns.readToken()._token !== undefined) {
    return (
      <VerifiedModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        username={username}
        props={props}
      />
    );
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      fullscreen="xxl-down"
      className={`auth-modal ${showModal ? "show" : ""}`}
    >
      <Modal.Header className="custom-modal-header">
        <Modal.Title className="custom-modal-title">
          {currentScreen === "login" && "Log in"}
          {currentScreen === "register" && "Sign Up"}
          {currentScreen === "issue" && "Access Skywatch Pro Account"}
          <button
            type="button"
            className="btn-close btn-modal-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <div
          style={{ display: currentScreen === "login" ? "block" : "none" }}
          className="auth-modal-animation"
        >
          <LoginModal
            handleUsernameChange={handleUsernameChange}
            handlePasswordChange={handlePasswordChange}
            handleLogin={handleLogin}
            handleIssueClick={handleIssueClick}
          />
        </div>
        <div
          style={{ display: currentScreen === "register" ? "block" : "none" }}
          className="auth-modal-animation"
        >
          <RegisterModal
            handleUsernameChange={handleUsernameChange}
            handlePasswordChange={handlePasswordChange}
            handleEmailChange={handleEmailChange}
            handleSignUp={handleSignUp}
          />
        </div>
        <div
          style={{ display: currentScreen === "issue" ? "block" : "none" }}
          className="auth-modal-animation"
        >
          <AuthIssueModal
            {...props}
            handleUsernameChange={handleUsernameChange}
            handlePasswordChange={handlePasswordChange}
            handleEmailChange={handleEmailChange}
            handlePasswordReset={handlePasswordReset}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer justify-content-center">
        <p className="text-center">
          {currentScreen === "login" && (
            <>
              Not a member yet?{" "}
              <span className="footer-link" onClick={handleSignUpClick}>
                Sign up!
              </span>{" "}
            </>
          )}
          {currentScreen === "register" && (
            <>
              Already a member?{" "}
              <span className="footer-link" onClick={handleLoginClick}>
                Log in!
              </span>{" "}
            </>
          )}
          {currentScreen === "issue" && (
            <>
              <p>
                {" "}
                Already have an Account?{" "}
                <span className="footer-link" onClick={handleLoginClick}>
                  Log in
                </span>{" "}
                or{" "}
                <span className="footer-link" onClick={handleSignUpClick}>
                  Sign up.
                </span>
              </p>
            </>
          )}
        </p>
      </Modal.Footer>
    </Modal>
  );
}

export default AuthModal;
