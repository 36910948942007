import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const StreamComponent = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  gridColor,
}) => {
  const data = [
    {
      name: "January",
      followers: 4000,
      views: 2400,
      streamed: 2400,
    },
    {
      name: "February",
      followers: 3000,
      views: 1398,
      streamed: 2210,
    },
    {
      name: "March",
      followers: 2000,
      views: 9800,
      streamed: 2290,
    },
    {
      name: "April",
      followers: 2780,
      views: 3908,
      streamed: 2000,
    },
    {
      name: "May",
      followers: 1890,
      views: 4800,
      streamed: 2181,
    },
    {
      name: "June",
      followers: 2390,
      views: 3800,
      streamed: 2500,
    },
    {
      name: "July",
      followers: 3490,
      views: 4300,
      streamed: 2100,
    },
  ];

  return (
    <div>
      <h5 className="profile-header-text pt-1 text-center">
        Monthly Stream Statistics
      </h5>
      <ResponsiveContainer width="100%" height={395}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={gridColor}
            strokeWidth={0.5}
          />
          <XAxis
            dataKey="name"
            stroke={primaryColor}
            strokeWidth={0.9}
            interval={0}
          />
          <YAxis stroke={primaryColor} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="views"
            stroke={primaryColor}
            activeDot={{ r: 8 }}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="followers"
            stroke={secondaryColor}
            strokeWidth={2}
          />

          <Line
            type="monotone"
            dataKey="streamed"
            stroke={tertiaryColor}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StreamComponent;
