import React, { useState } from "react";
import { Button } from "react-bootstrap";

function AuthIssueModal({ handlePasswordReset, handleUsernameCheck, D }) {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  return (
    <form>
      <div className="form-group">
        <p className="text-center">
          <span>Enter your email to retrieve your username.</span>
        </p>
        <div className="row">
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Username..."
              value={D?.lostusernamebyemail?.msg}
              disabled
            />
          </div>
        </div>
        <div className="button-container">
          <Button
            variant="primary"
            onClick={() => handlePasswordReset({ email })}
          >
            Retrieve Username
          </Button>
        </div>
        <div className="separator pt-3">
          <span className="px-2">or</span>
        </div>
        <p className="text-center">
          <span>Send an email with password reset instructions.</span>
        </p>
        <input
          type="text"
          className="form-control"
          id="username"
          placeholder="Username..."
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="button-container">
        <Button
          variant="primary"
          onClick={() => handlePasswordReset({ username })}
        >
          Reset Password
        </Button>
      </div>
    </form>
  );
}

export default AuthIssueModal;
