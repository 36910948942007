import React from "react";

const DateDivider = ({ dateText }) => {
  return (
    <div className="row align-items-center profile-activity-date-container">
      <div className="col-auto">
        <h6 className="profile-activity-date">{dateText}</h6>
      </div>
      <div className="col">
        <hr className="profile-date-hr" />
      </div>
    </div>
  );
};

export default DateDivider;
