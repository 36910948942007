import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "@highmountainlabs/arclight-ui";
import { ProSidebarProvider } from "react-pro-sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProSidebarProvider>
      <App
        nopage={"https://highmountainlabs.io/cdn/arclight/media/404.jpg"}
        socketEndpoint={`https://skywatchpro.us:7000`}
        loader={`https://skywatchpro.us/arclight/static/media/661efb9c4393380b3c8fc735.png`}
        pages={{
          _root_: {
            Home: {
              route: "/",
              component: require("./pages/homePage.jsx"),
            },
            Verification: {
              route: "/verification",
              component: require("./pages/verificationPage.jsx"),
            },
            Profile: {
              route: "/profile",
              component: require("./pages/profilePage.jsx"),
            },
            Article: {
              route: "/article",
              component: require("./pages/articlePage.jsx"),
            },
            Map: {
              route: "/map",
              component: require("./pages/mapPage.jsx"),
            },
          },
          admin: {
            Home: {
              route: "/",
              backgroundImage:
                "https://skywatchpro.us/arclight/static/media/661f0da3f881663b74ebc129.png",
              authBackgroundImage:
                "https://skywatchpro.us/arclight/static/media/661f0d5ef881663b74ebc0c7.png",
              noSelect:
                "https://skywatchpro.us/arclight/static/media/661efb9c4393380b3c8fc735.png",
            },
          },
        }}
      />
    </ProSidebarProvider>
  </React.StrictMode>
);
