import React, { useEffect, useState, useRef } from "react";
import "../../static/css/homepage/homepage.scss";
import data from "../../static/json/product_dict.json";

function SelectLocation(props) {
  const [fullscreen, setFullscreen] = useState(true);
  const [hoveredId, setHoveredId] = useState(null);
  const mapRef = useRef(null);

  const selectedModelCategory = data.product[0].model_category.find(
    (category) => Object.keys(category).includes(props.modelCategoryChoice)
  );

  const availableRegions =
    selectedModelCategory[props.modelCategoryChoice][0].available_regions[0];

  const regionCategories = Object.keys(availableRegions);

  const newRegionsArray = regionCategories.flatMap((category) =>
    data.region
      .filter((region) => region.category === category)
      .filter((region) => region.category === props.selectedRegionCategory)
  );

  const handleRegionChange = (newRegion) => {
    props.onRegionChange(newRegion);
    props.handleCloseModal(false);
  };

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 920.000000 550.000000"
      preserveAspectRatio="xMidYMid meet"
      id="svg-world-map"
      className="interactive-map-svg"
      ref={mapRef}
    >
      <linearGradient id="interactive-map-gradient" x2="0" y2="1">
        <stop offset="40%" stopColor="var(--color-stop)" />
        <stop offset="80%" stopColor="var(--color-bot)" />
      </linearGradient>
      <g
        transform="translate(0.000000,550.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="url(#interactive-map-gradient)"
      >
        <path
          d="M7570 5478 c0 -21 0 -21 -15 -2 -14 18 -14 18 -15 -6 -1 -23 -2 -23
                -9 -5 -8 19 -8 19 -15 -2 -9 -27 -24 -29 -41 -6 -12 16 -14 16 -29 1 -10 -10
                -12 -19 -6 -23 21 -13 9 -24 -15 -13 -14 6 -25 15 -25 20 0 5 -5 6 -12 2 -7
                -4 -22 -8 -35 -9 -12 0 -28 -7 -35 -14 -11 -12 -10 -13 4 -8 13 5 17 2 15 -13
                -2 -11 -6 -23 -10 -26 -4 -4 1 -13 11 -20 15 -12 15 -12 -5 -7 -24 6 -29 -3
                -14 -26 5 -8 16 -11 24 -8 9 3 14 0 13 -11 -1 -10 8 -18 22 -20 12 -2 22 1 22
                7 0 6 7 8 16 4 13 -5 13 -7 2 -14 -8 -6 -1 -9 21 -9 19 0 43 8 54 18 11 9 17
                12 14 5 -4 -6 0 -16 9 -21 12 -7 1 -11 -41 -16 -31 -3 -61 -4 -66 -1 -8 5 -14
                -24 -10 -47 0 -5 -3 -8 -9 -8 -6 0 -10 11 -10 25 0 14 -8 29 -21 36 -27 14
                -40 2 -43 -42 -5 -60 -15 -79 -43 -79 -22 0 -62 -33 -63 -52 0 -5 12 -8 28 -9
                15 0 21 -3 15 -6 -18 -7 -16 -43 2 -43 8 0 15 -4 15 -10 0 -26 -46 -4 -51 25
                -3 11 -13 21 -23 23 -16 4 -18 0 -13 -27 6 -29 5 -31 -23 -31 -16 0 -35 -7
                -43 -15 -12 -11 -17 -12 -20 -2 -4 8 -6 8 -6 0 -1 -16 87 -66 102 -56 18 11
                37 12 37 2 0 -5 3 -8 8 -8 23 4 34 -3 28 -17 -5 -12 -2 -15 11 -9 10 4 26 9
                35 12 18 5 33 33 18 33 -18 0 -10 29 10 35 11 3 25 15 30 26 6 10 21 19 34 19
                32 0 70 43 61 69 -5 16 -3 19 8 15 8 -3 18 -1 22 5 3 6 -2 11 -12 11 -10 0
                -25 5 -33 10 -10 6 2 7 34 4 47 -5 49 -5 43 18 -5 21 -5 21 7 6 18 -24 43 -23
                51 2 4 11 11 18 16 15 5 -4 9 -1 9 4 0 6 7 11 15 11 8 0 15 4 15 8 0 5 17 14
                38 21 21 7 49 21 62 32 l25 18 -50 -3 c-27 -2 -35 -1 -17 1 17 2 32 8 32 14 0
                5 15 9 34 9 43 0 96 29 96 53 0 9 -4 17 -10 17 -5 0 -9 10 -7 23 2 19 -2 22
                -33 20 -28 -1 -31 1 -17 8 21 12 13 29 -14 29 -10 0 -19 4 -19 9 0 13 -53 21
                -67 9 -10 -8 -13 -6 -13 6 0 9 -3 16 -7 17 -5 0 -16 2 -25 4 -12 2 -18 -3 -18
                -16 0 -25 -12 -24 -28 1 -16 26 -32 25 -32 -2z m-232 -355 c-5 -7 -14 -10 -19
                -7 -16 10 -9 23 10 21 15 -1 17 -4 9 -14z m-33 -133 c3 -6 -1 -7 -9 -4 -18 7
                -21 14 -7 14 6 0 13 -4 16 -10z"
        />
        <path
          d="M7249 5397 c13 -16 5 -27 -21 -27 -15 0 -18 -5 -13 -21 5 -14 4 -19
                -4 -15 -6 4 -18 3 -27 -2 -14 -8 -14 -10 1 -26 20 -20 12 -35 -9 -17 -11 9
                -16 6 -25 -21 -10 -29 -9 -34 11 -50 13 -10 19 -18 13 -19 -5 -1 -17 -2 -25
                -3 -9 0 -15 -10 -14 -23 0 -13 -3 -23 -8 -23 -5 0 -4 -9 2 -20 13 -25 29 -25
                46 -2 8 10 13 12 14 5 0 -20 20 -15 20 5 0 13 2 14 9 3 7 -11 11 -9 21 9 6 12
                14 19 17 16 3 -3 14 -1 24 5 13 7 18 20 17 42 -1 18 2 46 5 62 6 23 5 25 -2
                10 -9 -19 -10 -19 -10 3 -1 12 -6 22 -12 22 -7 0 -9 12 -5 36 7 35 -5 64 -26
                64 -7 0 -6 -5 1 -13z"
        />
        <path
          d="M8265 5392 c-3 -8 -12 -12 -20 -9 -9 4 -12 1 -8 -9 4 -10 0 -12 -13
                -8 -11 4 -24 11 -31 16 -8 6 -16 3 -24 -10 -7 -11 -19 -18 -28 -15 -13 4 -13
                3 -2 -5 19 -14 4 -34 -20 -27 -19 6 -20 3 -13 -32 4 -21 10 -48 15 -59 6 -18
                4 -21 -10 -17 -20 5 -36 32 -46 76 -7 36 -29 30 -33 -9 -5 -42 -42 -63 -42
                -23 0 18 -3 19 -15 9 -12 -10 -13 -6 -8 26 6 34 4 37 -13 29 -10 -5 -36 -10
                -57 -13 -36 -4 -39 -6 -35 -33 3 -15 1 -26 -4 -23 -4 3 -8 14 -8 25 0 24 -30
                26 -30 2 -1 -33 -26 -54 -54 -47 -19 4 -28 2 -32 -10 -3 -9 -12 -16 -19 -16
                -6 0 -20 -9 -31 -21 -18 -20 -18 -21 0 -34 17 -13 17 -15 0 -44 -14 -24 -25
                -31 -49 -31 -31 0 -133 -24 -142 -34 -3 -3 -2 -13 2 -23 5 -10 7 -22 6 -27 -1
                -5 5 -6 12 -3 10 4 13 0 10 -13 -4 -15 1 -18 24 -17 15 1 28 -2 28 -7 0 -6
                -19 -11 -43 -12 -54 -2 -61 -9 -33 -33 19 -16 19 -19 5 -25 -15 -6 -15 -8 1
                -26 13 -14 22 -17 30 -10 6 5 14 8 16 5 3 -2 21 -6 42 -8 20 -2 52 -16 70 -30
                30 -23 34 -30 32 -71 0 -25 2 -46 5 -46 3 0 7 -30 7 -67 1 -65 -8 -100 -30
                -114 -5 -4 -6 -12 -3 -18 8 -13 36 -15 36 -3 0 5 7 9 15 9 11 0 13 -6 9 -20
                -4 -12 -2 -17 4 -13 6 3 13 2 17 -4 3 -5 2 -10 -3 -10 -6 0 -8 -10 -5 -22 3
                -15 2 -19 -4 -11 -5 6 -20 16 -34 21 -22 9 -26 7 -38 -19 -17 -38 -8 -59 24
                -59 25 0 34 14 15 25 -5 3 -10 11 -10 18 0 8 3 8 12 -1 7 -7 20 -12 30 -12 19
                0 24 -11 11 -24 -5 -4 -8 -11 -8 -15 0 -4 -8 -15 -17 -25 -10 -10 -18 -15 -18
                -11 0 3 -8 1 -19 -4 -25 -14 -52 -72 -40 -87 7 -8 6 -13 -2 -16 -15 -5 7 -108
                23 -108 7 0 7 -4 0 -12 -11 -13 -5 -68 7 -68 4 0 8 -8 9 -17 1 -10 4 -27 6
                -38 2 -11 5 -24 5 -28 1 -5 6 -5 11 -2 6 3 10 -1 10 -10 0 -13 4 -14 20 -5 17
                9 22 7 31 -14 6 -13 21 -27 32 -30 26 -6 25 -7 31 28 3 15 9 33 15 39 5 7 11
                21 13 32 1 11 13 29 26 40 13 11 24 30 25 43 1 12 5 22 10 22 5 0 7 6 5 12 -6
                17 16 38 40 38 10 0 24 10 31 23 11 21 11 21 7 0 -3 -20 -2 -20 28 -8 29 12
                116 92 116 106 0 4 9 5 20 2 31 -8 110 17 159 50 41 28 43 31 26 44 -11 7 -33
                14 -49 14 -24 1 -26 3 -8 6 32 6 30 50 -3 63 -14 5 -25 14 -25 19 0 22 61 -23
                74 -54 11 -25 20 -33 36 -32 18 1 21 7 19 37 -3 46 -14 67 -39 75 -11 4 -18
                10 -15 15 3 5 0 12 -6 16 -8 4 -7 9 2 15 20 13 29 11 23 -4 -2 -7 5 -22 17
                -33 22 -19 22 -19 17 4 -4 12 -2 20 3 16 5 -3 9 -1 9 5 0 5 -13 18 -29 27 -17
                10 -27 22 -24 27 3 4 15 -1 27 -12 20 -19 22 -19 45 -2 l24 19 -22 7 c-23 7
                -26 35 -3 43 8 2 10 -2 5 -13 -5 -14 -2 -16 18 -10 34 10 36 15 16 26 -21 12
                -23 39 -2 39 17 0 20 35 4 46 -8 4 -7 9 2 15 10 6 8 9 -8 9 -27 0 -30 22 -7
                41 12 10 20 10 35 1 12 -8 20 -8 21 -2 4 29 2 35 -12 35 -9 0 -15 12 -17 33
                -2 18 1 31 6 27 5 -3 15 19 21 50 6 30 16 57 21 60 5 4 14 15 20 26 9 18 8 20
                -15 17 -14 -2 -25 2 -25 7 -1 6 16 10 36 10 20 0 38 6 40 13 3 7 -4 12 -17 12
                -12 0 -19 4 -16 9 3 5 15 7 26 4 13 -4 29 4 47 21 14 14 30 26 34 26 13 0 11
                13 -3 33 -20 25 -62 31 -82 12 -9 -9 -23 -14 -31 -11 -8 3 -15 1 -15 -5 0 -5
                -17 -17 -37 -26 -21 -8 -47 -22 -58 -31 -14 -12 -12 -8 5 15 14 18 30 32 37
                32 13 1 33 32 33 50 0 17 -27 13 -34 -4 -3 -9 -18 -15 -38 -15 -30 1 -31 2
                -15 14 24 19 22 23 -17 30 -22 4 -48 1 -70 -8 -20 -8 -36 -11 -36 -6 0 17 76
                34 140 32 46 -2 71 2 84 13 19 14 19 15 0 36 -14 15 -26 19 -49 15 -28 -5 -29
                -5 -11 9 11 8 16 16 10 17 -60 10 -148 11 -179 2 -15 -5 -17 -3 -9 6 7 6 44
                13 83 15 40 1 74 5 77 7 15 16 -87 49 -143 46 -18 0 -35 -8 -38 -16z m-78 -83
                c1 -19 1 -20 -4 -4 -3 11 -10 24 -17 28 -7 6 -6 7 4 4 8 -2 16 -14 17 -28z
                m83 -919 c-6 -11 -15 -20 -20 -20 -6 0 -5 9 2 20 7 11 16 20 21 20 4 0 3 -9
                -3 -20z m-555 -90 c8 -13 -5 -13 -25 0 -13 8 -13 10 2 10 9 0 20 -4 23 -10z"
        />
        <path
          d="M1284 5376 c-10 -29 -4 -79 9 -88 19 -12 46 -9 65 8 25 21 12 50 -34
                80 l-32 22 -8 -22z"
        />
        <path
          d="M7086 5305 c4 -8 7 -15 9 -15 2 0 5 7 9 15 3 9 0 15 -9 15 -9 0 -12
                -6 -9 -15z"
        />
        <path
          d="M1372 5294 c-38 -26 0 -86 57 -91 13 -1 30 -4 37 -7 28 -11 34 19 13
                62 -12 23 -24 43 -28 43 -40 7 -62 5 -79 -7z"
        />
        <path
          d="M6934 5288 c-3 -5 -2 -14 4 -20 14 -14 2 -49 -16 -46 -8 2 -17 -3
                -19 -9 -3 -8 4 -13 15 -13 27 0 45 -25 37 -50 -6 -18 -3 -21 11 -18 11 2 20
                12 22 23 2 11 11 28 20 38 11 13 13 20 5 28 -6 6 -9 18 -6 25 3 8 -1 14 -11
                14 -9 0 -16 6 -16 14 0 17 -37 28 -46 14z"
        />
        <path
          d="M2463 5261 c3 -14 0 -17 -14 -14 -11 3 -19 9 -19 14 0 12 -27 11 -35
                -1 -12 -20 19 -80 49 -95 39 -20 40 -20 73 13 l28 29 -22 17 c-13 9 -23 26
                -23 36 0 14 -7 20 -21 20 -16 0 -20 -5 -16 -19z"
        />
        <path
          d="M2510 5277 c0 -3 4 -17 10 -31 7 -19 17 -26 35 -26 25 0 32 14 17 37
                -7 12 -62 29 -62 20z"
        />
        <path
          d="M2619 5269 c-10 -6 -6 -11 16 -19 38 -14 69 -9 73 12 3 13 -4 17 -36
                16 -22 0 -46 -4 -53 -9z"
        />
        <path
          d="M6752 5272 c-8 -2 1 -11 20 -22 37 -20 48 -18 48 10 0 14 -7 18 -27
                18 -16 -1 -34 -3 -41 -6z"
        />
        <path d="M1306 5261 c-7 -11 18 -33 27 -24 4 3 7 12 7 20 0 15 -26 18 -34 4z" />
        <path
          d="M1525 5248 c6 -22 12 -51 14 -73 4 -35 19 -36 56 -2 43 39 41 49 -15
                77 -52 26 -64 26 -55 -2z"
        />
        <path
          d="M629 5250 c-1 -5 -2 -16 -3 -22 -1 -7 5 -18 14 -25 12 -9 9 -10 -15
                -7 -17 2 -22 1 -12 -2 9 -3 17 -12 17 -20 0 -8 7 -14 15 -14 16 0 21 35 6 44
                -4 3 -7 17 -6 31 2 14 -1 25 -6 25 -5 0 -10 -4 -10 -10z"
        />
        <path
          d="M746 5251 c-4 -5 -3 -16 0 -25 5 -12 10 -14 20 -6 7 7 10 18 7 26 -6
                16 -19 18 -27 5z"
        />
        <path d="M6686 5241 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M6621 5222 c-1 -8 -9 -10 -26 -6 -16 4 -42 -3 -77 -20 -28 -14 -58
                -26 -64 -26 -24 0 -26 -22 -5 -41 19 -18 23 -18 42 -5 11 8 27 24 35 35 22 30
                39 26 19 -5 -22 -34 -13 -41 23 -15 35 25 65 68 58 84 -3 9 -5 9 -5 -1z"
        />
        <path
          d="M6700 5215 c-10 -12 -10 -18 0 -30 16 -20 50 -19 50 0 0 9 8 19 17
                22 15 6 15 7 -3 14 -30 12 -51 10 -64 -6z"
        />
        <path
          d="M708 5185 c4 -37 8 -40 31 -26 10 6 10 10 1 16 -9 6 -9 10 1 16 11 7
                10 9 -3 9 -10 0 -18 5 -18 10 0 6 -4 10 -8 10 -4 0 -6 -16 -4 -35z"
        />
        <path d="M2360 5206 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z" />
        <path
          d="M7041 5196 c-7 -8 -14 -28 -16 -45 -4 -27 -1 -31 20 -31 30 0 41 30
                22 65 -11 23 -15 24 -26 11z"
        />
        <path
          d="M530 5155 c-5 -14 -14 -25 -20 -25 -16 0 -12 -37 5 -44 9 -3 15 0 15
                10 0 8 7 22 15 30 17 18 20 34 4 34 -5 0 -7 5 -4 10 3 6 4 10 0 10 -3 0 -10
                -11 -15 -25z"
        />
        <path
          d="M460 5145 c-10 -12 -10 -19 -2 -27 7 -7 13 -9 13 -5 1 4 2 10 3 15 1
                4 5 13 9 20 12 17 -8 15 -23 -3z"
        />
        <path
          d="M6841 5153 c0 -6 -4 -20 -7 -30 -5 -14 -4 -15 5 -7 7 7 11 20 8 30
                -3 11 -5 14 -6 7z"
        />
        <path d="M6910 5152 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28 10z" />
        <path
          d="M665 5137 c-13 -10 -15 -16 -6 -21 12 -8 41 10 41 25 0 13 -13 11
                -35 -4z"
        />
        <path
          d="M1639 5124 c-12 -34 6 -94 29 -95 11 0 6 -6 -12 -13 -22 -10 -25 -14
                -12 -19 9 -4 16 -12 15 -19 0 -10 -2 -10 -6 0 -7 17 -21 15 -45 -8 -26 -24
                -40 -26 -31 -4 4 12 2 15 -8 10 -8 -3 -22 -9 -32 -12 -9 -4 -16 -13 -15 -21 2
                -9 -6 -20 -16 -27 -36 -21 -71 -62 -72 -84 -1 -12 3 -22 8 -22 5 0 7 -4 4 -9
                -3 -4 6 -13 19 -19 26 -12 32 -27 16 -36 -5 -4 -11 0 -14 6 -3 10 -7 10 -18 2
                -8 -6 -31 -17 -51 -23 -43 -14 -44 -16 -22 -59 9 -19 23 -32 35 -32 10 0 19
                -4 19 -9 0 -5 8 -11 19 -14 10 -2 26 -19 35 -37 20 -40 20 -39 -1 -36 -11 2
                -19 13 -21 30 -3 19 -8 26 -21 24 -10 -2 -24 2 -32 8 -20 17 -88 34 -94 24 -3
                -5 4 -12 15 -15 28 -9 19 -31 -10 -27 -21 4 -22 2 -11 -12 7 -9 22 -16 32 -16
                10 0 21 -5 24 -11 5 -7 -6 -9 -31 -7 -34 3 -40 7 -50 37 -11 33 -11 33 -19 10
                -10 -33 -6 -51 16 -75 10 -11 19 -32 19 -45 0 -14 6 -34 14 -45 12 -16 18 -17
                34 -7 23 14 68 -1 77 -26 9 -24 -4 -25 -22 -2 -12 15 -24 20 -36 16 -18 -8
                -23 -40 -7 -50 11 -7 5 -42 -13 -87 -12 -28 -36 -35 -71 -22 -26 10 -18 27 9
                20 22 -6 25 -2 35 33 11 41 6 65 -18 94 -9 9 -23 40 -33 67 -10 28 -25 57 -33
                66 -9 8 -16 28 -16 44 0 48 -9 60 -46 60 l-34 0 0 -49 c0 -28 -4 -53 -10 -56
                -15 -9 -12 -42 5 -49 8 -3 15 -9 14 -13 -4 -26 3 -53 14 -53 19 -1 57 -36 57
                -54 0 -17 -15 -22 -25 -7 -11 18 -118 61 -152 61 -18 0 -44 7 -57 16 -14 8
                -27 11 -30 6 -3 -5 7 -15 23 -21 33 -14 64 -62 43 -68 -6 -3 -10 -9 -6 -14 3
                -5 0 -9 -5 -9 -6 0 -11 7 -11 16 0 11 -6 14 -22 8 -13 -3 -24 -10 -26 -15 -2
                -4 -9 -6 -16 -3 -7 3 -19 -2 -26 -11 -16 -20 -41 -20 -34 0 4 9 0 15 -10 15
                -9 0 -16 -7 -16 -15 0 -8 -6 -12 -13 -9 -15 6 -77 -44 -77 -62 0 -6 -7 -17
                -15 -24 -13 -10 -19 -9 -36 6 -19 17 -19 19 -3 31 14 10 15 15 4 28 -7 8 -23
                15 -37 15 -19 0 -22 -3 -14 -17 5 -10 12 -39 16 -66 6 -42 5 -47 -10 -41 -38
                15 -90 -41 -65 -71 18 -22 -6 -28 -42 -11 -38 19 -38 19 -38 1 0 -8 9 -19 21
                -26 14 -7 18 -14 11 -21 -14 -14 -68 16 -77 44 -4 13 -6 31 -6 40 1 9 -7 19
                -17 22 -11 4 -28 16 -38 28 -19 21 -19 21 1 13 50 -19 111 -35 136 -35 31 0
                65 39 56 64 -11 27 -130 91 -182 96 -37 4 -49 9 -47 20 3 11 0 12 -8 4 -12
                -12 -60 -7 -60 6 0 4 10 11 22 17 21 9 21 10 -10 21 -27 9 -32 9 -32 -5 0 -19
                -10 -12 -13 9 -4 23 -22 20 -29 -4 -5 -20 -6 -21 -7 -3 -2 18 -3 18 -17 -5
                l-15 -25 6 27 c4 22 2 26 -10 22 -8 -4 -15 -12 -15 -19 0 -14 -21 -25 -53 -26
                -12 0 -16 -3 -9 -6 23 -9 12 -21 -18 -18 -18 2 -30 -2 -30 -9 0 -18 -43 -56
                -63 -56 -9 0 -17 -4 -17 -9 0 -4 12 -6 27 -4 l28 4 -27 -16 -28 -16 1 -289 c0
                -160 3 -290 6 -290 3 0 11 7 17 15 6 9 21 17 32 17 18 2 20 9 20 51 -1 33 4
                52 15 60 27 20 30 55 6 72 -16 11 -22 26 -23 56 0 34 5 45 38 75 28 25 40 45
                44 72 6 47 14 54 55 50 24 -2 35 -9 37 -22 3 -11 0 -16 -7 -12 -6 4 -11 1 -11
                -6 0 -7 -16 -33 -35 -57 -22 -28 -33 -51 -30 -60 4 -9 9 -31 12 -49 3 -19 13
                -36 22 -40 9 -3 19 -10 23 -16 4 -7 19 -4 43 7 37 18 72 21 99 11 20 -8 21
                -24 2 -24 -8 0 -16 -7 -20 -15 -4 -11 -11 -12 -28 -6 -25 9 -78 -5 -78 -21 0
                -5 8 -23 17 -39 14 -23 15 -31 4 -41 -10 -11 -15 -10 -26 5 -12 17 -14 17 -30
                1 -12 -13 -16 -28 -12 -55 4 -31 2 -39 -14 -44 -10 -4 -19 -11 -19 -16 0 -5
                -7 -9 -15 -9 -8 0 -15 5 -15 10 0 17 -29 11 -72 -16 -32 -21 -42 -23 -49 -12
                -5 7 -9 -54 -9 -144 0 -134 3 -161 17 -178 12 -14 19 -16 24 -8 5 8 12 3 20
                -15 7 -15 20 -29 30 -32 9 -3 35 -19 58 -35 32 -24 41 -37 41 -60 0 -19 11
                -42 31 -65 17 -19 28 -35 25 -35 -4 0 1 -11 10 -25 15 -23 53 -38 39 -15 -3 5
                3 17 14 26 20 17 20 18 -5 47 -29 35 -31 47 -4 40 14 -4 20 -1 18 8 -2 9 7 14
                26 14 24 0 31 -5 37 -27 5 -19 3 -28 -4 -29 -7 0 -3 -4 8 -9 17 -7 17 -9 3 -9
                -23 -1 -24 -21 -1 -21 9 0 14 -3 11 -6 -11 -10 31 -44 47 -38 8 4 15 1 15 -5
                0 -6 9 -11 20 -11 11 0 20 5 20 10 0 15 31 12 50 -5 14 -13 19 -13 44 0 16 8
                34 15 41 15 20 0 24 -60 6 -109 -26 -72 -27 -73 -81 -66 -27 4 -56 3 -65 -2
                -18 -10 -135 6 -135 19 0 4 -11 8 -24 8 -14 0 -28 5 -31 10 -7 11 -45 13 -69
                4 -10 -4 -16 -18 -16 -39 0 -40 -18 -49 -51 -24 -13 10 -35 19 -47 19 -15 0
                -26 7 -29 19 -5 20 -30 31 -71 31 l-22 0 2 -562 c3 -502 5 -567 20 -598 9 -19
                19 -51 22 -70 2 -19 7 -46 11 -60 5 -19 0 -33 -18 -54 -23 -27 -37 -72 -37
                -124 0 -13 16 -57 35 -98 19 -40 35 -84 35 -96 0 -38 19 -100 52 -166 17 -34
                32 -78 32 -97 1 -19 11 -46 21 -61 19 -25 22 -26 60 -15 22 6 59 11 83 11 76
                0 113 25 218 147 23 28 38 56 41 82 4 32 12 42 42 58 l36 19 -2 70 -1 69 41
                30 c23 17 56 38 73 47 17 9 36 23 43 32 17 21 18 151 0 172 -7 8 -14 32 -15
                52 -1 20 -5 44 -9 53 -15 38 78 154 219 272 54 46 139 200 129 235 -6 25 -8
                25 -31 13 -9 -5 -35 -12 -57 -15 -62 -10 -69 -12 -89 -21 -14 -6 -24 -3 -38
                14 -10 13 -15 27 -12 32 7 11 -66 84 -92 93 -13 4 -25 21 -33 46 -6 22 -20 44
                -31 50 -12 6 -23 30 -30 66 -9 41 -17 58 -31 61 -10 3 -19 14 -19 24 0 11 -11
                36 -24 57 -29 44 -41 82 -27 82 5 0 12 8 14 18 4 16 5 16 6 0 0 -10 5 -18 10
                -18 6 0 26 -27 46 -59 21 -33 43 -62 51 -65 7 -3 17 -24 20 -46 5 -29 15 -48
                35 -63 16 -11 29 -26 29 -33 0 -7 12 -27 27 -44 14 -18 27 -39 28 -49 6 -52
                19 -93 32 -98 9 -3 28 1 43 10 15 9 30 14 33 11 3 -3 29 6 57 20 28 14 58 26
                66 26 8 0 25 11 37 24 12 13 37 27 55 30 18 4 32 14 32 22 0 8 6 14 14 14 8 0
                16 7 20 15 3 8 12 15 21 15 9 0 15 9 15 25 0 14 4 25 10 25 5 0 17 11 26 25
                16 25 16 27 -6 50 -13 14 -34 25 -46 25 -17 0 -26 9 -35 35 l-13 35 -30 -33
                c-43 -46 -85 -44 -102 4 -7 20 -13 26 -13 15 -1 -15 -4 -16 -16 -6 -8 7 -15
                19 -15 26 0 8 -9 23 -19 33 -30 30 -40 55 -30 73 13 24 43 11 64 -28 10 -19
                25 -34 33 -34 9 0 23 -6 31 -14 20 -18 49 -22 80 -12 19 7 28 4 41 -13 20 -27
                59 -35 175 -35 l89 1 40 -39 c22 -21 49 -38 61 -38 18 -1 18 -2 4 -13 -16 -12
                -15 -15 7 -34 28 -25 52 -21 64 11 7 18 9 15 15 -19 15 -89 55 -196 112 -300
                6 -11 17 -35 23 -52 7 -18 19 -33 26 -33 14 0 60 40 47 40 -5 0 -1 6 9 13 9 7
                16 24 17 37 0 14 0 51 0 82 1 46 4 58 19 62 25 8 142 126 153 154 8 20 26 28
                53 23 5 -1 22 3 38 10 22 9 26 14 16 20 -10 7 -9 9 3 9 9 0 22 -17 32 -41 10
                -25 24 -43 37 -46 11 -3 19 -11 17 -17 -2 -6 3 -16 11 -22 8 -6 16 -27 16 -47
                1 -20 4 -39 8 -42 8 -8 58 16 58 29 0 24 28 -24 50 -83 16 -47 21 -78 17 -115
                -4 -46 -1 -55 29 -95 19 -24 34 -52 34 -63 0 -26 39 -77 73 -95 37 -19 42 -12
                22 27 -9 17 -13 40 -10 50 5 15 -4 27 -39 52 -25 17 -46 36 -46 41 0 5 -10 22
                -21 39 -20 28 -21 33 -9 84 12 50 30 73 30 39 0 -8 9 -15 19 -15 11 0 32 -16
                46 -35 15 -19 32 -33 38 -32 6 1 13 -11 16 -27 l6 -30 27 32 c15 18 33 32 41
                32 24 0 47 35 47 74 0 39 -34 116 -51 116 -15 0 -69 62 -69 80 1 50 110 104
                110 54 0 -8 5 -14 10 -14 6 0 10 7 10 15 0 8 12 18 28 21 15 4 33 12 41 17 7
                6 18 12 25 13 6 2 21 5 34 8 27 6 92 65 92 84 0 7 4 11 9 8 5 -4 8 5 6 19 -1
                14 2 28 6 31 15 9 48 89 38 92 -5 2 -7 8 -3 13 3 5 -2 12 -11 15 -12 5 -13 9
                -3 21 9 11 9 16 -1 22 -6 4 -11 12 -10 17 4 14 -41 84 -62 95 -11 5 -19 18
                -19 28 0 19 52 71 71 71 5 0 9 4 9 9 0 16 -64 20 -81 5 -19 -17 -29 -18 -29
                -1 0 7 -10 17 -22 22 -30 14 -35 35 -9 42 12 3 21 12 21 19 0 8 6 17 14 20 8
                3 16 15 19 26 8 27 32 17 32 -13 0 -13 4 -30 8 -36 5 -8 3 -13 -4 -13 -7 0 -9
                -3 -5 -7 4 -5 13 -1 19 8 17 23 53 40 71 33 9 -3 16 -21 18 -42 3 -32 6 -37
                28 -37 19 0 25 -5 25 -22 0 -11 3 -25 8 -29 4 -4 7 -19 7 -34 0 -33 8 -41 29
                -30 9 5 24 11 34 14 34 11 11 89 -43 142 -35 35 -37 45 -9 70 14 13 19 26 15
                44 -7 37 41 93 66 77 35 -23 61 26 112 210 14 51 11 85 -13 146 -6 16 -11 41
                -11 56 0 70 -60 144 -98 122 -9 -6 -12 -11 -7 -11 6 0 3 -5 -5 -10 -19 -12
                -33 -13 -25 -1 3 5 1 12 -5 16 -5 3 -10 0 -11 -7 0 -7 -5 -3 -11 10 -7 14 -17
                21 -26 17 -27 -10 -31 6 -13 48 27 61 42 106 52 157 15 75 18 79 57 86 20 4
                42 11 48 16 7 5 16 8 20 7 25 -4 44 3 44 17 0 12 9 14 38 12 29 -2 36 -7 34
                -20 -3 -21 11 -23 30 -5 7 7 22 16 32 20 17 7 18 9 3 20 -14 10 -16 23 -11 79
                6 57 10 67 31 77 14 6 28 9 33 6 5 -3 10 1 13 7 5 13 8 7 22 -38 8 -24 30 -11
                33 19 2 15 8 27 13 27 5 0 2 14 -7 31 -13 26 -14 33 -2 40 17 11 34 5 30 -10
                -1 -7 5 -33 13 -58 13 -37 13 -48 3 -61 -7 -8 -13 -27 -14 -41 -2 -112 -5
                -128 -24 -149 -12 -12 -16 -23 -10 -27 6 -4 7 -23 4 -46 -5 -32 -1 -52 26
                -107 19 -37 48 -87 67 -111 18 -24 33 -49 33 -56 0 -7 6 -18 14 -24 19 -16 30
                18 21 65 -4 26 -2 36 9 40 12 5 13 10 5 26 -14 25 -3 68 16 68 20 0 19 7 -5
                37 -24 31 -27 68 -5 59 22 -8 19 20 -4 36 -10 8 -21 24 -23 37 -1 14 -9 26
                -17 29 -9 3 -11 0 -6 -9 4 -8 0 -7 -10 3 -12 11 -18 30 -17 52 1 19 -3 38 -8
                41 -19 12 -11 45 10 45 11 0 19 5 18 11 -5 19 20 21 25 2 4 -15 5 -15 6 3 0
                11 10 29 20 38 18 17 22 17 43 2 25 -18 42 -21 34 -7 -8 12 12 105 28 134 28
                52 42 67 63 67 29 0 37 14 21 40 -12 19 -44 36 -60 32 -4 -1 -5 4 -2 11 5 14
                -72 61 -86 52 -5 -3 -11 -1 -15 5 -3 5 0 10 7 11 7 1 24 2 38 2 20 2 25 7 26
                32 2 43 -5 65 -22 65 -15 0 -35 39 -27 53 3 5 14 -1 24 -12 23 -24 39 -27 39
                -7 0 20 22 26 40 11 18 -16 84 -6 88 13 1 6 -5 12 -13 12 -8 0 -15 8 -15 19 0
                10 -7 24 -15 31 -12 10 -12 15 2 36 9 15 11 24 4 24 -6 0 -11 8 -11 19 0 25
                -62 38 -90 18 -20 -14 -20 -14 0 -35 22 -23 25 -32 10 -32 -5 0 -24 16 -41 35
                -27 29 -39 35 -73 35 -22 0 -81 7 -131 16 -81 15 -100 15 -170 2 l-79 -14 37
                -35 c53 -52 47 -82 -11 -54 -37 19 -61 45 -41 45 9 0 10 3 2 11 -16 16 -32 3
                -26 -20 4 -16 -3 -20 -39 -26 -57 -10 -79 -22 -72 -40 4 -9 0 -15 -10 -15 -9
                0 -16 4 -16 9 0 4 -11 6 -24 4 -19 -4 -25 1 -33 24 -20 56 -98 68 -156 23 -26
                -21 -57 -27 -57 -11 0 15 -32 22 -46 10 -11 -9 -14 -9 -14 0 0 7 -4 9 -10 6
                -5 -3 -10 -2 -10 4 0 6 -7 8 -15 5 -8 -4 -15 -2 -15 4 0 6 6 12 12 14 8 3 5 9
                -9 16 -16 9 -29 9 -50 1 -15 -6 -41 -12 -58 -13 -16 -1 -47 -3 -68 -4 -21 -1
                -36 -6 -32 -11 3 -6 -2 -14 -12 -19 -15 -9 -14 -13 16 -41 18 -17 32 -32 30
                -32 -2 -1 -13 -3 -24 -5 -73 -14 -85 -18 -85 -30 0 -17 -24 -18 -52 -3 -19 10
                -20 9 -13 -7 4 -11 10 -29 12 -41 7 -33 -22 -30 -57 6 -16 16 -30 25 -32 20
                -2 -4 -12 -1 -22 7 -17 12 -18 14 -3 14 24 0 22 6 -15 44 -37 37 -73 53 -83
                36 -5 -8 -11 -8 -20 0 -10 8 -19 8 -34 0 -18 -9 -19 -14 -9 -30 17 -26 7 -32
                -57 -37 -43 -3 -55 -1 -55 11 0 11 -9 13 -37 9 -21 -3 -43 -10 -50 -15 -7 -5
                -17 -7 -24 -3 -6 4 -15 1 -20 -6 -6 -11 -13 -11 -26 -3 -19 12 -43 8 -43 -7 0
                -4 7 -6 17 -2 12 5 10 -1 -7 -25 -13 -18 -30 -32 -38 -32 -8 0 -12 -6 -9 -15
                4 -8 2 -17 -3 -20 -15 -9 -12 27 6 76 9 24 17 49 18 54 2 6 8 29 14 51 12 42
                8 74 -10 74 -5 0 -7 4 -4 9 3 5 -1 14 -9 21 -8 7 -15 9 -15 5 0 -5 -8 -2 -17
                6 -41 33 -57 36 -84 20 -14 -9 -24 -21 -22 -27 2 -6 -3 -14 -12 -17 -11 -5
                -15 1 -15 23 0 33 -5 35 -45 20 -24 -9 -26 -8 -20 11 5 14 2 25 -6 30 -24 15
                -39 10 -50 -17z m1141 -108 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
                10 11 6 0 10 -2 10 -4z m22 -38 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9
                -15 0 -24z m-993 -30 c18 -11 18 -12 -5 -31 -13 -10 -24 -25 -24 -33 0 -8 -4
                -12 -10 -9 -14 9 -12 -8 3 -25 6 -7 0 -5 -15 6 -30 21 -39 58 -13 47 23 -8 58
                26 44 44 -14 16 -5 17 20 1z m861 -58 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10
                -3 6 1 10 9 10 9 0 16 -4 16 -10z m-1164 -387 c-10 -10 -19 5 -10 18 6 11 8
                11 12 0 2 -7 1 -15 -2 -18z m44 -23 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2
                7 4 15 4 8 0 15 -4 15 -10z m-1258 -192 c-7 -7 -12 -8 -12 -2 0 14 12 26 19
                19 2 -3 -1 -11 -7 -17z m128 -58 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
                10 11 10 2 0 4 -4 4 -10z m1955 -70 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
                2 10 4 10 3 0 8 -4 11 -10z m-2055 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
                10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-15 -80 c3 -6 -1 -7 -9 -4 -18 7 -21 14
                -7 14 6 0 13 -4 16 -10z m237 -47 c22 -27 -4 -32 -29 -5 -20 22 -22 28 -9 30
                9 2 17 9 17 15 1 7 4 3 6 -8 3 -11 10 -25 15 -32z m-255 -5 c-3 -8 -6 -5 -6 6
                -1 11 2 17 5 13 3 -3 4 -12 1 -19z m78 -9 c-8 -12 -35 -11 -35 2 0 5 5 7 10 4
                6 -3 10 -1 10 5 0 6 5 8 10 5 6 -4 8 -11 5 -16z m81 -5 c29 -30 29 -31 4 -44
                -16 -9 -22 -6 -36 15 -15 23 -14 55 2 55 4 0 17 -11 30 -26z m611 -76 c-3 -8
                -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1215 4 c-10 -3 -10 -7 -1 -18
                8 -9 8 -14 1 -14 -16 0 -25 19 -18 36 3 9 11 12 18 8 9 -6 9 -9 0 -12z m204
                -29 c3 -16 7 -34 10 -41 5 -17 -10 -53 -33 -77 -10 -11 -22 -28 -26 -37 -3
                -10 -16 -18 -29 -18 l-22 0 22 20 c30 28 53 86 55 137 2 49 14 56 23 16z
                m-2126 -19 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m277 -10
                c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m1033 -74 c0 -5 -5 -10
                -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m640 -110 c0 -11 -2
                -20 -4 -20 -2 0 -6 9 -9 20 -3 11 -1 20 4 20 5 0 9 -9 9 -20z m480 -30 c0 -5
                -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-675 -9 c3
                -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-193 -111 c14 0 19 -3 12
                -10 -16 -16 -54 0 -54 24 1 19 2 19 11 4 5 -10 20 -18 31 -18z m1338 -15 c0
                -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m-2448 -40 c-2 -14
                -9 -25 -14 -25 -5 0 -7 3 -5 8 2 4 7 15 10 25 9 25 15 21 9 -8z m856 -40 c-12
                -27 -28 -34 -28 -12 0 14 20 37 32 37 4 0 2 -11 -4 -25z m-586 -26 c4 -25 1
                -29 -19 -29 -13 0 -23 -6 -23 -14 0 -8 -5 -16 -12 -18 -6 -2 5 -20 25 -41 21
                -20 37 -43 37 -51 0 -8 5 -18 10 -21 6 -4 10 -12 9 -18 -3 -27 2 -37 18 -37 9
                0 13 -3 10 -7 -4 -3 -3 -11 2 -17 4 -6 11 -28 14 -48 l6 -38 -43 0 c-24 0 -58
                7 -76 16 -31 16 -32 19 -26 58 4 22 11 42 15 44 4 1 -9 22 -31 45 -21 23 -41
                51 -44 62 -4 11 -13 28 -21 37 -19 21 -9 38 42 77 24 18 51 29 71 29 28 0 33
                -4 36 -29z m-392 8 c-8 -7 -15 -18 -15 -26 0 -17 -56 -34 -69 -21 -14 14 1 40
                23 40 11 0 23 5 26 10 3 6 16 10 28 10 19 -1 20 -2 7 -13z m-170 -17 c0 -5 9
                -10 20 -10 21 0 29 -20 8 -20 -7 0 -5 -5 5 -11 9 -6 15 -14 12 -20 -9 -13 7
                -11 49 6 36 15 37 15 105 -29 73 -48 89 -69 69 -93 -9 -11 -27 -14 -71 -11
                -32 2 -62 9 -65 14 -12 17 -91 17 -117 0 -38 -25 -82 -21 -102 10 -13 21 -14
                30 -5 45 7 10 12 28 12 39 0 11 5 20 10 20 6 0 10 5 10 10 0 19 32 60 46 60 8
                0 14 -4 14 -10z m780 -19 c0 -16 -6 -31 -12 -34 -18 -5 -48 4 -48 15 0 22 22
                48 40 48 15 0 20 -7 20 -29z m-845 -191 c-3 -5 -15 -10 -26 -10 -10 0 -19 5
                -19 10 0 6 12 10 26 10 14 0 23 -4 19 -10z m2595 -250 c0 -5 -2 -10 -4 -10 -3
                0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-100 -81 c0 -6 -4 -7 -10
                -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-105 -17 c-3 -3 -11
                0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-2241 -38 c3 -8 2 -12 -4 -9 -6
                3 -10 10 -10 16 0 14 7 11 14 -7z m53 -859 c3 -9 -2 -24 -11 -34 -14 -15 -14
                -20 -4 -24 7 -3 -2 -6 -20 -6 -33 -1 -33 0 -27 32 4 17 9 35 12 40 8 13 43 7
                50 -8z m-127 -135 c0 -16 9 -45 21 -64 12 -20 16 -36 10 -36 -6 0 -11 5 -11
                12 0 6 -6 19 -14 27 -18 20 -33 107 -17 97 6 -4 11 -20 11 -36z m140 -175 c1
                -11 2 -35 1 -54 -2 -54 -14 -27 -15 34 -1 50 10 65 14 20z"
        />
        <path d="M2556 5115 c4 -8 10 -12 15 -9 11 6 2 24 -11 24 -5 0 -7 -7 -4 -15z" />
        <path
          d="M6557 5109 c-9 -6 -15 -14 -12 -18 2 -5 -5 -12 -18 -15 -21 -7 -21
                -7 -2 -16 18 -8 17 -9 -7 -9 -15 -1 -29 -5 -33 -10 -7 -13 33 -34 51 -27 8 3
                14 1 14 -4 0 -5 12 -11 28 -13 19 -3 14 -4 -15 -6 -31 -1 -43 -5 -43 -16 0 -8
                3 -16 8 -16 36 -6 59 -5 70 3 7 6 32 8 59 4 42 -5 50 -3 75 21 28 27 37 53 18
                53 -5 0 -10 -4 -10 -10 0 -5 -4 -10 -9 -10 -14 0 -18 31 -10 63 8 30 6 32 -22
                16 -14 -7 -18 -16 -14 -30 4 -11 2 -19 -4 -19 -6 0 -11 -7 -11 -15 0 -16 -25
                -21 -34 -6 -4 5 -7 21 -8 36 -2 15 -8 30 -15 32 -7 3 -10 9 -7 14 7 12 -27 11
                -49 -2z"
        />
        <path
          d="M2599 5108 c-9 -32 -5 -38 27 -38 36 0 48 22 18 34 -16 6 -43 8 -45
                4z"
        />
        <path
          d="M6484 5096 c-10 -8 -17 -17 -14 -19 3 -3 16 3 30 13 29 22 14 27 -16
                6z"
        />
        <path
          d="M7022 5088 c3 -7 17 -15 33 -16 25 -3 27 -2 16 12 -16 19 -55 22 -49
                4z"
        />
        <path
          d="M60 5058 c0 -14 -4 -16 -24 -8 -28 10 -36 5 -36 -27 0 -20 4 -23 28
-17 19 4 23 3 13 -4 -18 -13 -4 -35 17 -27 8 3 19 1 22 -6 10 -15 45 8 60 39
13 30 4 41 -35 44 -17 1 -33 7 -37 13 -5 6 -8 3 -8 -7z"
        />
        <path
          d="M6810 5060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path d="M7110 5044 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
        <path
          d="M6880 5020 c0 -38 -20 -40 -21 -2 l-1 27 -13 -30 c-13 -27 -20 -21
-16 13 1 6 -5 12 -14 12 -8 0 -15 -7 -15 -15 0 -8 5 -15 11 -15 6 0 9 -7 5
-15 -3 -10 1 -15 14 -15 10 0 22 -4 25 -10 3 -5 -1 -10 -9 -10 -21 0 -21 -26
0 -34 25 -10 34 -7 34 9 0 8 3 15 8 15 13 0 54 69 47 80 -3 6 -11 8 -16 5 -5
-4 -9 -1 -9 4 0 6 -7 11 -15 11 -10 0 -15 -10 -15 -30z"
        />
        <path
          d="M6977 5044 c-3 -4 -2 -14 3 -24 5 -10 7 -20 4 -22 -3 -3 6 -8 20 -12
30 -7 30 -37 2 -80 -23 -33 -12 -62 23 -60 11 0 21 -5 21 -13 0 -12 15 -15 93
-17 12 -1 25 -6 30 -13 4 -7 17 -9 34 -4 34 9 53 56 30 78 -15 16 -70 20 -106
8 -12 -4 -21 -2 -21 4 0 6 -7 11 -16 11 -9 0 -18 9 -21 20 -3 11 -9 20 -14 20
-5 0 -9 7 -9 15 0 13 4 14 22 4 18 -10 20 -9 15 6 -4 9 -7 21 -7 26 0 5 -9 9
-20 9 -12 0 -25 10 -32 25 -10 23 -37 33 -51 19z"
        />
        <path
          d="M5445 5029 c-4 -6 -22 -8 -45 -4 -29 5 -39 3 -43 -9 -4 -10 -18 -16
-37 -16 -19 0 -51 -14 -83 -36 -41 -28 -59 -35 -84 -31 -45 8 -67 -18 -52 -62
6 -17 8 -43 5 -56 -5 -18 -2 -27 9 -31 8 -4 15 -15 15 -25 0 -10 7 -19 15 -19
21 0 19 -20 -2 -21 -10 0 -27 -2 -38 -4 -34 -7 -57 5 -52 26 6 22 -10 24 -63
9 -53 -15 -67 -27 -52 -42 14 -14 16 -32 3 -24 -13 9 -22 -36 -9 -51 6 -7 18
-11 27 -8 9 2 22 1 30 -4 9 -6 24 -5 39 0 25 10 44 3 27 -9 -6 -4 -12 -16 -13
-27 -6 -31 -22 -45 -51 -45 -14 0 -33 -4 -41 -10 -9 -6 -17 -5 -21 2 -10 16
-40 1 -33 -17 4 -9 1 -15 -9 -15 -19 0 -77 -50 -77 -67 0 -6 10 -19 23 -28 20
-15 20 -16 2 -13 -16 2 -21 -3 -23 -29 -3 -27 0 -33 15 -33 11 0 24 5 30 11 8
8 9 0 2 -31 -6 -24 -12 -47 -15 -52 -3 -4 2 -5 11 -2 9 4 23 0 33 -9 9 -8 30
-13 47 -11 25 2 27 1 15 -9 -8 -7 -21 -24 -29 -38 -7 -15 -40 -42 -72 -60 -32
-19 -59 -39 -59 -46 0 -7 -7 -10 -15 -6 -9 3 -35 -4 -58 -15 -23 -12 -51 -24
-61 -27 -10 -3 -17 -7 -14 -10 6 -6 80 16 96 29 8 5 26 10 40 11 34 1 72 14
72 25 0 4 17 15 38 23 21 8 44 22 51 31 8 9 17 16 22 16 5 0 27 12 48 26 29
18 37 30 34 45 -3 10 0 20 6 20 6 1 16 2 22 3 6 0 16 8 22 16 18 22 94 70 101
63 14 -14 5 -34 -12 -29 -19 6 -58 -26 -64 -54 -2 -8 -7 -18 -13 -22 -16 -12
2 -20 22 -9 17 9 37 16 58 20 19 4 44 33 39 46 -3 7 -1 16 5 20 6 3 11 1 11
-4 0 -6 6 -11 14 -11 8 0 18 -9 21 -20 3 -11 13 -20 21 -20 8 0 17 -7 20 -15
4 -8 14 -15 24 -15 9 0 25 -9 35 -20 10 -12 25 -18 32 -16 8 3 13 0 12 -7 -1
-6 3 -25 9 -40 7 -16 12 -32 12 -37 0 -4 7 -11 16 -14 12 -4 14 -1 9 14 -9 30
4 24 17 -7 l12 -28 4 33 c2 17 7 32 12 32 4 0 6 -9 3 -20 -3 -11 -2 -28 2 -37
14 -31 16 -125 3 -117 -8 5 -9 1 -5 -10 4 -10 7 -24 7 -32 0 -9 5 -12 12 -8 8
5 9 2 5 -10 -6 -14 -3 -17 9 -12 14 6 15 4 4 -9 -7 -9 -15 -29 -17 -45 -2 -23
1 -28 12 -24 18 7 20 -7 3 -25 -11 -10 -10 -12 1 -9 9 2 14 -8 15 -35 0 -20
-3 -38 -7 -40 -11 -3 24 -42 46 -50 10 -4 16 -11 13 -16 -4 -5 0 -16 8 -24 18
-18 30 -62 21 -77 -4 -7 -13 -5 -28 5 -33 23 -37 19 -37 -37 0 -34 -10 -74
-26 -113 -46 -105 -55 -200 -28 -290 8 -27 20 -66 26 -85 7 -25 21 -42 45 -55
32 -18 38 -28 53 -87 9 -36 27 -78 39 -93 25 -32 27 -45 6 -45 -22 0 -8 -23
25 -42 19 -11 30 -25 30 -39 0 -25 56 -93 65 -79 3 5 -3 18 -15 30 -11 11 -20
22 -20 25 1 20 -18 70 -46 123 -33 62 -44 112 -25 112 16 0 41 -30 41 -50 0
-21 31 -80 43 -80 4 0 7 -7 7 -15 0 -7 7 -18 17 -23 9 -6 14 -16 10 -25 -4
-10 1 -18 14 -22 10 -4 19 -11 19 -16 0 -5 10 -20 21 -32 34 -35 51 -84 39
-107 -9 -17 -5 -26 27 -55 21 -19 44 -35 52 -35 8 0 22 -6 30 -13 9 -8 43 -24
77 -36 47 -17 66 -20 87 -12 24 9 32 7 66 -25 36 -32 134 -69 153 -58 3 2 9
-4 13 -15 3 -10 14 -26 25 -36 10 -9 16 -23 13 -31 -5 -12 7 -19 29 -15 4 0
15 -11 24 -25 9 -14 23 -23 30 -20 7 3 23 -3 36 -13 23 -18 53 -18 42 0 -5 9
18 39 30 39 12 0 46 -96 46 -130 0 -31 -8 -46 -39 -79 -38 -40 -61 -86 -61
-124 0 -13 5 -17 15 -13 23 8 18 -8 -10 -34 -17 -16 -22 -28 -16 -39 5 -9 7
-21 4 -27 -2 -6 7 -15 20 -21 13 -6 39 -41 61 -84 21 -41 47 -91 58 -111 20
-39 68 -80 105 -92 12 -4 36 -23 52 -42 34 -38 35 -43 30 -214 -2 -58 -2 -145
-1 -195 2 -91 -14 -205 -30 -216 -5 -3 -2 -18 5 -32 10 -19 13 -51 10 -107 -3
-52 0 -80 6 -80 6 0 11 14 11 30 0 17 5 30 10 30 6 0 10 -33 10 -80 0 -72 -2
-80 -19 -80 -10 0 -22 -7 -25 -16 -8 -21 -9 -21 16 -20 17 1 23 -5 24 -21 1
-16 -4 -23 -16 -23 -13 0 -16 -6 -12 -25 2 -14 8 -22 13 -19 5 3 6 -2 3 -10
-3 -9 -1 -16 6 -16 6 0 8 -5 5 -10 -4 -6 -1 -9 6 -8 7 2 21 -14 31 -35 16 -32
21 -37 33 -27 11 9 15 9 15 1 0 -10 -13 -15 -32 -12 -18 3 -6 -27 15 -38 21
-11 20 -11 -8 -6 -27 5 -28 4 -10 -7 11 -6 26 -18 33 -25 18 -17 66 -18 56 0
-6 9 -5 9 8 0 8 -7 19 -9 23 -5 5 4 5 1 1 -5 -4 -7 -14 -13 -22 -13 -12 0 -12
-3 -2 -14 8 -9 17 -11 22 -5 16 15 26 10 19 -8 -5 -14 -1 -15 27 -9 19 3 36
10 40 14 3 5 14 12 25 16 18 8 18 9 -9 18 -35 13 -66 38 -66 55 0 6 -8 17 -17
22 -11 6 -13 11 -5 11 10 0 10 5 -2 22 -20 28 -20 52 -1 68 8 7 15 20 15 30 0
10 7 23 15 30 24 20 19 57 -9 64 -13 3 -27 13 -30 22 -7 19 10 44 30 44 9 0
14 12 14 34 0 19 5 38 10 41 6 4 8 11 5 16 -3 5 1 6 9 3 11 -4 16 0 16 11 0
12 -5 15 -20 10 -11 -3 -20 -2 -20 4 0 5 -3 17 -7 26 -5 15 -1 16 27 10 38 -8
48 2 45 49 -3 30 -1 32 22 28 37 -7 100 26 108 56 4 15 1 28 -5 32 -6 4 -10
14 -8 22 2 9 -6 24 -17 34 -12 11 -16 16 -10 12 6 -4 29 -10 52 -14 34 -5 44
-3 62 17 20 21 25 33 19 43 -2 3 13 22 32 42 19 20 29 33 21 29 -9 -5 -12 -3
-10 4 3 7 8 11 12 9 15 -6 58 85 52 110 -3 14 0 34 9 47 24 36 107 88 140 87
25 -1 61 21 61 37 0 3 5 20 11 36 28 76 34 106 34 168 0 43 4 66 11 64 6 -1
16 7 22 18 7 11 27 37 45 58 48 56 43 128 -11 147 -17 6 -41 20 -53 31 -27 25
-148 51 -164 35 -7 -7 -10 -5 -10 7 0 10 -15 23 -36 32 -44 18 -70 19 -78 3
-3 -7 -4 -4 -3 7 1 15 -4 20 -23 20 -14 0 -31 -4 -38 -9 -23 -17 -26 4 -3 28
l23 24 -28 54 c-32 58 -75 87 -132 88 -39 0 -60 10 -99 47 -43 40 -75 63 -90
63 -17 0 -17 17 0 23 6 3 2 6 -10 6 -12 1 -29 4 -38 7 -10 4 -15 2 -13 -7 2
-11 -9 -13 -47 -10 -27 1 -52 6 -55 10 -3 3 -17 14 -31 23 -24 15 -27 15 -42
-3 -18 -20 -37 -25 -37 -10 0 6 5 13 10 16 6 4 8 11 4 16 -4 6 -13 4 -25 -7
-10 -9 -33 -19 -50 -23 -18 -3 -37 -15 -43 -26 -6 -10 -17 -25 -23 -33 -7 -7
-13 -18 -13 -24 0 -7 -10 -2 -23 10 -25 24 -66 29 -84 11 -8 -8 -19 -6 -42 5
-46 24 -54 47 -40 112 11 50 11 56 -7 69 -12 10 -38 14 -74 13 l-55 -2 3 30
c2 16 10 36 18 45 8 8 14 23 14 33 0 9 7 28 16 41 16 24 16 25 -9 25 -59 0
-86 -12 -92 -40 -8 -38 -27 -52 -76 -55 -50 -4 -73 8 -101 54 -23 37 -28 135
-8 155 5 5 10 25 10 44 0 43 16 72 40 72 10 0 25 8 32 18 14 19 81 23 108 7
26 -16 57 -12 53 6 -3 10 2 19 14 23 21 6 78 0 96 -11 6 -4 20 -6 30 -4 22 3
49 -28 42 -47 -7 -20 32 -102 48 -102 23 0 27 40 12 107 -17 71 -9 105 27 124
21 11 97 69 125 96 7 6 14 33 14 60 1 26 3 40 6 30 5 -18 6 -18 19 2 9 12 12
26 9 31 -3 6 1 10 9 10 14 0 33 35 28 53 -1 4 11 7 28 7 16 0 30 4 30 9 0 5
14 12 30 16 25 5 29 10 20 20 -17 20 2 48 47 69 21 10 51 25 66 32 41 20 68
19 39 -2 -23 -16 -30 -44 -10 -44 6 0 20 8 30 17 10 9 28 18 40 20 16 3 80 28
96 38 1 1 -1 12 -6 24 l-8 22 -14 -25 c-15 -29 -27 -31 -65 -14 -23 10 -29 22
-28 56 1 6 -7 12 -16 13 -9 0 -4 5 12 11 39 14 35 38 -7 38 -43 0 -84 -15
-116 -43 -14 -12 -9 -5 9 16 19 20 40 37 46 37 7 0 20 9 31 21 17 19 27 21
107 16 78 -4 91 -3 108 14 10 10 26 19 34 19 8 0 26 5 40 12 22 10 26 17 23
47 -2 22 -8 35 -15 33 -7 -1 -13 2 -13 8 0 5 -8 10 -17 10 -13 0 -14 3 -6 11
9 9 8 15 -2 25 -7 8 -17 14 -22 15 -4 0 -19 13 -31 30 -18 22 -21 33 -13 43
12 15 -6 76 -24 76 -5 0 -4 4 3 8 10 7 10 16 -1 47 l-14 40 -24 -37 c-20 -31
-55 -58 -74 -58 -2 0 -10 10 -16 23 -15 27 -13 85 4 106 8 10 8 12 1 8 -13 -8
-60 36 -69 63 -3 10 -15 18 -28 18 -12 0 -39 3 -60 7 -37 8 -39 7 -36 -16 2
-13 -1 -31 -6 -39 -5 -8 -8 -28 -6 -43 2 -21 -5 -35 -24 -51 -26 -23 -27 -24
-11 -49 25 -38 18 -94 -15 -118 -15 -10 -37 -23 -50 -27 -15 -6 -24 -18 -24
-33 -1 -13 -2 -41 -3 -61 -2 -34 -23 -68 -41 -68 -4 0 -16 17 -25 38 -14 31
-15 46 -7 82 14 62 15 60 -24 60 -19 0 -35 5 -35 10 0 6 -11 15 -24 21 -13 6
-29 21 -35 33 -14 26 -64 50 -88 42 -16 -5 -18 0 -15 39 3 36 1 45 -12 45 -39
0 -5 80 63 148 32 32 49 57 45 65 -5 9 -4 9 7 0 23 -22 42 -16 35 11 -5 20 -3
23 14 19 12 -3 20 0 20 7 0 8 8 10 22 7 18 -5 33 5 74 50 35 37 50 61 46 72
-5 14 -2 14 21 -2 30 -20 60 -16 95 13 19 16 22 26 18 55 -5 30 -3 35 13 35
10 0 21 9 24 20 3 11 1 20 -4 20 -5 0 -9 6 -9 13 0 14 -46 44 -55 36 -2 -3 -6
-14 -8 -25 -3 -22 -55 -80 -68 -77 -5 2 -8 0 -8 -5 3 -17 -2 -32 -10 -32 -17
0 -23 42 -11 66 7 12 10 31 6 41 -7 16 -9 16 -26 -7 l-20 -25 0 27 c0 14 6 28
13 31 6 3 -1 10 -18 17 -17 7 -24 14 -17 17 6 3 12 11 12 19 0 9 4 13 9 10 5
-3 9 21 10 54 0 32 -3 62 -8 65 -13 7 -42 -5 -35 -16 3 -5 -2 -9 -10 -9 -19 0
-61 -84 -51 -100 4 -7 21 -21 39 -32 22 -14 25 -18 9 -13 -23 6 -28 -1 -24
-33 1 -6 -14 -22 -34 -33 -21 -13 -35 -29 -35 -40 0 -16 -2 -18 -14 -7 -11 11
-11 16 0 32 11 16 10 21 -2 33 -18 18 -44 10 -44 -13 0 -12 -6 -15 -22 -10
-13 3 -32 6 -44 6 -11 0 -37 15 -56 34 -34 33 -38 34 -72 24 -32 -10 -36 -15
-36 -45 0 -23 -5 -33 -15 -33 -10 0 -15 10 -15 28 0 41 -14 56 -44 48 -15 -4
-41 0 -62 9 -31 13 -35 17 -24 30 7 9 19 14 27 11 9 -4 13 3 13 19 0 23 -10
30 -33 26 -13 -2 -71 50 -79 71 -9 22 -40 36 -46 20 -2 -7 -10 -12 -19 -12
-10 0 -12 6 -8 20 5 15 3 18 -9 14 -9 -3 -16 -10 -16 -15 0 -5 -4 -9 -10 -9
-5 0 -10 21 -10 48 -1 37 -3 43 -11 30 -5 -10 -17 -18 -26 -18 -21 0 -80 -18
-115 -36 -16 -7 -28 -10 -28 -5 0 11 37 28 88 41 23 6 42 13 42 17 0 8 -94 -7
-104 -18 -15 -14 -24 -11 -28 8 -3 15 -4 15 -20 -1 -10 -10 -18 -14 -18 -10 0
4 -6 0 -13 -10 -13 -18 -15 -17 -35 9 -12 15 -22 34 -22 41 0 7 -13 21 -30 31
-16 9 -30 23 -30 30 0 7 -11 13 -23 13 -14 0 -42 17 -68 41 -27 25 -50 38 -61
36 -17 -5 -19 -1 -22 43 -1 8 -11 16 -24 18 -14 2 -20 8 -15 15 5 9 3 9 -8 0
-8 -7 -20 -8 -28 -4 -9 6 -7 10 9 14 12 3 19 10 15 16 -8 14 -22 14 -30 0z
m395 -328 c0 -11 -4 -22 -9 -25 -5 -3 -8 6 -8 19 0 14 3 25 8 25 5 0 9 -9 9
-19z m280 -211 c0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -9 0 -13 27 -21 45 -15
8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -8 -4 -12 -11 -9 -16 4 -7 -5 -8 -26 -3
-28 6 -32 5 -27 -9 4 -11 1 -16 -11 -16 -10 0 -23 -5 -30 -12 -8 -8 -12 -8
-12 -1 0 6 7 13 15 17 8 3 15 10 15 16 0 7 -13 4 -30 -7 -20 -12 -33 -14 -40
-7 -7 7 -2 15 15 24 14 7 25 17 25 22 0 4 8 8 18 8 16 1 16 1 -1 11 -11 6 -30
9 -43 6 -13 -2 -24 0 -24 4 0 5 8 9 18 10 28 1 76 10 81 15 9 8 61 4 61 -6z
m122 -82 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-160
-40 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m205 -30 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m293 -4 c0 -2 -16 -4 -35 -4
-19 0 -35 4 -35 8 0 4 16 6 35 4 19 -2 35 -6 35 -8z m-520 -65 c0 -6 -4 -7
-10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m259 -13 c0 -7
-2 -6 -6 2 -2 6 -11 12 -19 12 -9 0 -12 5 -9 10 8 13 35 -6 34 -24z m487 -4
c-9 -8 -26 10 -26 26 1 15 2 15 15 -2 8 -11 13 -21 11 -24z m-669 -72 c7 0 28
9 48 20 27 15 35 16 35 6 0 -12 -83 -64 -107 -67 -5 -1 -18 -5 -31 -10 -32
-12 -41 -11 -58 6 -14 13 -13 15 6 15 11 0 23 6 26 14 3 8 12 12 20 9 17 -6
18 6 4 44 l-11 28 28 -32 c16 -18 34 -33 40 -33z m493 40 c13 -8 13 -10 -2
-10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m-174 -46 c-5 -14 -9 -14 -22 -3 -14
12 -14 14 2 26 21 15 30 3 20 -23z m74 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 10
-10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-100 2 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-90 -70 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m91 -59 c-10 -9 -11 -8 -5 6 3
10 9 15 12 12 3 -3 0 -11 -7 -18z m-247 7 c26 -10 18 -24 -13 -24 -17 0 -33
-5 -36 -10 -4 -6 -11 -7 -17 -4 -7 5 24 33 49 43 1 1 8 -2 17 -5z m136 -72 c0
-11 -22 -32 -32 -32 -5 0 -4 9 2 20 11 20 30 28 30 12z m-105 -42 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m111 -6 c-3 -9
-6 -20 -6 -25 0 -5 -7 -9 -15 -9 -18 0 -20 21 -3 38 17 17 31 15 24 -4z m64
-44 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-100 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-70 -56 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m102 -85 c3 -14 1 -25 -4 -25 -5 0 -7 -4 -4 -9 3 -4 -2 -17 -10
-27 -15 -19 -16 -19 -9 0 3 11 0 23 -7 28 -8 5 -9 8 -2 8 7 0 9 11 6 25 -3 18
0 25 11 25 8 0 17 -11 19 -25z m55 -80 c2 -42 0 -65 -8 -65 -5 0 -8 8 -5 18 4
10 -1 27 -9 38 -15 20 -20 75 -8 88 15 14 26 -16 30 -79z m749 53 c5 -7 3 -8
-6 -3 -9 6 -10 2 -5 -16 6 -19 5 -22 -5 -12 -7 6 -10 19 -7 27 8 19 13 20 23
4z m-631 -18 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4
16 -10z m775 -19 c0 -5 -11 -8 -25 -8 -14 0 -28 3 -31 8 -3 5 9 9 25 9 17 0
31 -4 31 -9z m105 -21 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m-1045 -55 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5
15 10 15 6 0 10 -7 10 -15z m210 5 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19
12 20 12 20 0z m-46 -35 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10
15 5 0 7 -7 4 -15z m496 -5 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15 0 15
-2 2 -10z m-410 -22 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16
-11z m-166 -3 c3 -9 0 -15 -9 -15 -9 0 -12 6 -9 15 4 8 7 15 9 15 2 0 5 -7 9
-15z m190 -41 c9 -3 16 -12 16 -20 0 -8 7 -14 15 -14 10 0 15 -10 15 -30 0
-33 4 -32 -73 -15 -15 3 -25 10 -22 15 3 6 2 10 -4 10 -5 0 -12 -4 -15 -9 -8
-13 -56 -20 -56 -9 0 6 -8 7 -17 4 -38 -15 13 25 63 49 56 27 57 27 78 19z
m117 -112 c18 -2 43 -49 29 -57 -5 -3 -12 0 -15 5 -9 14 -34 -18 -35 -42 0
-22 -20 -24 -20 -2 0 10 -6 14 -15 10 -18 -6 -19 -1 -5 24 7 14 6 23 -5 33
-32 33 -85 -23 -85 -89 0 -13 -9 -28 -20 -34 -18 -10 -20 -8 -20 17 0 41 24
114 42 127 13 10 93 14 149 8z m118 -106 c-9 -8 -27 -10 -49 -6 l-35 7 28 11
c16 7 32 10 37 7 5 -3 12 0 16 7 6 9 9 9 12 -1 2 -7 -2 -18 -9 -25z m-79 -26
c0 -6 -21 -20 -46 -30 -43 -19 -64 -19 -64 -1 0 7 16 13 95 36 6 2 11 4 13 4
1 1 2 -4 2 -9z m-275 -1020 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z m395 -48 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9
-4 9 -8z m624 -418 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z
m-548 -351 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z m524 -457
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-386 -811 c-5 -21 -3 -24 12 -19 15 6 16 4 7 -6 -6 -7 -16 -9 -22 -6 -12 8
-15 39 -4 49 12 12 13 7 7 -18z m-34 -9 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z"
        />
        <path
          d="M9159 5008 c-1 -7 -1 -17 0 -22 1 -5 -5 -4 -13 3 -12 10 -17 9 -26
-6 -10 -16 -11 -16 -6 0 6 20 -23 24 -41 6 -13 -13 -6 -42 8 -33 5 3 9 2 8 -3
-4 -22 2 -43 17 -57 21 -22 30 -20 39 7 4 13 8 17 12 9 2 -6 9 -10 14 -6 21
12 7 -13 -16 -30 -14 -10 -25 -23 -25 -27 0 -5 11 -4 25 1 17 6 25 6 25 -1 0
-6 -9 -13 -20 -16 -26 -7 -25 -11 10 -53 l30 -34 0 121 c0 97 -3 125 -16 137
-19 19 -24 20 -25 4z m5 -54 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11
14 -7z"
        />
        <path
          d="M6323 5003 c-20 -3 -33 -10 -33 -18 0 -8 -27 -40 -59 -72 -48 -45
-58 -60 -50 -74 5 -10 6 -25 3 -33 -7 -18 21 -23 32 -5 5 8 9 8 14 -1 6 -9 14
-5 31 14 12 14 28 26 35 26 6 0 17 7 24 14 6 8 36 20 66 27 l55 12 -3 36 c-2
29 -8 37 -26 39 -12 2 -28 12 -35 23 -6 10 -14 18 -17 17 -3 0 -20 -3 -37 -5z"
        />
        <path
          d="M6745 4970 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M1 4888 l1 -73 12 48 c6 26 17 52 24 56 11 6 10 11 -3 25 -29 28 -35
18 -34 -56z"
        />
        <path
          d="M1977 4953 c-13 -12 -7 -23 13 -23 13 0 18 5 14 15 -7 16 -17 19 -27
8z"
        />
        <path
          d="M6917 4919 c-18 -20 -18 -21 0 -35 25 -18 28 -18 41 10 7 17 7 27 -1
35 -15 15 -20 14 -40 -10z"
        />
        <path
          d="M55 4883 c8 -16 13 -35 10 -43 -4 -12 2 -15 21 -15 29 2 57 35 29 35
-8 0 -15 7 -15 16 0 11 -5 14 -16 10 -12 -4 -15 -2 -10 9 3 10 -1 15 -14 15
-17 0 -18 -2 -5 -27z"
        />
        <path
          d="M975 4902 c-5 -4 -15 -16 -21 -27 -6 -11 -25 -25 -42 -30 -33 -11
-72 -46 -72 -65 0 -5 -11 -20 -25 -32 -13 -11 -21 -26 -17 -32 12 -20 11 -41
-3 -63 -10 -15 -11 -23 -3 -25 8 -3 8 -12 1 -35 -8 -23 -8 -34 0 -43 9 -9 8
-15 -7 -25 -26 -20 -14 -47 18 -39 19 4 26 1 31 -15 4 -13 18 -23 41 -27 45
-8 53 1 22 26 -36 28 -52 72 -44 123 4 23 9 55 12 71 2 16 11 37 19 46 8 9 15
21 15 27 0 6 4 13 8 15 4 2 18 17 30 35 13 18 36 40 52 50 33 19 41 57 14 67
-19 7 -17 7 -29 -2z"
        />
        <path
          d="M6376 4863 c-42 -18 -86 -54 -86 -72 0 -6 15 -15 33 -18 l32 -7 -35
-6 c-45 -7 -54 -37 -13 -45 49 -11 93 -34 93 -50 0 -14 -4 -14 -36 0 -20 8
-52 15 -70 15 -31 0 -34 -3 -34 -29 0 -23 6 -30 25 -35 21 -5 25 -12 23 -33
-3 -24 1 -28 36 -34 36 -6 77 2 118 22 23 12 31 11 23 0 -8 -13 46 -49 68 -45
25 3 35 16 20 21 -7 3 -13 8 -13 13 0 14 32 21 45 10 21 -17 29 8 14 38 -8 15
-17 29 -20 32 -12 9 -20 50 -11 50 5 0 12 19 16 42 5 29 17 51 41 74 40 37 43
50 15 59 -28 9 -38 0 -34 -32 2 -16 1 -20 -2 -10 -3 9 -14 17 -24 17 -12 0
-24 -17 -40 -60 -12 -33 -27 -60 -32 -60 -6 0 -7 10 -4 23 16 52 18 87 7 87
-6 0 -11 -9 -11 -19 0 -14 -5 -18 -20 -14 -15 4 -18 9 -10 17 6 9 6 16 -2 24
-15 15 -38 16 -38 2 0 -5 -5 -10 -10 -10 -7 0 -7 6 0 19 5 11 10 22 10 25 0
11 -34 5 -74 -11z m139 -293 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z"
        />
        <path d="M1311 4854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M6810 4840 c0 -6 -11 -8 -25 -4 -19 5 -25 2 -25 -11 0 -9 -4 -13 -10
-10 -5 3 -10 1 -10 -4 0 -6 5 -11 10 -11 7 0 7 -8 -1 -22 -11 -20 -13 -20 -24
-5 -31 43 -47 3 -20 -49 8 -16 15 -37 15 -46 0 -9 5 -20 10 -23 6 -3 10 2 10
13 0 15 5 18 26 14 20 -3 26 0 31 17 3 11 10 21 15 21 12 0 -1 42 -16 53 -7 4
-3 9 11 13 24 7 40 37 22 43 -8 2 -7 6 2 12 11 7 10 9 -3 9 -10 0 -18 -5 -18
-10z"
        />
        <path
          d="M6872 4802 c-21 -26 -48 -99 -39 -108 10 -10 37 10 37 27 0 22 11 24
28 7 10 -10 21 -3 54 29 l41 41 -38 16 c-50 21 -56 20 -83 -12z"
        />
        <path
          d="M6999 4743 c-31 -26 -89 -108 -89 -125 0 -4 6 -8 14 -8 7 0 20 -7 27
-17 13 -15 11 -16 -14 -9 -26 6 -28 4 -25 -16 2 -13 11 -24 23 -27 11 -2 22
-6 25 -10 10 -15 93 -41 110 -36 10 3 21 1 25 -5 12 -19 38 -11 32 10 -6 23
-1 25 16 8 7 -7 12 -27 12 -45 -1 -17 2 -31 5 -31 18 2 23 -4 10 -12 -12 -8
-12 -10 -1 -10 22 0 51 -41 51 -72 0 -21 -10 -39 -35 -61 -26 -23 -34 -36 -29
-51 4 -12 3 -18 -1 -14 -4 4 -17 3 -30 -2 -15 -6 -29 -4 -41 4 -15 12 -20 11
-35 -7 -26 -33 -14 -48 37 -43 30 3 48 0 56 -9 34 -37 38 -45 28 -45 -8 0 -8
-6 0 -20 6 -11 17 -20 25 -20 8 0 15 -4 15 -9 0 -8 54 -41 67 -41 8 0 2 39 -7
45 -5 3 -15 16 -21 27 -11 20 -10 20 22 -3 19 -12 36 -27 39 -31 3 -4 13 1 22
12 13 15 15 26 8 51 -5 18 -13 35 -17 38 -11 8 -33 49 -33 61 0 5 7 7 15 3 9
-3 15 -2 14 3 -4 22 2 23 21 4 11 -11 20 -29 20 -39 0 -10 7 -21 15 -25 10 -4
15 1 15 16 0 12 3 18 6 15 10 -9 64 32 64 49 0 8 -5 14 -11 14 -14 0 -59 47
-59 61 0 6 -4 8 -9 5 -5 -3 -14 1 -21 9 -7 8 -9 15 -4 15 4 0 -1 7 -12 15 -11
8 -14 15 -6 15 6 0 12 7 12 15 0 9 6 12 18 8 14 -5 13 -3 -3 10 -18 15 -18 16
-2 17 9 0 17 7 17 15 0 9 -6 12 -15 9 -18 -7 -20 12 -2 19 8 4 4 12 -13 25
-13 11 -28 18 -32 16 -4 -3 -8 0 -8 5 0 6 5 11 10 11 7 0 7 7 0 21 -8 14 -15
17 -23 11 -15 -13 -31 5 -35 37 -4 42 -21 53 -66 45 -23 -3 -47 -11 -55 -18
-11 -8 -11 -5 1 14 9 14 18 42 21 64 l6 38 -41 -6 c-47 -8 -99 -53 -77 -67 11
-6 10 -9 -4 -15 -10 -4 -17 -17 -17 -34 0 -31 -15 -50 -17 -22 -1 10 -2 22 -4
27 -6 29 17 79 47 103 18 14 30 30 28 34 -11 17 -54 7 -85 -19z m221 -562 c0
-12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z"
        />
        <path
          d="M7179 4728 c-6 -62 -6 -62 37 -65 39 -2 41 -1 36 23 -2 14 -12 28
-21 31 -9 2 -24 9 -33 14 -13 6 -18 5 -19 -3z"
        />
        <path
          d="M1330 4670 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M8490 4665 c0 -8 7 -15 15 -15 9 0 12 6 9 15 -4 8 -10 15 -15 15 -5
0 -9 -7 -9 -15z"
        />
        <path
          d="M1136 4655 c4 -9 11 -14 16 -13 15 5 11 23 -6 26 -11 2 -14 -2 -10
-13z"
        />
        <path
          d="M4750 4609 c0 -5 5 -7 11 -4 6 4 13 -2 16 -14 3 -12 9 -21 14 -21 10
0 11 12 3 34 -7 18 -44 22 -44 5z"
        />
        <path
          d="M6678 4541 c-7 -12 -20 -21 -28 -21 -10 0 -6 -8 12 -26 16 -17 32
-24 42 -21 20 8 20 46 1 70 -13 18 -14 18 -27 -2z"
        />
        <path
          d="M6415 4529 c-11 -17 0 -20 29 -9 15 5 32 8 37 4 5 -3 9 0 9 5 0 14
-67 14 -75 0z"
        />
        <path d="M7100 4430 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M7127 4362 c-21 -23 -22 -42 -3 -42 19 0 48 29 43 43 -6 19 -23 18
-40 -1z"
        />
        <path
          d="M4745 4360 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"
        />
        <path
          d="M743 4344 c-8 -20 8 -36 26 -25 15 10 8 41 -9 41 -6 0 -13 -7 -17
-16z"
        />
        <path
          d="M36 4305 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path d="M3765 4290 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z" />
        <path
          d="M6839 4214 c-14 -29 -22 -54 -17 -54 4 0 8 -7 8 -15 0 -22 17 -18 48
10 18 17 28 21 33 13 4 -7 6 -15 3 -19 -2 -4 5 -9 16 -12 21 -5 42 14 22 21
-7 2 -12 11 -12 20 0 21 -44 74 -54 65 -3 -3 -6 1 -6 10 0 31 -15 17 -41 -39z"
        />
        <path
          d="M5036 4164 c-3 -8 -10 -12 -16 -9 -5 3 -10 2 -10 -4 0 -6 -7 -8 -15
-5 -18 7 -32 -23 -18 -37 6 -6 23 3 46 25 38 35 44 46 28 46 -5 0 -12 -7 -15
-16z"
        />
        <path
          d="M9179 4165 c-4 -41 -20 -78 -51 -113 -15 -18 -26 -37 -23 -42 3 -5
-10 -14 -29 -21 -19 -7 -33 -16 -30 -21 3 -4 -2 -8 -10 -8 -9 0 -16 -4 -16
-10 0 -5 -8 -10 -17 -10 -15 0 -16 -2 -3 -10 12 -7 12 -10 1 -10 -7 0 -11 -6
-7 -15 3 -8 0 -15 -7 -15 -7 0 -4 -5 6 -11 9 -6 14 -15 11 -21 -5 -7 -1 -9 9
-5 21 8 21 3 0 -18 -12 -12 -13 -19 -4 -24 6 -4 9 -14 6 -23 -4 -9 3 -18 19
-26 21 -9 29 -7 60 16 19 15 36 34 37 42 0 8 9 -7 18 -35 10 -27 25 -66 34
-85 l16 -35 1 52 c0 32 -5 53 -12 56 -10 4 -10 7 0 18 15 16 18 389 2 389 -5
0 -11 -7 -11 -15z"
        />
        <path
          d="M8356 4148 c5 -7 2 -9 -8 -6 -8 4 -20 -2 -26 -13 -11 -17 -9 -19 12
-19 35 0 43 -20 9 -21 -15 -1 -21 -3 -11 -6 18 -5 33 -33 18 -33 -6 0 -10 -4
-10 -10 0 -5 9 -10 20 -10 11 0 29 -5 40 -12 22 -14 76 1 122 35 25 19 44 57
28 57 -4 0 -10 8 -13 19 -4 15 -12 18 -53 13 -27 -2 -52 -9 -55 -14 -4 -6 -9
-5 -12 3 -4 10 -6 10 -6 -2 -2 -31 -28 -29 -35 2 -3 16 -10 29 -16 29 -6 0 -8
-5 -4 -12z"
        />
        <path
          d="M7005 4110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M6863 4092 c-10 -24 7 -26 29 -4 l21 21 -22 -2 c-13 -1 -26 -7 -28
-15z"
        />
        <path
          d="M3932 4064 c10 -10 20 -16 22 -13 3 3 -5 11 -17 18 -21 13 -21 12 -5
-5z"
        />
        <path
          d="M6947 4074 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"
        />
        <path
          d="M5455 4060 c-3 -5 -2 -11 3 -14 5 -3 7 -20 6 -38 -2 -18 -1 -24 2
-13 3 11 11 25 16 30 8 7 8 5 3 -5 -8 -13 -7 -13 8 -1 26 20 21 46 -8 47 -14
1 -27 -2 -30 -6z"
        />
        <path
          d="M5477 3980 c-3 -13 0 -16 14 -13 22 6 26 30 5 30 -8 0 -17 -8 -19
-17z"
        />
        <path d="M5488 3939 c-2 -14 -2 -34 2 -44 11 -30 20 7 11 42 l-8 28 -5 -26z" />
        <path
          d="M3190 3920 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3345 3878 c2 -48 15 -90 35 -113 9 -11 31 -67 49 -125 45 -142 43
-137 48 -122 3 6 11 12 19 12 20 0 18 8 -11 33 -30 28 -40 93 -13 91 11 -1 15
3 11 10 -4 6 -24 38 -45 72 -21 34 -41 74 -44 90 -3 16 -16 42 -28 57 l-23 29
2 -34z"
        />
        <path
          d="M5454 3837 c-7 -19 3 -68 17 -81 9 -8 9 -6 1 9 -7 14 -7 27 3 46 14
26 11 39 -7 39 -5 0 -11 -6 -14 -13z"
        />
        <path
          d="M215 3791 c3 -5 -1 -12 -7 -14 -8 -4 -7 -6 4 -6 23 -1 32 15 12 23
-8 3 -13 2 -9 -3z"
        />
        <path
          d="M6877 3783 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path d="M3763 3765 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z" />
        <path
          d="M8745 3750 c-3 -11 -10 -20 -14 -20 -3 0 -7 -9 -7 -20 0 -11 4 -20 8
-20 4 0 8 -10 8 -22 0 -19 2 -20 11 -8 8 11 9 8 4 -13 -5 -25 -3 -28 13 -23
15 5 21 1 26 -16 10 -40 7 -51 -14 -45 -16 4 -18 2 -10 -13 7 -13 5 -21 -6
-31 -13 -11 -12 -14 13 -25 16 -7 21 -13 12 -13 -9 -1 -24 -10 -33 -20 -17
-18 -16 -19 8 -17 14 0 26 4 26 7 0 4 27 9 60 13 33 4 62 11 65 16 4 6 1 10
-5 10 -8 0 -8 6 0 21 14 25 5 53 -14 46 -9 -4 -16 3 -20 19 -6 23 -11 31 -54
80 -12 14 -16 27 -11 40 13 34 10 44 -17 44 -22 0 -25 2 -14 15 10 13 9 15 -8
15 -12 0 -23 -9 -27 -20z"
        />
        <path d="M116 3741 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M9103 3722 c-19 -15 -21 -24 -17 -84 4 -56 2 -67 -10 -62 -19 7 -96
-33 -96 -51 0 -8 -23 -37 -51 -65 -37 -37 -56 -49 -70 -45 -13 5 -19 2 -19
-10 0 -10 -8 -15 -22 -14 -63 6 -74 -20 -17 -40 16 -5 29 -17 29 -25 0 -9 5
-16 10 -16 11 0 14 -97 3 -103 -5 -2 -44 -1 -88 3 -101 8 -114 1 -107 -56 2
-24 -1 -57 -8 -74 -9 -23 -10 -33 -1 -44 6 -7 9 -21 5 -30 -6 -16 7 -21 48
-17 9 1 19 -7 23 -19 7 -24 13 -24 39 -5 11 8 34 15 52 15 25 0 39 8 58 32 16
21 22 36 16 43 -13 15 13 51 51 70 22 12 29 23 29 45 0 36 9 42 50 30 28 -8
37 -6 66 16 27 21 36 23 53 14 12 -6 21 -14 21 -18 0 -4 11 -20 25 -36 l25
-29 0 231 c0 128 -4 232 -8 232 -4 0 -13 -5 -19 -11 -8 -8 -17 -7 -32 2 -29
19 -33 36 -15 64 9 14 11 26 5 29 -5 3 -7 15 -4 26 6 24 4 24 -24 2z"
        />
        <path
          d="M5531 3648 c18 -35 70 -98 80 -98 12 0 12 37 -1 45 -5 3 -10 14 -10
23 0 9 -14 25 -31 35 -41 23 -52 22 -38 -5z"
        />
        <path
          d="M9157 3663 c-4 -3 -2 -12 4 -20 14 -16 -2 -18 -19 -1 -6 6 -14 9 -18
6 -8 -9 35 -30 46 -23 6 3 10 15 10 26 0 19 -11 25 -23 12z"
        />
        <path
          d="M6780 3640 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"
        />
        <path
          d="M8677 3629 c-6 -10 -20 -19 -29 -19 -14 0 -18 -8 -17 -32 1 -18 -2
-43 -6 -55 -9 -28 6 -30 59 -8 29 12 36 21 36 43 0 15 5 33 10 38 15 15 3 41
-21 47 -13 3 -24 -2 -32 -14z"
        />
        <path
          d="M7410 3513 c-61 -79 -80 -107 -80 -114 0 -5 17 -9 38 -9 20 0 44 -5
52 -10 12 -8 12 -10 1 -10 -8 0 -12 -3 -9 -6 4 -3 18 2 33 12 26 17 27 17 23
-1 -2 -10 2 -20 8 -22 16 -5 27 29 13 38 -6 4 -8 12 -5 18 4 6 2 11 -5 11 -9
0 -9 3 0 14 10 12 7 15 -21 18 -20 2 -32 8 -30 16 1 6 -3 12 -10 12 -7 0 -6 6
4 18 23 26 30 42 19 42 -5 0 -19 -12 -31 -27z"
        />
        <path
          d="M3646 3494 c-9 -8 -16 -20 -16 -26 0 -6 1 -9 3 -7 1 2 11 14 21 27
22 25 16 30 -8 6z"
        />
        <path
          d="M3487 3435 c4 -33 2 -48 -11 -60 -18 -18 -22 -45 -6 -45 6 0 10 -7
10 -16 0 -14 3 -14 15 -4 13 11 13 14 -2 26 -17 13 -17 13 2 15 26 2 27 2 47
-6 11 -4 18 0 22 13 4 11 16 22 27 25 17 5 18 8 8 26 -7 11 -16 18 -21 15 -10
-6 -68 24 -68 35 0 5 -7 11 -15 15 -12 4 -13 -3 -8 -39z"
        />
        <path
          d="M7224 3465 c11 -8 27 -15 35 -15 13 1 12 4 -3 15 -11 8 -27 15 -35
15 -13 -1 -12 -4 3 -15z"
        />
        <path d="M3605 3439 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
        <path d="M7190 3359 c0 -12 38 -31 44 -21 2 4 -7 12 -20 19 -13 6 -24 7 -24 2z" />
        <path
          d="M3503 3293 c-15 -6 -17 -37 -4 -62 21 -39 -26 -151 -53 -124 -3 3 -9
-8 -12 -24 -5 -26 -12 -32 -44 -37 -30 -5 -47 -16 -74 -50 -34 -40 -48 -75
-26 -61 6 3 10 -4 10 -17 0 -30 15 -52 29 -44 14 9 14 61 1 85 -8 16 -2 22 40
39 48 20 50 20 50 2 0 -10 5 -22 11 -25 11 -8 40 25 33 37 -3 5 6 8 20 8 13 0
31 7 40 15 8 9 21 14 28 13 8 -2 12 5 10 17 -1 11 -3 55 -3 97 -1 66 -4 81
-24 102 -13 14 -20 27 -17 30 6 7 2 7 -15 -1z"
        />
        <path
          d="M0 3187 c0 -41 3 -50 20 -54 11 -3 20 -8 20 -12 0 -4 14 -15 30 -24
20 -12 30 -26 30 -42 0 -29 -9 -35 -55 -37 -42 -2 -44 -10 -8 -29 16 -7 31
-15 35 -17 3 -2 8 7 10 20 2 13 9 22 16 20 17 -3 32 40 25 69 -5 19 -3 21 15
17 33 -9 27 8 -8 24 -16 7 -30 16 -30 20 0 5 -6 8 -14 8 -8 0 -30 19 -50 43
l-36 42 0 -48z"
        />
        <path
          d="M9111 3181 c-8 -6 -11 -16 -7 -27 5 -12 10 -14 17 -7 16 16 7 45 -10
34z"
        />
        <path
          d="M9107 3123 c-10 -10 -8 -73 2 -73 17 0 29 26 24 53 -5 27 -13 33 -26
20z"
        />
        <path
          d="M3372 2982 c-14 -11 -19 -22 -13 -30 7 -11 10 -10 15 3 3 10 12 15
21 11 9 -3 15 0 15 9 0 22 -14 25 -38 7z"
        />
        <path
          d="M9119 2985 c-3 -2 -46 -6 -96 -9 -66 -3 -104 -11 -140 -27 -47 -23
-130 -32 -139 -17 -10 15 -22 8 -28 -16 -3 -13 -22 -34 -41 -46 -41 -25 -55
-48 -55 -88 0 -29 -41 -82 -64 -82 -18 0 -63 -53 -106 -124 -39 -64 -49 -96
-31 -96 14 0 12 -98 -4 -142 -7 -23 -11 -46 -7 -52 3 -6 5 -21 3 -33 -1 -13 2
-23 7 -23 17 0 93 -83 107 -117 8 -18 18 -33 22 -33 5 0 33 -18 63 -41 39 -29
59 -39 74 -34 46 15 103 21 128 14 19 -5 42 0 78 17 78 36 115 32 139 -14 10
-19 20 -23 54 -21 23 1 50 -2 60 -6 21 -9 22 -54 2 -109 -16 -45 -9 -70 29
-105 l26 -24 0 550 0 550 -26 12 c-25 11 -26 12 -9 31 11 13 15 25 10 37 -5
10 -7 22 -6 27 4 16 -38 34 -50 21z m-222 -897 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z"
        />
        <path d="M310 2930 c0 -6 19 -10 42 -10 49 0 47 8 -5 14 -20 3 -37 1 -37 -4z" />
        <path
          d="M583 2932 c-13 -2 -23 -8 -23 -13 0 -13 37 -11 45 2 9 14 5 16 -22
11z"
        />
        <path
          d="M3075 2631 c-11 -23 -12 -36 -5 -57 14 -37 31 -19 37 40 6 54 -9 61
-32 17z"
        />
        <path
          d="M6530 2541 l-25 -22 25 7 c14 3 29 10 34 14 5 5 16 5 25 1 9 -5 34
-14 55 -21 22 -7 49 -23 61 -36 16 -17 33 -24 59 -24 20 0 36 4 36 9 0 15
-154 84 -200 88 -36 4 -50 1 -70 -16z"
        />
        <path
          d="M2772 2471 c-16 -10 -13 -42 4 -48 15 -5 44 24 44 43 0 15 -27 19
-48 5z"
        />
        <path
          d="M6838 2453 c22 -9 13 -33 -14 -33 -14 0 -23 -4 -20 -9 3 -4 16 -6 30
-4 13 3 27 0 31 -7 5 -8 10 -8 21 1 7 6 29 11 49 11 19 0 35 4 35 9 0 5 -5 9
-11 9 -6 0 -17 7 -25 15 -10 9 -33 15 -62 14 -26 0 -41 -3 -34 -6z"
        />
        <path
          d="M3093 2389 c-12 -47 -7 -79 14 -79 9 0 12 -6 8 -19 -6 -21 3 -51 17
-51 5 0 8 8 7 18 -3 30 1 35 16 22 8 -7 15 -9 15 -6 0 4 16 -5 35 -20 21 -15
34 -33 31 -41 -3 -7 -2 -21 1 -30 6 -15 7 -15 14 3 15 38 10 55 -21 65 -16 6
-30 17 -30 25 0 8 -13 17 -29 20 -37 8 -45 27 -27 63 9 17 11 33 6 41 -5 8 -6
18 -4 22 3 4 -5 8 -18 8 -19 0 -26 -8 -35 -41z"
        />
        <path d="M6690 2411 c0 -5 6 -11 13 -13 17 -7 38 2 31 13 -7 12 -44 12 -44 0z" />
        <path
          d="M7005 2410 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"
        />
        <path
          d="M3158 2205 c4 -22 7 -24 20 -13 14 12 14 10 3 -11 -11 -19 -11 -25 0
-32 8 -5 15 -4 17 1 2 6 10 10 18 10 9 0 1 14 -20 35 -20 19 -37 35 -39 35 -2
0 -2 -11 1 -25z"
        />
        <path
          d="M3052 2160 c-13 -16 -19 -30 -14 -30 4 0 18 14 30 30 13 17 19 30 14
30 -4 0 -18 -13 -30 -30z"
        />
        <path
          d="M7150 2174 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"
        />
        <path
          d="M3246 2139 c-11 -10 -24 -18 -30 -17 -16 3 -56 -21 -56 -34 0 -9 3
-9 11 -1 15 15 43 5 54 -20 14 -31 45 -35 45 -5 0 16 3 19 9 10 16 -26 21 17
6 54 -8 19 -15 34 -17 33 -2 0 -12 -9 -22 -20z"
        />
        <path
          d="M1957 2138 c-7 -20 0 -79 10 -89 16 -16 43 2 43 27 0 26 -26 74 -40
74 -4 0 -10 -6 -13 -12z"
        />
        <path
          d="M2993 2047 c-55 -56 -99 -94 -115 -100 -10 -4 -18 -13 -18 -22 0 -11
-8 -15 -27 -13 -25 3 -28 0 -31 -32 -3 -23 2 -38 13 -46 9 -7 16 -15 16 -18
-4 -28 2 -36 24 -36 14 0 25 -4 25 -9 0 -5 9 -7 19 -4 10 3 29 0 40 -7 25 -13
58 -6 58 13 0 26 46 117 59 117 17 0 19 17 3 22 -22 7 -21 70 2 94 21 22 21
22 1 28 -11 4 -23 13 -26 21 -7 20 -17 19 -43 -8z"
        />
        <path
          d="M2400 2027 c0 -5 22 -32 49 -58 50 -49 62 -67 100 -145 11 -22 42
-61 69 -85 40 -37 53 -44 71 -38 16 7 21 17 21 44 0 22 4 34 10 30 14 -9 12 8
-3 30 -10 13 -16 15 -21 6 -6 -8 -15 -5 -33 13 -14 14 -22 29 -19 34 3 5 -2
17 -12 26 -29 28 -65 56 -72 56 -4 0 -25 16 -48 37 -42 37 -112 69 -112 50z"
        />
        <path
          d="M3327 1917 c-6 -17 3 -66 14 -73 5 -3 6 2 3 11 -4 8 -1 22 6 30 9 11
9 18 -3 29 -12 12 -16 13 -20 3z"
        />
        <path
          d="M2460 1900 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3120 1891 c-24 -25 -56 -124 -42 -129 7 -2 12 -17 12 -33 0 -48 28
-37 32 13 l3 41 15 -29 c17 -33 41 -55 52 -48 14 9 8 42 -8 48 -9 4 -14 9 -11
13 4 3 2 12 -4 20 -15 17 6 44 29 36 13 -4 14 -3 3 5 -11 8 -11 11 0 16 8 3
-2 3 -21 -1 -59 -10 -65 -9 -65 12 0 18 7 20 57 21 31 0 63 6 72 13 22 18 13
26 -12 10 -14 -9 -30 -9 -58 -2 -32 8 -42 7 -54 -6z m70 -155 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M3444 1848 c-21 -10 -21 -11 -4 -28 10 -10 29 -20 42 -23 19 -4 18
-4 -7 -6 -27 -1 -28 -2 -12 -18 9 -9 24 -18 34 -19 10 -1 34 -8 53 -14 19 -7
41 -14 47 -16 7 -2 22 -18 32 -35 20 -32 20 -32 -2 -51 l-22 -19 37 6 c26 4
39 2 45 -8 4 -8 22 -17 39 -20 26 -5 35 -2 50 18 25 34 49 31 84 -7 26 -28
107 -65 118 -53 2 2 -17 22 -43 46 -37 34 -45 47 -40 66 3 13 1 23 -4 23 -5 0
-24 14 -43 31 -44 41 -237 114 -245 93 -3 -8 -11 -14 -19 -14 -7 0 -17 -8 -23
-17 -8 -14 -12 -15 -25 -4 -9 7 -16 24 -16 37 0 17 -8 28 -26 34 -14 6 -26 10
-27 9 -1 0 -12 -5 -23 -11z"
        />
        <path
          d="M6295 1850 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2500 1831 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"
        />
        <path d="M3568 1813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M2750 1780 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"
        />
        <path d="M3285 1770 c-8 -13 15 -24 27 -12 6 6 4 11 -6 15 -8 3 -18 2 -21 -3z" />
        <path
          d="M3366 1771 c17 -4 37 -11 44 -16 8 -5 11 -3 8 6 -3 8 -20 15 -44 16
-37 2 -37 1 -8 -6z"
        />
        <path d="M4036 1758 c10 -14 21 -24 23 -22 3 3 -6 15 -18 27 l-24 22 19 -27z" />
        <path
          d="M4012 1729 c-9 -14 -23 -19 -52 -19 -49 0 -53 -17 -5 -26 37 -7 58 4
76 37 13 25 -4 32 -19 8z"
        />
        <path
          d="M3516 1694 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"
        />
        <path
          d="M4100 1703 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"
        />
        <path
          d="M2700 1674 c-8 -8 -9 -14 -4 -13 5 1 22 -3 37 -9 16 -7 36 -12 46
-12 10 0 31 -4 47 -10 38 -12 143 -19 149 -9 3 4 -13 10 -35 14 -22 4 -40 10
-40 14 0 17 -51 31 -70 21 -15 -8 -28 -7 -49 5 -38 19 -65 19 -81 -1z"
        />
        <path
          d="M3420 1640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4225 1630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3018 1623 c-39 -6 -35 -23 5 -23 41 0 66 14 42 24 -9 3 -17 5 -18 5
-1 -1 -14 -4 -29 -6z"
        />
        <path
          d="M3135 1621 c-29 -3 -45 -9 -38 -14 15 -9 93 2 93 14 0 5 -1 8 -2 7
-2 0 -25 -4 -53 -7z"
        />
        <path d="M4270 1611 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
        <path
          d="M3234 1600 c-23 -9 -39 -28 -31 -36 2 -3 26 7 53 20 39 21 44 25 24
25 -14 0 -35 -4 -46 -9z"
        />
        <path d="M3088 1574 c24 -17 39 -14 27 5 -3 6 -16 11 -28 11 l-21 0 22 -16z" />
        <path
          d="M4240 1586 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path
          d="M3720 1483 c-6 -32 -14 -71 -17 -88 -11 -62 -37 -81 -68 -52 -22 19
-38 31 -68 50 -23 15 -28 23 -22 44 3 14 13 32 21 40 19 20 12 28 -26 31 -18
1 -40 7 -50 12 -25 13 -41 13 -37 -1 1 -6 -9 -13 -21 -14 -24 -2 -71 -50 -72
-72 0 -18 -39 -16 -46 2 -10 24 -48 17 -77 -13 -15 -15 -27 -32 -27 -36 0 -15
-29 -36 -35 -26 -9 15 -35 -7 -35 -29 0 -40 -50 -67 -170 -95 -14 -3 -35 -15
-48 -26 -14 -14 -22 -16 -23 -8 -2 25 -22 -58 -22 -94 -1 -20 -5 -35 -9 -32
-9 6 1 -71 13 -104 15 -39 11 -139 -6 -156 -20 -19 -19 -29 3 -49 24 -22 54
-21 106 3 29 13 54 17 79 13 29 -4 38 -1 49 16 7 11 20 21 30 21 9 0 19 4 22
9 3 5 23 11 43 14 21 3 60 9 87 13 27 4 51 3 55 -2 3 -5 18 -14 32 -21 18 -7
30 -25 40 -57 l15 -46 37 36 c20 20 37 32 37 26 0 -16 -28 -64 -42 -73 -10 -7
-9 -9 4 -9 9 0 22 8 28 18 10 14 11 13 7 -10 -2 -15 -1 -25 3 -23 10 6 20 -20
20 -55 0 -24 39 -89 40 -66 0 5 9 1 19 -8 20 -18 61 -15 61 5 0 5 4 9 10 9 5
0 9 -3 8 -8 0 -4 2 -15 6 -24 7 -16 11 -16 50 7 24 14 52 25 63 25 16 0 23 10
32 44 6 25 16 48 21 51 6 4 19 23 29 43 11 20 33 52 49 70 17 19 38 57 48 85
21 62 35 186 23 201 -5 6 -18 22 -29 35 -11 13 -20 32 -20 43 0 10 -5 18 -10
18 -10 0 -35 48 -32 62 1 4 -14 16 -33 28 -28 18 -35 28 -35 54 0 18 -3 36 -7
40 -9 8 -10 14 -12 52 -1 17 -8 28 -21 31 -11 3 -20 13 -20 23 0 25 -21 80
-30 80 -5 0 -14 -26 -20 -57z"
        />
        <path d="M3390 1526 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z" />
        <path
          d="M1060 1505 c0 -7 -8 -18 -17 -24 -10 -5 -17 -12 -15 -15 6 -14 1 -27
-8 -21 -5 3 -10 2 -10 -3 0 -5 -20 -17 -45 -26 -48 -19 -60 -40 -50 -85 4 -20
0 -39 -13 -61 -15 -23 -18 -43 -15 -73 7 -57 22 -70 66 -62 34 7 36 10 70 98
19 51 40 111 46 134 6 23 16 45 21 48 6 4 8 17 5 31 -3 13 -7 35 -10 49 -6 27
-25 35 -25 10z"
        />
        <path
          d="M4420 1400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4330 1233 c0 -17 57 -80 64 -72 4 3 -9 24 -29 45 -19 21 -35 33 -35
27z"
        />
        <path d="M3458 683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path
          d="M4480 654 c0 -5 5 -36 10 -69 10 -64 -3 -125 -26 -125 -21 0 -28 -22
-10 -32 20 -11 21 -42 1 -58 -10 -8 -12 -17 -6 -23 13 -13 78 40 86 70 3 13
11 23 18 23 11 0 47 49 47 63 0 3 -12 5 -27 4 -23 -2 -28 3 -34 28 -4 17 -7
24 -8 17 0 -7 -5 -10 -11 -7 -5 3 -8 22 -5 41 3 26 -1 41 -15 57 -11 11 -20
17 -20 11z"
        />
        <path
          d="M3618 423 c-1 -43 2 -86 7 -92 2 -2 17 3 33 10 18 8 35 27 45 51 23
53 22 58 -17 51 -19 -3 -36 -1 -40 6 -14 22 -26 10 -28 -26z"
        />
        <path
          d="M4355 340 c-34 -43 -105 -100 -124 -100 -11 0 -68 -63 -73 -80 -5
-17 32 -50 56 -50 9 0 37 27 63 60 27 35 54 60 65 60 10 0 18 6 18 13 0 6 16
29 35 49 37 39 45 68 19 68 -8 0 -13 4 -9 9 3 5 2 12 -3 15 -5 3 -26 -17 -47
-44z"
        />
        <path
          d="M6946 195 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path d="M6958 163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />

        {newRegionsArray.map((m, index) => (
          <rect
            key={`border-${index}`}
            x={m.params[0].rx}
            y={m.params[0].ry}
            width={m.params[0].rw}
            height={m.params[0].rh}
            id={`svg-border-${m.id}`}
            className={`svg-border${hoveredId === m.id ? "-hovered" : ""}`}
          />
        ))}

        {newRegionsArray.map((m, index) => (
          <React.Fragment key={`non-test-${index}`}>
            <circle
              cx={m.params[0].cx}
              cy={m.params[0].cy}
              r="300"
              className={`svg-circle${hoveredId === m.id ? "-hovered" : ""}`}
              id={`svg-circle-${m.id}`}
              onMouseEnter={() => setHoveredId(m.id)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => handleRegionChange(m.id)}
            >
              <title>{m.name}</title>
            </circle>
          </React.Fragment>
        ))}
      </g>
    </svg>
  );
}

export default SelectLocation;
