import { useEffect } from "react";

export const useWindowResize = (setSidebarCollapsed, setIsMobileView) => {
  useEffect(() => {
    const handleWindowResize = () => {
      const newCollapsed = window.innerWidth <= 1000;
      const newIsMobileView = window.innerWidth <= 900;
      const isCollapsed = setSidebarCollapsed();
      const isMobile = setIsMobileView();
      if (newCollapsed !== isCollapsed) {
        setSidebarCollapsed(newCollapsed);
      }
      if (newIsMobileView !== isMobile) {
        setIsMobileView(newIsMobileView);
      }
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [setSidebarCollapsed, setIsMobileView]);
};
