import React, { useState, useEffect } from "react";

import PostComponent from "./components/postsComponent";
import StreamComponent from "./components/streamComponent";

import "../../static/css/profile/profileComponent.scss";

import ThemeIcon from "@mui/icons-material/ColorLens";
import PostIcon from "@mui/icons-material/DynamicFeed";
import StreamIcon from "@mui/icons-material/LiveTv";

const MainProfileComponent = () => {
  const primaryColor = "var(--graph-color-one)";
  const secondaryColor = "var(--graph-color-two)";
  const tertiaryColor = "var(--graph-color-three)";
  const backgroundColor = "var(--hover-color)";
  const gridColor = "var(--secondary-font-color)";

  const [activeComponent, setActiveComponent] = useState("stats");
  const [textAnimationClass, setTextAnimationClass] = useState("");

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  useEffect(() => {
    setTextAnimationClass("animate-text");
    const timeout = setTimeout(() => {
      setTextAnimationClass("");
    });
    return () => clearTimeout(timeout);
  }, [activeComponent]);

  const getComponentText = () => {
    switch (activeComponent) {
      case "posts":
        return "Posting History";
      case "stats":
        return "Stream Stats";
      case "another":
        return "Yet Another Text";
      default:
        return "";
    }
  };

  return (
    <div className="mr-2">
      <div className="profile-btn-container row">
        <div className="col-2">
          <h5
            className={`profile-header-text text-center ${textAnimationClass}`}
          >
            {getComponentText()}
          </h5>
        </div>
        <div
          className={`profile-btn profile-stats-btn col-1 ${
            activeComponent === "stats" ? "active" : ""
          }`}
          onClick={() => handleComponentChange("stats")}
        >
          <div className="profile-btn-wrapper">
            <StreamIcon
              className="profile-icon profile-theme-icon"
              fontSize="large"
            />
          </div>
        </div>
        <div
          className={`profile-btn profile-theme-btn col-1 ${
            activeComponent === "posts" ? "active" : ""
          }`}
          onClick={() => handleComponentChange("posts")}
        >
          <div className="profile-btn-wrapper">
            <PostIcon
              className="profile-icon profile-theme-icon"
              fontSize="large"
            />
          </div>
        </div>
        <div
          className={`profile-btn profile-another-btn col-1 ${
            activeComponent === "another" ? "active" : ""
          }`}
          onClick={() => handleComponentChange("another")}
        >
          <div className="profile-btn-wrapper">
            <ThemeIcon
              className="profile-icon profile-theme-icon"
              fontSize="large"
            />
          </div>
        </div>
      </div>

      <div className="profile-component-container row">
        {activeComponent === "posts" ? (
          <div className="profile-component-posts">
            <PostComponent
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              gridColor={gridColor}
            />
          </div>
        ) : activeComponent === "stats" ? (
          <div className="profile-component-stats">
            {" "}
            <StreamComponent
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              tertiaryColor={tertiaryColor}
              gridColor={gridColor}
            />
          </div>
        ) : (
          <div className="profile-component-another">Test Text 2</div>
        )}
      </div>
    </div>
  );
};

export default MainProfileComponent;
