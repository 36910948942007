import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Sidebar from "../components/sidebar/sidebar.jsx";
import Navbar from "../components/navbar/navbar.jsx";
import LoginModal from "../components/auth/loginContainer.jsx";
import { Page } from "@highmountainlabs/arclight-ui";
import { fetchUserTheme } from "../controllers/themeController.js";

import init from "../hooks/init";

import "../static/css/main.scss";

const VerificationPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  let v;
  try {
    if (props.fns?.readState()?.query?.v) {
      v = JSON.parse(atob(props.fns.readState().query.v));
    }
  } catch (e) {}

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  useEffect(() => {
    const fetchUserThemeId = async () => {
      setIsLoading(true);
      try {
        if (
          username !== undefined &&
          props.fns.readToken()._token !== undefined
        ) {
          await fetchUserTheme(props, username);
        }
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserThemeId();
  }, [props, username]);

  const handleLogin = () => {
    props.fns.verifyRegisterUser(username);
    props.fns.authenticate({
      username: username,
      password: password,
    });
    Cookies.set("username", username, { path: "/" });
    setTimeout(function () {
      window.location.href = "/";
    }, 250);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordChangeConfirm = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleVerification = () => {
    if (v) {
      if (v._passwordresettoken && props?.fns?.calls?.verifyresetuserpassword) {
        if (!password.length) return alert(`Password cannot be empty`);
        if (password !== confirmPassword) return alert(`Passwords don't match`);
        props.fns.calls.verifyresetuserpassword({
          password,
          v: props.fns.readState().query.v,
        });
      } else if (props?.fns?.verifyRegisterUser) {
        props.fns.verifyRegisterUser(password, v);
      }
    }
  };

  useEffect(() => {
    if (
      !props?.D?.verifyregisteruser?._token &&
      !props?.D?._diff?.serversuccess
    )
      return;
    // props.fns.writeToken(props.D.verifyregisteruser._token);
    setTimeout(() => props.fns.route("/"), 1000);
  }, [props.D]);

  init(props);

  return props?.D?.getrecords_settings?.init?.records ? (
    <Page fns={props.fns}>
      <div className="main-container">
        <div className="navbar-container">
          <Navbar {...props} />
        </div>
        <div className="container-fluid row full-height p-0 m-0">
          <div className={`col-2 sidebar-container p-0`}>
            <Sidebar
              {...props}
              sidebarCollapsed={sidebarCollapsed}
              onSidebarCollapse={handleSidebarCollapse}
            />
          </div>
          <div
            className={`col-8 app-container ${
              sidebarCollapsed ? "app-container-collapsed" : ""
            }`}
          >
            <div>
              <div className="d-flex align-items-center justify-content-center vh-100 flex-column main-theme-color">
                <h2 className="text-center mb-4 main-theme-header">
                  User Verification
                </h2>
                <LoginModal
                  resetPassword={v?._passwordresettoken}
                  fns={props.fns}
                  verify={props.fns.readState()?.query?.v}
                  handleUsernameChange={handleUsernameChange}
                  handlePasswordChange={handlePasswordChange}
                  handlePasswordChangeConfirm={handlePasswordChangeConfirm}
                  handleLogin={handleLogin}
                  handleVerification={handleVerification}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : null;
};

export default VerificationPage;
