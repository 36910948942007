import React from "react";
import Avatar from "@material-ui/core/Avatar";
import FollowerIcon from "@mui/icons-material/Loyalty";

const NewFollower = ({ activityText }) => {
  return (
    <div className="profile-feed-component row">
      <div className="col-2 pt-1">
        <Avatar className="profile-mui-btn">
          <FollowerIcon />
        </Avatar>
      </div>
      <div className="profile-activity-content col-10">
        <h6 className="profile-activity-header-text text-left">NEW FOLLOWER</h6>
        <span className="profile-activity-text">{activityText}</span>
      </div>
    </div>
  );
};

export default NewFollower;
