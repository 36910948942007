import React, { useState } from "react";
import CheckmarkIcon from "@mui/icons-material/CheckCircle";
import LiveIcon from "@mui/icons-material/LiveTv";
import CircleIcon from "@mui/icons-material/Circle";

import "../../../static/css/sidebar/sidebarHeader.scss";

import AuthModal from "../../modals/authModal";

function SidebarHeader(props) {
  const [showModal, setShowModal] = useState(false);
  let avatar;

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (props?.user?.profile_decorations?.avatar) {
    avatar = props.user.profile_decorations.avatar;
  } else {
    avatar =
      "https://skywatchpro.us/arclight/static/media/661f1a8ef881663b74ebc3a6.png";
  }

  if (props.collapsed) {
    return (
      <div>
        <div className="user-pic" onClick={handleModalOpen}>
          <img className="img-user-collapsed" src={props?.D?.verifyself?.msg?.User?.profile_decorations?.avatar} />
        </div>
        <AuthModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          props={props}
        />
      </div>
    );
  }

  if (props.fns.readToken()._token !== undefined) {
    return (
      <div>
        <div className="sidebar-header" onClick={handleModalOpen}>
          <div className="user-pic">
            <img
              className="img-responsive img-rounded img-sidebar-avatar"
              src={props?.D?.verifyself?.msg?.User?.profile_decorations?.avatar}
            />
          </div>
          <div className="user-info">
            <span className="user-name">
              {props?.D?.verifyself?.msg?.User?.username}
            </span>
            <span className="user-role">
              Storm Chaser
              <CheckmarkIcon className="approved-user" />
            </span>
            <div className="user-status-wrapper">
              <span className="user-status">
                <CircleIcon className="online-icon" />
                <span>Online</span>
              </span>
              <span className="chaser-status">
                <LiveIcon className="chaser-live-icon" />
                <span>Live</span>
              </span>
            </div>
          </div>
        </div>
        <AuthModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          props={props}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="sidebar-header" onClick={handleModalOpen}>
        <div className="user-pic">
          <img
            className="img-responsive img-rounded img-sidebar-avatar"
            src={avatar}
          />
        </div>
        <div className="user-info">
          <span className="user-welcome">Welcome to</span>
          <span className="user-logo-text">
            {props.D.getrecords_settings.init.records[0].applicationtitle}
          </span>
          <span className="user-role">Login/Register</span>
        </div>
      </div>
      <AuthModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        props={props}
      />
    </div>
  );
}

export default SidebarHeader;
