import React, { useEffect, useState } from "react";
import { Page } from "@highmountainlabs/arclight-ui";
import Navbar from "../components/navbar/navbar.jsx";
import Sidebar from "../components/sidebar/sidebar.jsx";
import MapPageContent from "../components/product/mappagecontainer.jsx";

import init from "../hooks/init.js";
import { useDataFetching } from "../hooks/dataFetching";
import { useWindowResize } from "../hooks/windowResize";

import "../static/css/main.scss";
import "../static/css/fonts.scss";

const MapPage = (props) => {
  init(props);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [selectedTimeStep, setSelectedTimeStep] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const [theme, setTheme] = useState({});
  const [productChange, setProductChange] = useState("model");
  const [modelCategoryChange, setModelCategoryChange] = useState("climate");
  const [modelNameChange, setModelNameChange] = useState("cansips");
  const [paramChange, setParamChange] = useState("ssta");
  const [regionChange, setRegionChange] = useState("north_atlantic");
  const [regionCategoryChange, setRegionCategoryChange] = useState("global");
  const [initTimeChange, setInitTimeChange] = useState([]);
  const [uniqueInitTimes, setUniqueInitTimes] = useState([]);
  const [runChange, setRunChange] = useState(uniqueInitTimes[0]);
  const [initTime, setInitTime] = useState(uniqueInitTimes[0]);
  const [imageArrayLength, setImageArrayLength] = useState(0);

  const page_name = "map";
  const image_array = [];
  // console.log("MapPage props: ", props);
  // console.log("productChange:", productChange);
  // console.log("modelCategoryChange:", modelCategoryChange);
  // console.log("modelNameChange:", modelNameChange);
  // console.log("paramChange:", paramChange);
  // console.log("regionChange:", regionChange);
  // console.log("runChange:", runChange);

  // get the product from the data
  const foundParam = props?.D?.getproducts?.msg?.products
    ?.find((product) => product.name === productChange)
    ?.productcategory?.find((category) => category.name === modelCategoryChange)
    ?.productcategory?.find((model) => model.name === modelNameChange)
    ?.productcategory?.find((region) => region.name === regionChange)
    ?.parameters?.find((param) => param.name === paramChange);

  // const initialRun = foundParam?.run?.map((item) => item?.init_time);
  // const [run, setRun] = useState(determineInitialRun);

  // const determineInitialRun = () => {
  //   const initialRun = foundParam?.run?.map((item) => item?.init_time);
  //   console.log("initial run:", initialRun);
  //   return initialRun && initialRun.length > 0
  //     ? initialRun[0]
  //     : "default_value";
  // };

  // const [run, setRun] = useState(determineInitialRun);

  useEffect(() => {
    if (uniqueInitTimes.length > 0) {
      const sortedUniqueInitTimes = uniqueInitTimes
        .slice()
        .sort((b, a) => a.localeCompare(b));
      setRunChange(sortedUniqueInitTimes[0]);
    }
  }, [uniqueInitTimes]);

  useEffect(() => {
    const initTimes = foundParam?.run?.map((item) => item?.init_time);
    const uniqueSet = new Set(initTimes);
    setUniqueInitTimes(Array.from(uniqueSet));
  }, [modelNameChange, paramChange, foundParam?.run]);

  if (foundParam && foundParam.run && productChange === "model") {
    foundParam.run.forEach((img_url) => {
      if (img_url.init_time === runChange) {
        image_array.push(img_url?.img);
      }
    });
  } else if (foundParam && foundParam.run && productChange === "satellite") {
    foundParam.run.forEach((img_url) => {
      image_array.push(img_url?.img);
    });
  }

  const imageUrl = image_array[selectedTimeStep];

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  useEffect(() => {
    if (imageUrl === undefined && productChange === "model") {
      setParamChange("ssta");
    } else if (imageUrl === undefined && productChange === "satellite") {
      setParamChange("true_color");
    }
  }, [modelCategoryChange, modelNameChange]);

  useEffect(() => {
    if (props?.fns?.calls?.getproducts) {
      props.fns.calls.getproducts({
        search: {
          product: productChange,
          child_category: modelCategoryChange,
          child_category_level2: modelNameChange,
          region: regionChange,
          parameter: paramChange,
        },
      });
    }
  }, [
    regionChange,
    productChange,
    modelCategoryChange,
    modelNameChange,
    paramChange,
    regionChange,
    initTime,
  ]);

  useEffect(() => {
    if (props?.fns?.calls?.getproducts && !props?.D?.getproducts) {
      props.fns.calls.getproducts({
        search: {
          product: productChange,
          child_category: modelCategoryChange,
          child_category_level2: modelNameChange,
          region: regionChange,
          parameter: paramChange,
        },
      });
    }
  }, [props?.fns?.calls?.getproducts]);

  // useEffect to dynamically change theme for user
  useDataFetching(props, props?.D.verifyself?.msg?.User?.username);
  useWindowResize(setSidebarCollapsed, setIsMobileView);
  useEffect(() => {
    if (props?.fns?.calls?.verifyself) props.fns.calls.verifyself();
  }, [props.D.updateuser]);

  const handleTimeStepChange = (newTimeStep) => {
    setSelectedTimeStep(newTimeStep);
  };

  const handleSliderChange = (newValue) => {
    setSelectedTimeStep(newValue);
  };

  const handleRegionChange = (newRegion) => {
    setRegionChange(newRegion);
  };

  const handleProductChange = (newProduct) => {
    setProductChange(newProduct);
  };

  const handleParamChange = (newParam) => {
    setParamChange(newParam);
  };

  const handleModelNameChange = (newModelName) => {
    setModelNameChange(newModelName);
  };

  const handleModelCategoryChange = (newCategory) => {
    setModelCategoryChange(newCategory);
  };

  const handleInitTimeChange = (newInitTime) => {
    setInitTimeChange(newInitTime);
  };

  const onInitTimeChange = (time) => {
    setInitTime(time);
  };

  const handleRunChange = (newRun) => {
    setRunChange(newRun);
  };

  const handleRegionCategoryChange = (newCategory) => {
    setRegionCategoryChange(newCategory);
  };

  return props?.D?.getrecords_settings?.init?.records ? (
    <Page fns={props.fns}>
      <div className="main-container">
        <div className="navbar-container">
          <Navbar {...props} />
        </div>
        <div className="container-fluid row full-height p-0 m-0 ">
          <div className={`col-2 sidebar-container p-0 d-none d-xl-block`}>
            <Sidebar
              {...props}
              sidebarCollapsed={sidebarCollapsed}
              selectedProduct={productChange}
              selectedRegion={regionChange}
              selectedModelName={modelNameChange}
              selectedModelCategory={modelCategoryChange}
              selectedParam={paramChange}
              selectedRun={runChange}
              initTime={uniqueInitTimes}
              initTimeChoice={initTime}
              page_name={page_name}
              step_cnt={image_array.length}
              onSidebarCollapse={handleSidebarCollapse}
              onTimeStepChange={handleTimeStepChange}
              onModelNameChange={handleModelNameChange}
              onModelCategoryChange={handleModelCategoryChange}
              onProductChange={handleProductChange}
              onRegionChange={handleRegionChange}
              onParamChange={handleParamChange}
              onInitTimeChange={handleInitTimeChange}
              onInitTime={onInitTimeChange}
              onRunChange={handleRunChange}
            />
          </div>
          <div
            className={`col-8 app-container ${
              sidebarCollapsed ? "app-container-collapsed" : ""
            }`}
          >
            <MapPageContent
              {...props}
              selectedTimeStep={selectedTimeStep}
              selectedProduct={productChange}
              selectedRegion={regionChange}
              selectedRegionCategory={regionCategoryChange}
              selectedModelName={modelNameChange}
              selectedParam={paramChange}
              initTime={initTimeChange}
              initTimeChoice={initTime}
              modelCategoryChoice={modelCategoryChange}
              step_cnt={image_array.length}
              onTimeStepChange={setSelectedTimeStep}
              onSliderChange={handleSliderChange}
              onRegionChange={handleRegionChange}
              onProductChange={setProductChange}
              onRegionCategoryChange={handleRegionCategoryChange}
              onModelNameChange={handleModelNameChange}
              onParamChange={setParamChange}
              onInitTimeChange={handleInitTimeChange}
              imageUrl={imageUrl}
            />
          </div>
        </div>
      </div>
    </Page>
  ) : null;
};

export default MapPage;
