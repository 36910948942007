import React from "react";
import { Modal, Button } from "react-bootstrap";
import CheckmarkIcon from "@mui/icons-material/CheckCircle";
import UserIcon from "@mui/icons-material/Person2";
import SubIcon from "@mui/icons-material/Loyalty";

import { resetTheme } from "../../controllers/themeController";
import "../../static/css/modal/verifiedModal.scss";
import "../../static/css/modal/modal.scss";

function AuthenticatedUserModal({ showModal, handleCloseModal, props }) {
  const userinfo = props?.D?.verifyself?.msg?.User;
  return (
    <Modal show={showModal} onHide={handleCloseModal} className="auth-modal">
      <Modal.Header className="custom-modal-header">
        <Modal.Title className="custom-modal-title">
          Welcome {userinfo?.username}
          <button
            type="button"
            className="btn-close btn-modal-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <div className="verified-modal-wrapper">
          <div className="verified-modal-avatar-container">
            <img
              src={userinfo?.profile_decorations?.avatar}
              alt="user_avatar"
            />
            <Button
              variant="primary"
              size="sm"
              className="mt-1 verified-modal-btn"
            >
              Edit Profile
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="mt-1 mx-1 verified-modal-btn"
            >
              Reset Password
            </Button>
          </div>
          <div className="verified-modal-info-container">
            <div className="verified-modal-info">
              <h5>
                <UserIcon />
                User Information
              </h5>
              <p>
                <b>Account name: </b>
                <span>{userinfo?.username}</span>
              </p>
              <p>
                <b>Email: </b>
                <span>{userinfo?.email}</span>
              </p>
              <p>
                <b>Rank: </b>
                <span>Administrator</span>
              </p>
              <p>
                <b>Member since: </b>
                <span>Jun 5, 2024</span>
              </p>
              <h5 className="pt-4">
                <SubIcon />
                Subscription Info
              </h5>
              <p>
                <b>Status: </b>
                <span>
                  Currently Active
                  <CheckmarkIcon
                    className="verified-modal-checkmark mx-1"
                    fontSize="14px"
                  />
                </span>
              </p>
              <p>
                <b>Expires: </b>
                <span>Jun 26, 2024</span>
              </p>
              <p>
                <b>Tier: </b>
                <span>Founder</span>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button
          variant="danger"
          onClick={() => {
            props.fns.signOut();
            handleCloseModal();
            resetTheme();
          }}
        >
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AuthenticatedUserModal;
