import React, { useEffect } from "react";
import { SubMenu as ProSubMenu, MenuItem } from "react-pro-sidebar";
import data from "../../../static/json/product_dict.json";

const ParamMenu = (props) => {
  const handleParamChange = (newParam) => {
    props.onParamChange(newParam);
  };

  const climateData = data.product[0].model_category[0].climate[0];
  const geoData = data.product[1].satellite_category[0].geostationary[0];

  const climateModels = climateData.models;
  const geoSatellites = geoData.satellites;
  const parameters = data.parameters;

  const selectedModel = climateModels.find(
    (model) => model.id === props.selectedModelName
  );

  const selectedSatellite = geoSatellites.find(
    (satellite) => satellite.id === props.selectedModelName
  );

  const availableParams =
    props.selectedModelCategory == "climate"
      ? selectedModel
        ? selectedModel.available_params
        : []
      : selectedSatellite
      ? selectedSatellite.available_params
      : [];

  const paramItems = availableParams.map((paramId) => {
    const paramDetail = parameters.find((param) => param.id === paramId);
    return paramDetail ? (
      <MenuItem
        key={paramId}
        className="sidebar-item"
        onClick={() => handleParamChange(paramId)}
      >
        {paramDetail.name}
      </MenuItem>
    ) : null;
  });

  if (props.selectedModelName === "NMME" || "CanSIPS") {
    return (
      <ProSubMenu
        label="Parameters"
        className="sidebar-submenu sidebar-param-menu"
      >
        {paramItems.length > 0 ? (
          paramItems
        ) : (
          <MenuItem className="sidebar-item">No Parameters Available</MenuItem>
        )}
      </ProSubMenu>
    );
  }
};

export default ParamMenu;
