import React, { useState } from "react";

import CollapseIcon from "@mui/icons-material/OpenInNewOff";
import PowerButtonIcon from "@mui/icons-material/MenuOpen";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/MarkEmailRead";
import NotificationIcon from "@mui/icons-material/NotificationAdd";
import ThemeIcon from "@mui/icons-material/ColorLens";

import "../../../static/css/sidebar/sidebarFooter.scss";

import AuthModal from "../../modals/authModal";
import ThemeModal from "../../modals/themeModal";

function SidebarFooter(props) {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(false);

  const handleAuthModalOpen = () => {
    setShowAuthModal(true);
  };

  const handleAuthCloseModal = () => {
    setShowAuthModal(false);
  };

  const handleThemeModalOpen = () => {
    setShowThemeModal(true);
  };

  const handleThemeCloseModal = () => {
    setShowThemeModal(false);
  };

  const handleCollapseClick = () => {
    props.onCollapse();
  };

  if (props.collapsed) {
    return (
      <div>
        <div className="sidebar-footer-collapsed">
          <CollapseIcon className="footer-icon" onClick={handleCollapseClick} />
        </div>
      </div>
    );
  }

  if (props.fns.readToken()._token !== undefined) {
    return (
      <div className="sidebar-footer">
        <div className="footer-icon-container position-relative">
          <NotificationIcon className="footer-icon" />
          <span className="position-absolute top-0 start-50 badge badge-pill badge-warning rounded-pill notification">
            3
          </span>
        </div>
        <div className="footer-icon-container position-relative">
          <EmailIcon className="footer-icon" />
          <span className="position-absolute top-0 start-50 badge badge-pill badge-success rounded-pill notification">
            7
          </span>
        </div>
        <div className="footer-icon-container">
          <ThemeIcon className="footer-icon" onClick={handleThemeModalOpen} />
        </div>
        <div className="footer-icon-container">
          <SettingsIcon className="footer-icon" onClick={handleAuthModalOpen} />
        </div>
        <div className="footer-icon-container">
          <PowerButtonIcon
            className="footer-icon"
            onClick={handleCollapseClick}
          />
        </div>
        <ThemeModal
          showModal={showThemeModal}
          handleCloseModal={handleThemeCloseModal}
          props={props}
        />
        <AuthModal
          showModal={showAuthModal}
          handleCloseModal={handleAuthCloseModal}
          props={props}
        />
      </div>
    );
  }

  return (
    <div className="sidebar-footer">
      <div className="footer-icon-container position-relative">
        <NotificationIcon className="footer-icon" />
      </div>
      <div className="footer-icon-container position-relative">
        <EmailIcon className="footer-icon" />
      </div>
      <div className="footer-icon-container">
        <SettingsIcon className="footer-icon" onClick={handleAuthModalOpen} />
      </div>
      <div className="footer-icon-container">
        <PowerButtonIcon
          className="footer-icon"
          onClick={handleCollapseClick}
        />
      </div>
      <AuthModal
        showModal={showAuthModal}
        handleCloseModal={handleAuthCloseModal}
        props={props}
      />
    </div>
  );
}

export default SidebarFooter;
