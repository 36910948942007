import React, { useState } from "react";
import "../../static/css/navbar/navbarButton.css";
import HomeIcon from "@mui/icons-material/AccountCircle";
import GlobeIcon from "@mui/icons-material/Public";
import LoginModal from "../modals/authModal";

function NavbarButton(props) {
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="btn-navbar-wrapper">
      <ul className="btn-navbar-list">
        <li>
          <GlobeIcon className="navbar-icon" />
        </li>
        <li className="user-auth-modal">
          <HomeIcon className="navbar-icon" onClick={handleModalOpen} />
        </li>
      </ul>

      {/* Modal */}
      <LoginModal
        showModal={showModal}
        handleCloseModal={handleModalClose}
        {...props}
      />
    </div>
  );
}

export default NavbarButton;
