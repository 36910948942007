import React, { useEffect, useState } from "react";
import { SubMenu as ProSubMenu, MenuItem } from "react-pro-sidebar";
import "../../../static/css/sidebar/sidebarMenu.scss";

const ModelMenu = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleModelNameChange = (newModelName) => {
    props.onModelNameChange(newModelName);
    props.onProductChange("model");
    props.onModelCategoryChange("climate");
    setActiveItem(newModelName);
  };

  return (
    <>
      <ProSubMenu
        label="Climate"
        className="sidebar-submenu sidebar-param-menu"
      >
        <MenuItem
          className={`sidebar-item-${activeItem === "cansips" ? "active" : ""}`}
          onClick={() => handleModelNameChange("cansips")}
        >
          CanSIPS
        </MenuItem>
        <MenuItem
          className={`sidebar-item-${activeItem === "NMME" ? "active" : ""}`}
          onClick={() => handleModelNameChange("NMME")}
        >
          NMME
        </MenuItem>
        {/* <MenuItem className="sidebar-item" disabled>
          CFS
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          ECMWF
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          UKMET
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          JMA
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          CMCC
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          ECCC
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          Meteo France
        </MenuItem>
        <MenuItem className="sidebar-item" disabled>
          DWD
        </MenuItem> */}
      </ProSubMenu>

      {/* <ProSubMenu
        label="Global"
        className="sidebar-submenu sidebar-param-menu"
        disabled
      >
        <MenuItem className="sidebar-item">ECMWF</MenuItem>
        <MenuItem className="sidebar-item">GFS</MenuItem>
        <MenuItem className="sidebar-item">CMC</MenuItem>
        <MenuItem className="sidebar-item">ICON</MenuItem>
        <MenuItem className="sidebar-item">JMA</MenuItem>
      </ProSubMenu>

      <ProSubMenu
        label="Ensembles"
        className="sidebar-submenu sidebar-param-menu"
        disabled
      >
        <MenuItem className="sidebar-item">
          GEFS (GFS ensemble members)
        </MenuItem>
        <MenuItem className="sidebar-item">
          EPS (ECWMF ensemble members)
        </MenuItem>
        <MenuItem className="sidebar-item">
          GEPS (CMC ensemble members)
        </MenuItem>
      </ProSubMenu>

      <ProSubMenu
        label="Mesoscale"
        className="sidebar-submenu sidebar-param-menu"
        disabled
      >
        <MenuItem className="sidebar-item">NAM</MenuItem>
        <MenuItem className="sidebar-item">HRRR</MenuItem>
        <MenuItem className="sidebar-item">RGEM</MenuItem>
        <MenuItem className="sidebar-item">HRDPS</MenuItem>
      </ProSubMenu>

      <ProSubMenu
        label="Superblends"
        className="sidebar-submenu sidebar-param-menu"
        disabled
      >
        <MenuItem className="sidebar-item">ECMWF + UKMET</MenuItem>
        <MenuItem className="sidebar-item">ECMWF + UKMET + CanSIPS</MenuItem>
        <MenuItem className="sidebar-item">ECMWF + UKMET + CFS</MenuItem>
      </ProSubMenu> */}
    </>
  );
};

export default ModelMenu;
