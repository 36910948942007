import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import "../../static/css/modal/themeModal.scss";

const ThemeModal = ({ showModal, handleCloseModal, props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [themePagination, setThemePagination] = useState(0);
  const themesPerPage = 3;
  const currentThemes = props?.D?.getthemes?.msg?.themes || [];
  const totalThemes = props?.D?.getthemes?.msg?.totalcount || 0;
  const totalPages = Math.ceil(totalThemes / themesPerPage) || 1;

  const handleNextPage = () => {
    if (themePagination < totalPages - 1) {
      setThemePagination((prevPagination) => prevPagination + themesPerPage);
    }
  };

  const handlePrevPage = () => {
    if (themePagination > 0) {
      setThemePagination((prevPagination) => prevPagination - themesPerPage);
    }
  };

  const indexOfLastTheme = currentPage * themesPerPage;
  const indexOfFirstTheme = indexOfLastTheme - themesPerPage;
  const displayedThemes = currentThemes.slice(
    indexOfFirstTheme,
    indexOfLastTheme
  );

  useEffect(() => {
    if (!props?.fns?.calls?.getthemes || props.D.getthemes) return;
    props.fns.calls.getthemes({ search: { skip: 0, limit: themesPerPage } });
  }, [props.fns]);

  useEffect(() => {
    props.fns.calls.getthemes({
      search: { skip: themePagination, limit: themesPerPage },
    });
  }, [themePagination]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      fullscreen="xxl-down"
      dialogClassName="custom-modal"
      contentClassName="custom-modal-content theme-modal"
    >
      <Modal.Header>
        <Modal.Title>
          Select a Theme
          <button
            type="button"
            className="btn-close btn-modal-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="theme-cards-container p-0 row">
          {displayedThemes.map((m, index) => (
            <div className="card custom-card px-0 mx-1" key={index}>
              <img src={m.img} class="card-theme-img rounded" />
              <div className="card-body p-2">
                <h6 className="card-title">{m.name}</h6>
                <div className="color-box-container">
                  <div className="color-text">Primary Color:</div>
                  <div
                    className="color-box"
                    style={{ backgroundColor: m.backgroundcolor }}
                  ></div>
                </div>
                <div className="color-box-container">
                  <div className="color-text">Secondary Color:</div>
                  <div
                    className="color-box"
                    style={{ backgroundColor: m.secondarycolor }}
                  ></div>
                </div>
                <div className="color-box-container">
                  <div className="color-text">Text Color:</div>
                  <div
                    className="color-box"
                    style={{ backgroundColor: m.fontcolor }}
                  ></div>
                </div>
                <div className="color-box-container">
                  <div className="color-text">Secondary Text:</div>
                  <div
                    className="color-box"
                    style={{ backgroundColor: m.fontsecondarycolor }}
                  ></div>
                </div>

                <button
                  onClick={() => {
                    props.fns.calls.updateuser({
                      profiletheme: m._id.toString(),
                    });
                  }}
                  className="btn btn-sm btn-primary mt-3"
                >
                  Select Theme
                </button>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <nav aria-label="...">
          <ul className="pagination pagination-sm">
            <Button
              variant="secondary"
              className="page-link"
              onClick={handlePrevPage}
            >
              Previous
            </Button>
            <Button
              variant="secondary"
              className="page-link btn-custom-modal"
              onClick={handleNextPage}
            >
              Next
            </Button>
          </ul>
        </nav>
      </Modal.Footer>
    </Modal>
  );
};

export default ThemeModal;
