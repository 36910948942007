import React, { Component } from "react";
import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import CommentIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import BookmarkIcon from "@mui/icons-material/BookmarksOutlined";
import CreateIcon from "@mui/icons-material/CreateOutlined";

const FeaturedArticle = (props) => {
  const Article =
    props?.D?.getarticles?.records && props.D.getarticles.records.length
      ? props.D.getarticles.records[0]
      : undefined;
  return Article ? (
    <div className="list-group featured-article-container">
      <div className="list-group-item shadow-sm home-component-container">
        {/* Blog header text */}
        <h4 className="featured-blog-header main-header-text">
          {Article.emphasis}
        </h4>
        <hr className="text-light"></hr>
        {/* Text for title of article */}
        <h3
          className="featured-article-title"
          onClick={() =>
            props.fns.route(`/article?id=${Article._id.toString()}`)
          }
        >
          {Article.title}
        </h3>

        {/* subtext and subtext icons (date, tags, comments, author) */}
        <div className="row featured-article-subtext featured-details pb-2">
          <div className="col-sm-3">
            <CalendarIcon />
            <small className="subtext featured-article-subtext">
              {Article.createddate}
            </small>
          </div>
          <div className="col-sm-3">
            <BookmarkIcon className="subtext-icon" />
            <small className="subtext">
              {Article.tags.map((tag, index) => (
                <a href="#">
                  {tag.name}
                  {index < Article.tags.length - 1 ? ", " : null}
                </a>
              ))}
            </small>
          </div>
          <div className="col-sm-3">
            <CommentIcon className="subtext-icon" />
            <small className="subtext featured-article-subtext">
              <a href="#">
                {Article.commentcount && Article.commentcount - 1 >= 0
                  ? Article.commentcount - 1
                  : 0}{" "}
                comments
              </a>
            </small>
          </div>
          <div className="col-sm-3">
            <CreateIcon className="subtext-icon" />
            <small className="subtext featured-article-subtext">
              By: <a href="#"> {Article.author.username}</a>
            </small>
          </div>
        </div>

        {/* Featured article image */}
        <div className="row pb-1">
          <div className="col-sm-12">
            <div className="video-wrapper">
              <img
                onClick={() =>
                  props.fns.route(`/article?id=${Article._id.toString()}`)
                }
                src={Article.img}
                alt="Featured Video"
                className="img-fluid featured-article-img"
              />
            </div>
          </div>
        </div>

        {/* Featured article text blurb */}
        <div className="row">
          <div className="col-sm-12 featured-article-text-blurb">
            <div
              dangerouslySetInnerHTML={{
                __html: Article.contentBlocks[0].value,
              }}
            />
            {/* Read more button */}
            <div
              onClick={() =>
                props.fns.route(`/article?id=${Article._id.toString()}`)
              }
              className="btn btn-sm featured-article-button"
            >
              Read More
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>No Articles</div>
  );
};

export default FeaturedArticle;
