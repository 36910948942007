import React from "react";
import "../../../static/css/sidebar/sidebarMap.scss";
import data from "../../../static/json/product_dict.json";

const InfoComponent = (props) => {
  const selectedProduct =
    props.selectedModelCategory == "climate"
      ? data.product[0].model_category[0].climate[0].models.find(
          (model) => model.id === props.selectedModelName
        )
      : props.selectedModelCategory == "GOES"
      ? data.product[1].satellite_category[0].geostationary[0].satellites.find(
          (satellite) => satellite.id === props.selectedModelName
        )
      : null;

  const selectedParam = data.parameters.find(
    (param) => param.id === props.selectedParam
  );

  const selectedRegion = data.region.find(
    (region) => region.id === props.selectedRegion
  );

  if (!props.collapsed) {
    return (
      <div className="sidebar-map-header">
        <div className="user-info">
          <span className="sidebar-map-product-type pb-1">
            {selectedProduct.category}
          </span>
          <span className="sidebar-map-product-name">
            {selectedProduct.name}
          </span>
          <span className="sidebar-map-product-param">
            {selectedParam.name} ({selectedParam.unit})
          </span>
          <span className="sidebar-map-product-param ">
            {selectedRegion.name}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sidebar-map-header">
        <div className="user-info">
          <span className="sidebar-map-product-name">
            {selectedProduct.name}
          </span>
          <span className="sidebar-map-product-param">
            {selectedParam.name} ({selectedParam.unit})
          </span>
          <span className="sidebar-map-product-param ">North Atlantic</span>
        </div>
      </div>
    );
  }
};
export default InfoComponent;
