import { useEffect } from "react";

export default (props) => {
  useEffect(() => {
    if (!props?.D) return;
    if (props?.fns?.calls?.verifyself && !props.D.verifyself) {
      props.fns.calls.verifyself();
    }
    if (
      props?.fns?.calls?.getrecords_settings &&
      !props?.D?.getrecords_settings?.init?.records
    ) {
      props.fns.calls.getrecords_settings();
    }
  }, [props.fns]);
  useEffect(() => {
    if (!props?.D?.getrecords_settings?.init?.records) return;
    if (
      document.title ===
      props.D.getrecords_settings.init.records[0].applicationtitle
    )
      return;
    document.title =
      props.D.getrecords_settings.init.records[0].applicationtitle;
  }, [props.D]);
  useEffect(() => {
    if (props?.D?.getarticles || !props.fns.calls.getarticles) return;
    props.fns.calls.getarticles();
  }, [props.fns]);
};
