import React, { useEffect, useState } from "react";
import { SubMenu as ProSubMenu, MenuItem } from "react-pro-sidebar";
import "../../../static/css/sidebar/sidebarMenu.scss";

const SatelliteMenu = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleModelNameChange = (newModelName) => {
    props.onProductChange("satellite");
    props.onModelCategoryChange("GOES");
    props.onModelNameChange(newModelName);

    setActiveItem(newModelName);
  };

  return (
    <>
      <ProSubMenu
        label="Geostationary"
        className="sidebar-submenu sidebar-param-menu"
      >
        <MenuItem
          className={`sidebar-item-${
            activeItem === "True Color" ? "active" : ""
          }`}
          onClick={() =>
            handleModelNameChange("East") && props.handleParamChange("trueRGB")
          }
        >
          GOES-EAST
        </MenuItem>
        {/* <MenuItem
          className={`sidebar-item-${activeItem === "NMME" ? "active" : ""}`}
          onClick={() => handleModelNameChange("NMME")}
        >
          NMME
        </MenuItem> */}
      </ProSubMenu>
    </>
  );
};

export default SatelliteMenu;
