import React from "react";

function RecentArticles(props) {
  return props?.D?.getarticles?.records &&
    props.D.getarticles.records.length ? (
    <div className="list-group recent-article-wrapper">
      <div className="list-group-item home-component-container shadow-sm">
        <h4 className="main-header-text">Recent Articles</h4>
        <hr className="text-light"></hr>
        <div className="row mx-1">
          {props.D.getarticles.records.slice(0, 5).map((Article) => (
            <div
              className="col-sm-2 recent-article-preview-wrapper mb-2 mx-2 px-0"
              onClick={() =>
                props.fns.route(`/article?id=${Article._id.toString()}`)
              }
            >
              <img src={Article.img} className="article-preview-img" />
              <small className="recent-article-date px-2">
                {Article.createddate}
              </small>
              <br />
              <hr className="text-light mx-2 my-1"></hr>
              <div className="recent-article-text px-2">{Article.title}</div>
              {/* <a
              href="#"
              class="btn btn-sm read-article-button my-2 mx-2"
              // onClick={handleReadArticleClick}
            >
              Read Article
            </a> */}
            </div>
          ))}
        </div>
        <button type="button" class="btn btn-sm more-articles-button mt-2">
          More Articles
        </button>
      </div>
    </div>
  ) : (
    <div>No Articles</div>
  );
}

export default RecentArticles;
