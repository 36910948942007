import React, { useState } from "react";

// import StreamPanel from "../streampanel/streampanel";
// import LiveIcon from "@mui/icons-material/LiveTv";

function FeaturedProducts() {
  const [showFullPage, setShowFullPage] = useState(false);

  const handleWatchLiveClick = () => {
    setShowFullPage(true);
  };

  return (
    <div className="list-group featured-products-container">
      <div className="list-group-item home-component-container shadow-sm">
        <h4 className="featured-products-header main-header-text">
          Recent Product Updates
        </h4>
        <hr className="text-light"></hr>
        <div className="row">
          {/* First product div */}
          <div className="col-sm-4 featured-products-card-container mb-3">
            <div className="card border-0 featured-products-card">
              <img
                className="featured-products-img"
                src="https://i.imgur.com/iKsoogP.jpeg"
              />
              <hr className="text-light pb-0 mb-0"></hr>
              <div className="card-body">
                <p class="featured-products-card-title">
                  Product Category:{" "}
                  <p className="featured-products-card-text">CAMS Model</p>
                </p>
                <p class="featured-products-card-title">
                  Product Type:{" "}
                  <p className="featured-products-card-text">
                    Aersol Optical Depth
                  </p>
                </p>
                <a
                  href="#"
                  class="btn btn-sm featured-products-button"
                  onClick={handleWatchLiveClick}
                >
                  View Product
                </a>
              </div>
            </div>
          </div>

          {/* Second product div */}
          <div className="col-sm-4 featured-products-card-container mb-3">
            <div className="card border-0 featured-products-card">
              <img
                className="featured-products-img"
                src="https://i.imgur.com/76kyJ2i.png"
              />
              <hr className="text-light pb-0 mb-0"></hr>
              <div className="card-body">
                <p class="featured-products-card-title">
                  Product Category:{" "}
                  <p className="featured-products-card-text">CanSIPS Model</p>
                </p>
                <p class="featured-products-card-title">
                  Product Type:{" "}
                  <p className="featured-products-card-text">
                    Sea Surface Temperature Anomalies
                  </p>
                </p>
                <a
                  href="#"
                  class="btn btn-sm featured-products-button"
                  onClick={handleWatchLiveClick}
                >
                  View Product
                </a>
              </div>
            </div>
          </div>

          {/* Third product div */}
          <div className="col-sm-4 featured-products-card-container mb-3">
            <div className="card border-0 featured-products-card">
              <img
                className="featured-products-img"
                src="https://i.imgur.com/maTQkte.jpeg"
              />
              <hr className="text-light pb-0 mb-0"></hr>
              <div className="card-body">
                <p class="featured-products-card-title">
                  Product Category:{" "}
                  <p className="featured-products-card-text">Forecast Tracks</p>
                </p>
                <p class="featured-products-card-title">
                  Product Type:{" "}
                  <p className="featured-products-card-text">
                    Major Hurricane Plots
                  </p>
                </p>
                <a
                  href="#"
                  class="btn btn-sm featured-products-button"
                  onClick={handleWatchLiveClick}
                >
                  View Product
                </a>
              </div>
            </div>

            {/* CLosing divs */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProducts;
